import Input from "../components/Input";
import { useState, useEffect } from "react";
import axios from "axios";
import saveData from "./saveData";
import updateCV from "./updateCV";
import { useAuth } from "../components/AuthContext";
import { useLocalStorage } from '../hooks/useLocalStorage';



export default function PersonaliaBox({ handleSubmit }) {

    const { user, isAuthenticated } = useAuth();
    const [serverLastUpdated, setServerLastUpdated] = useState(null);
    const [personalia, setPersonalia] = useLocalStorage('personalia', {});
    const [imagePreview, setImagePreview] = useState(null);
    const [formData, setFormData] = useState(() => {
        const savedData = localStorage.getItem('personalia');
        return savedData ? {
            ...JSON.parse(savedData)
        } : {
            firstName: '',
            lastName: '',
            phonenumber: '',
            email: '',
            date: '',
            jobtitle: '',
            profilePicture: '',
            address: '',
            zipcode: '',
            city: '',
            country: '',
            licence: '',
            nationality: '',
        };
    });

    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            if(!localStorage.getItem('personalia')) {
                fetchPersonalia();
            }

            // Set up interval to check for updates
            const interval = setInterval(() => {
                const localData = JSON.parse(localStorage.getItem('personalia'));
                const localLastUpdated = localData?.lastUpdated;
                
                if (localLastUpdated && (!serverLastUpdated || new Date(localLastUpdated) > new Date(serverLastUpdated))) {
                    syncWithServer(localData);
                }
            }, 10000); // Check every 10 seconds

            return () => clearInterval(interval);
        }
    }, [isAuthenticated, serverLastUpdated]);

    useEffect(() => {
        if (formData.profilePicture && typeof formData.profilePicture === 'string' && formData.profilePicture.startsWith('http')) {
            setImagePreview(formData.profilePicture);
        }
    }, [formData.profilePicture]);

    const syncWithServer = async (data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/cv/personalia`,
                { ...data, cvId: localStorage.getItem('cvId') },
                { withCredentials: true }
            );
            if(response.status === 200) {
                setServerLastUpdated(response.data.lastUpdated);
            }
        } catch (error) {
            console.error('Error syncing with server:', error);
        }
    };

    const fetchPersonalia = async () => {
        let token = window.location.search.split('=')[0];
        let idcv = window.location.search.split('=')[1];
        let cvId = localStorage.getItem('cvId');

        //if localstorage and browser has id, and they are the same, fetch latest data from server
        if((cvId && idcv && (cvId === idcv))|| idcv && token === "?idcv" ) {

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrievePersonalia`, {
                withCredentials: true,
                params: {cvId: idcv}
            }).then((response) => {

                const localData = JSON.parse(localStorage.getItem('personalia'));
                if (!localData) {
                    // setPersonaliaValues('personalia', response.data);
                    //localStorage.setItem('personalia', JSON.stringify(response.data));
                    setPersonalia(response.data);
                }

                setServerLastUpdated(response.data.lastUpdated);
            });
        } else if (!idcv && cvId) {

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrievePersonalia`, {
                withCredentials: true,
                params: {cvId}
            }).then((response) => {
                const localData = JSON.parse(localStorage.getItem('personalia'));
                if (!localData) {
                    // setPersonaliaValues('personalia', response.data);
                    // localStorage.setItem('personalia', JSON.stringify(response.data));
                    setPersonalia(response.data);
                }
                setServerLastUpdated(response.data.lastUpdated);
            });
        } else if ((idcv && token === "?idcv") && cvId !== idcv) {

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrievePersonalia`, {
                withCredentials: true,
                params: {cvId: idcv}
            }).then((response) => {
                // setPersonaliaValues('personalia', response.data);           
                // localStorage.setItem('personalia', JSON.stringify(response.data));
                setPersonalia(response.data);
                setServerLastUpdated(response.data.lastUpdated);
            });
        }
    }
/*
    const fetchLatestCVData = async () => {
        let token = window.location.search.split('=')[0];
        let idcv = window.location.search.split('=')[1];


        if(idcv && token === "?idcv") {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/fetch-cv-id`, {idcv: idcv}, {
                withCredentials: true
            }).then((response) => {

                setFormData(prevData => ({
                    ...prevData,
                    ...response.data[0].cvContent.Personalia
                }));
            })
            
        } else {

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/fetch-cv`, {}, {
                withCredentials: true
            });
            if (response.data && response.data.cvContent.Personalia) {
                setFormData(prevData => ({
                    ...prevData,
                    ...response.data.cvContent.Personalia
                }));
            }

            if (response.data.profilePicture) {
                setFormData(prevData => ({
                    ...prevData,
                    profilePicture: response.data.profilePicture
                }));
      
            }
        } catch (error) {
            console.error('Error fetching CV data:', error);
        }
    }

    };
*/
    const showMoreFunc = () => {
        setShowMore(!showMore);
    };

    const handleLocalInputChange = async (field, value) => {
        const lastUpdated = new Date().toISOString();
        const newData = { ...formData, [field]: value, lastUpdated };
        // setStorageValue('personalia', newData); 
        // localStorage.setItem('personalia', JSON.stringify(newData));
        setFormData(newData);
        setPersonalia(newData);
        
    };


    const handleFileUpload = (e) => {
        console.log(e.target.files[0], "FILE");
        let file = e.target.files[0];
        
        if (!file) {
            console.error('No file selected');
            return;
        }
        
        // Create preview URL for the selected image
        const previewUrl = URL.createObjectURL(file);
        setImagePreview(previewUrl);

        const fileData = new FormData();
        fileData.append('image', file);
        fileData.append('idcv', localStorage.getItem('cvId'));
        
        // Show loading state here if needed
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload/profilePicture`, fileData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }, 
            withCredentials: true
        })
        .then(response => {
            console.log(response.data, "RESPONSE");
            // Handle successful upload
            if (response.data && response.data.imageUrl) {
                // Use the direct S3 URL returned from the server
                const imageUrl = response.data.imageUrl;
                
                console.log('Image URL:', imageUrl);
                
                setFormData(prev => ({...prev, profilePicture: imageUrl}));
                setImagePreview(imageUrl);
                
                // Save the profile picture URL to localStorage
                const updatedData = {...formData, profilePicture: imageUrl, lastUpdated: new Date().toISOString()};
                setPersonalia(updatedData);
                localStorage.setItem('profilePicture', imageUrl);
                
                // Now we can revoke the temporary object URL since we don't need it anymore
                URL.revokeObjectURL(previewUrl);
            } else {
                console.error('No imageUrl in response:', response.data);
                // Keep the local preview if we don't get a URL from the server
            }
        })
        .catch(error => {
            console.error('Error uploading file:', error);
            console.error('Error details:', error.response?.data || error.message);
            // Keep the local preview as fallback if server upload fails
            // Don't revoke the URL on error so the user still sees something
        });
    }

    return (
        <div className="personaliaBox">
            <div className="personaliaBoxPad">
                <h2>Personalia</h2>
                <p>Her fyller du ut generell informasjon om deg selv</p>
                <div className="inputs">
                    <Input 
                        placeholder="fornavn" 
                        value={formData.firstName} 
                        onChange={(value) => handleLocalInputChange("firstName", value)} 
                    />
                    <Input 
                        placeholder="etternavn" 
                        value={formData.lastName} 
                        onChange={(value) => handleLocalInputChange("lastName", value)} 
                    />
                    <Input 
                        placeholder="telefonnummer" 
                        value={formData.phonenumber} 
                        onChange={(value) => handleLocalInputChange("phonenumber", value)} 
                    />
                    <Input 
                        placeholder="epost" 
                        value={formData.email} 
                        onChange={(value) => handleLocalInputChange("email", value)} 
                    />
                    <Input 
                        placeholder="stillingstittel" 
                        value={formData.jobtitle} 
                        onChange={(value) => handleLocalInputChange("jobtitle", value)} 
                    />
                </div>
                <div className="profile-upload-section">
                    {imagePreview ? (
                        <div className="profile-preview-row">
                            <img 
                                src={imagePreview} 
                                alt="Profile" 
                                className="profile-preview-image"
                            />
                            <button 
                                onClick={() => {
                                    setImagePreview(null);
                                    setFormData(prev => ({...prev, profilePicture: ''}));
                                }}
                                className="remove-image-btn"
                            >
                                ×
                            </button>
                        </div>
                    ) : (
                        <input 
                            type="file" 
                            onChange={handleFileUpload} 
                            accept="image/*"
                        />
                    )}
                </div>
                <div className="moreButton" onClick={showMoreFunc}>
                    {!showMore ? <p>Vis flere felt</p> : <p>Skjul flere felt</p>}
                </div>
                {showMore && (
                    <div className="inputs">
                        <Input 
                            placeholder="fødselsdato" 
                            value={formData.date} 
                            onChange={(value) => handleLocalInputChange("date", value)} 
                        />
                        <Input 
                            placeholder="addresse" 
                            value={formData.address} 
                            onChange={(value) => handleLocalInputChange("address", value)} 
                        />
                        <Input 
                            placeholder="postnummer" 
                            value={formData.zipcode} 
                            onChange={(value) => handleLocalInputChange("zipcode", value)} 
                        />
                        <Input 
                            placeholder="by" 
                            value={formData.city} 
                            onChange={(value) => handleLocalInputChange("city", value)} 
                        />
                        <Input 
                            placeholder="land" 
                            value={formData.country} 
                            onChange={(value) => handleLocalInputChange("country", value)} 
                        />
                        <Input 
                            placeholder="førerkort" 
                            value={formData.licence} 
                            onChange={(value) => handleLocalInputChange("licence", value)} 
                        />
                        <Input 
                            placeholder="nasjonalitet" 
                            value={formData.nationality} 
                            onChange={(value) => handleLocalInputChange("nationality", value)} 
                        />
                    </div>
                )}
            </div>
        </div>
    );
}