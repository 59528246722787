import "../css/Profile.css";
import CTA from "../components/buttons/CTA";
import Input from "../components/Input";
import CTA2 from "../components/buttons/CTA2";
import { useEffect, useState, useCallback } from "react";
import handleInputChange from "../components/HandleInputChange";
import axios from "axios";
import lodash from "lodash";
import { useNavigate } from "react-router-dom";
import DangerButton from "../components/buttons/DangerButton";
import PrimaryButton from "../components/buttons/PrimaryButton";
import { useAuth } from "../components/AuthContext";
import CvPreviewThumbnail from "../components/CvPreviewThumbnail";
import { pdf } from "@react-pdf/renderer";

// Import CV templates for PDF generation
import Mainla from "../pages/templates/Mainla";
import Toyko from "../pages/templates/Toyko";
import Cali from "../pages/templates/Cali";
import Stokkholm from "../pages/templates/Stokkholm";
import Asu from "../pages/templates/Asu";
import NTNU from "../pages/templates/NTNU";
import Filipina from "../pages/templates/Filipina";

export default function Profile() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const { userInfo } = useAuth();

  const [showDeleted, setShowDeleted] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showCVs, setShowCVs] = useState(true);
  const [showSubscriptions, setShowSubscriptions] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [cvs, setCvs] = useState([]);
  const [message, setMessage] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});

  useEffect(() => {
    getUser();
    fetchCVs();
    fetchSubscriptions();
  }, []);

  async function getUser() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/profile`, {
        withCredentials: true,
      })
      .then((result) => {
        let data = result.data.data;

        setFormData((prevState) => ({
          ...prevState, // Spread in the previous state
          firstName: data.firstName, // Update with new data
          lastName: data.lastName,
          email: data.email,
        }));
      });
  }

  const isAnyFieldNotEmpty = () => {
    return Object.entries(formData)
      .filter(([key, value]) => value.trim() !== "") // Filter out empty values
      .map(([key, value]) => ({ [key]: value }));
  };

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  }

  const handleInput = debounce(() => {
    // Send data to backend
    handleSubmit();
  }, 2000);

  const handleSubmit = () => {
    const nonEmptyValues = isAnyFieldNotEmpty();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isEmail = emailRegex.test(nonEmptyValues[2].email);

    if (isEmail) {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/user/profile`,
          { email: userInfo, user: nonEmptyValues },
          { withCredentials: true }
        )
        .then((result) => {
          if (result.status(201)) {
            window.location.href = "/login";
          }
        });
    }
  };

  const deleteCookie = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/deleteCookie`, {
        withCredentials: true,
      })
      .then((result) => {
        window.location.href = "/login";
      });
  };

  const showInputField = () => {
    setShowDeleted(true);
  };

  const deleteProfile = (e) => {
    let value = e.target.parentElement.firstChild.value;
    if (value.toUpperCase() == "SLETT MEG") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/user/deleteProfile`, {
          withCredentials: true,
        })
        .then((result) => {
          if (result.status == 200) {
            window.location.href = "/login";
          }
        });
    }
  };

  const selectProfile = (e) => {
    setShowProfile(true);
    setShowCVs(false);
    setShowSubscriptions(false);
  };

  const selectSubscriptions = (e) => {
    setShowSubscriptions(true);
    setShowProfile(false);
    setShowCVs(false);
  };

  const selectCV = (e) => {
    setShowProfile(false);
    setShowCVs(true);
    setShowSubscriptions(false);
  };

  const fetchCVs = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/fetchCVs`,
        {},
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          // Process the response data to ensure compatibility with both old and new formats
          const processedCvs = response.data.map(cv => ({
            ...cv,
            // Ensure cvName is available (might come as cvName or as cvTemplate from old API)
            cvName: cv.cvName || cv.cvTemplate,
            // Ensure cvTemplate is available for backwards compatibility
            cvTemplate: cv.cvTemplate || cv.cvName,
            // Format the date for display if needed
            last_cv_update: cv.last_cv_update || new Date().toISOString()
          }));
          setCvs(processedCvs);
          console.log("Fetched CVs:", processedCvs);
        } else {
          console.error("Failed to fetch CVs");
        }
      })
      .catch((error) => {
        console.error("Error fetching CVs:", error);
      });
  };

  const fetchSubscriptions = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/user/subscriptions`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        setSubscriptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching subscriptions:", error);
      });
  };

  const cancelSubscription = (agreementId) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/user/cancelSubscription`,
        { agreementId: agreementId },
        { withCredentials: true }
      )
      .then((response) => {})
      .catch((error) => {
        if (error.response.status === 400) {
          setMessage("Abonnement allerede avbrutt");
        } else {
          setMessage("Noe gikk galt");
        }
        console.error("Error canceling subscription:", error);
      });
  };

  const deleteCV = (idcv) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/deleteCV`,
        { idcv: idcv },
        { withCredentials: true }
      )
      .then((result) => {
        if (result.status == 200) {
          setMessage("CV slettet");
          setTimeout(() => setMessage(""), 5000);
          fetchCVs();
        }
      });
  };

  const editCV = (idcv, template) => {
    let templateId = template || 5;

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/cvmal/mal`,
        { cvMal: templateId },
        { withCredentials: true }
      )
      .then((response) => {
        navigate(`/lageCV/?idcv=${idcv}`, {
          state: { template: response.data[0], idcv: idcv },
        });
      });
  };

  const copyCV = (idcv) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/copyCV`,
        { idcv: idcv },
        { withCredentials: true }
      )
      .then((result) => {
        if (result.status == 200) {
          setMessage("CV kopiert");
          setTimeout(() => setMessage(""), 5000);
          fetchCVs();
        }
      });
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const createNewCV = async () => {
    try {
      // Get current design settings
      const designSettings = {
        fonts: JSON.parse(localStorage.getItem('fontFamily') || '{"fontFamily": "Inter"}'),
        colors: JSON.parse(localStorage.getItem('colorFormData') || '{}'),
        fontSize: JSON.parse(localStorage.getItem('fontSettings') || '{"size": 10}')
      };

      // Create new CV with design settings
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/cv/create`, 
        designSettings,
        { withCredentials: true }
      );

      if (response.status === 200) {
        const newCvId = response.data.cvId;
        
        // Clear all CV-related data from localStorage except design settings
        const keysToKeep = ['colorFormData', 'fontFamily', 'fontSettings', 'selectedCV'];
        
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (!keysToKeep.includes(key)) {
            localStorage.removeItem(key);
          }
        }

        // Save the new CV ID to localStorage
        localStorage.setItem('cvId', newCvId);

        // Navigate to CV template selection
        navigate('/lagecv');
      }
    } catch (error) {
      console.error('Error creating new CV:', error);
      setMessage("Kunne ikke opprette ny CV");
    }
  };

  const downloadCV = async (idcv, templateName) => {
    try {
      // Set loading state for this specific CV
      setLoadingStates(prev => ({ ...prev, [idcv]: 'downloading' }));
      
      // Get the CV data
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/cv/get`,
        { idcv },
        { withCredentials: true }
      );
      
      if (response.status !== 200) {
        throw new Error('Failed to fetch CV data');
      }
      
      const cvData = response.data;
      const profilePicture = cvData.profilePicture || "https://plus.unsplash.com/premium_photo-1690294614341-cf346ba0a637?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
      
      // Get design settings
      const designSettings = {
        design: cvData.design || {},
        fonts: cvData.fonts || { fontFamily: "Inter" },
        size: cvData.fontSize || { fontSize: 10 }
      };
      
      // Set up props for the template component
      const templateProps = {
        cvData,
        cvDesign: designSettings.design,
        profilePicture,
        selectedCVMal: { name: templateName },
        cvFonts: designSettings.fonts.fontFamily,
        cvSize: designSettings.size.fontSize,
        showIcons: true,
        uppercaseHeadlines: false
      };
      
      // Render the correct template based on template name
      let template;
      switch (templateName) {
        case "Toyko":
          template = <Toyko {...templateProps} />;
          break;
        case "Mainla":
          template = <Mainla {...templateProps} />;
          break;
        case "Cali":
          template = <Cali {...templateProps} />;
          break;
        case "Stokkholm":
          template = <Stokkholm {...templateProps} />;
          break;
        case "Asu":
          template = <Asu {...templateProps} />;
          break;
        case "NTNU":
          template = <NTNU {...templateProps} />;
          break;
        case "Filipina":
          template = <Filipina {...templateProps} />;
          break;
        default:
          template = <Mainla {...templateProps} />;
      }
      
      // Generate PDF blob
      const blob = await pdf(template).toBlob();
      
      // Create download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `CV_${cvData.Personalia?.firstName || 'Fornavn'}_${cvData.Personalia?.lastName || 'Etternavn'}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      setMessage("CV lastet ned");
      setTimeout(() => setMessage(""), 5000);
    } catch (error) {
      console.error('Error downloading CV:', error);
      setMessage("Kunne ikke laste ned CV");
      setTimeout(() => setMessage(""), 5000);
    } finally {
      // Clear the loading state for this CV
      setLoadingStates(prev => ({ ...prev, [idcv]: null }));
    }
  };

  return (
    <div className="profile">
      <div className="nav-container">
        <div className="hamburger2" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className={`menu ${isMenuOpen ? "open" : ""}`}>
          <h2
            onClick={() => {
              selectCV();
              toggleMenu();
            }}
            className={showCVs ? "active" : ""}
          >
            Mine CVer
          </h2>
          <h2
            onClick={() => {
              selectProfile();
              toggleMenu();
            }}
            className={showProfile ? "active" : ""}
          >
            Min profil
          </h2>
          <h2
            onClick={() => {
              selectSubscriptions();
              toggleMenu();
            }}
            className={showSubscriptions ? "active" : ""}
          >
            Mitt abonnement
          </h2>
        </div>
      </div>

      {/* CV */}
      {showCVs ? (
        <div className="profileContainer">
          <h1>Mine CVer</h1>
          {message && <div className="message">{message}</div>}
          
          {cvs.length === 0 ? (
            <div className="no-cvs-message">
              <p>Du har ingen CVer ennå. Klikk på knappen nedenfor for å lage din første CV.</p>
              <div className="newCVButton">
                <PrimaryButton name="Lag ny CV" onClick={createNewCV} />
              </div>
            </div>
          ) : (
            <>
              <div className="cv-grid">
                {cvs.map((cv, index) => (
                  <CvPreviewThumbnail 
                    key={index} 
                    cv={cv} 
                    onClick={() => editCV(cv.idcv, cv.cvName || cv.cvTemplate)}
                    onEdit={() => editCV(cv.idcv, cv.cvName || cv.cvTemplate)}
                    onDelete={() => deleteCV(cv.idcv)}
                    onCopy={() => copyCV(cv.idcv)}
                    onDownload={() => downloadCV(cv.idcv, cv.cvName || cv.cvTemplate)}
                    isLoading={loadingStates[cv.idcv] === 'downloading'}
                  />
                ))}
              </div>
              
              <div className="newCVButton">
                <PrimaryButton name="Lag ny CV" onClick={createNewCV} />
              </div>
            </>
          )}
        </div>
      ) : (
        <div></div>
      )}

      {showProfile ? (
        <div className="createUser">
          <h1>Rediger personalia</h1>
          <div className="profile-edit-section">
            <form>
              <div className="input-row">
                <Input
                  type="text"
                  placeholder={formData.firstName || "fornavn"}
                  name={formData.firstName}
                  onChange={(value) =>
                    handleInputChange("firstName", value, setFormData)
                  }
                  onBlur={handleSubmit}
                />
                <Input
                  type="text"
                  placeholder={formData.lastName || "etternavn"}
                  name={formData.lastName}
                  onChange={(value) =>
                    handleInputChange("lastName", value, setFormData)
                  }
                  onBlur={handleSubmit}
                />
              </div>
              <div className="input-row">
                <Input
                  type="email"
                  placeholder={formData.email || "email"}
                  name={formData.email}
                  onChange={(value) =>
                    handleInputChange("email", value, setFormData)
                  }
                  onBlur={handleSubmit}
                />
                <Input
                  type="password"
                  placeholder="passord"
                  name={formData.password}
                  onChange={(value) =>
                    handleInputChange("password", value, setFormData)
                  }
                  onBlur={handleSubmit}
                />
              </div>
            </form>
            
            <div className="account-actions">
              <div className="primary-actions">
                <PrimaryButton
                  name="logg ut"
                  onClick={deleteCookie}
                  path="/login"
                />
              </div>
              
              <div className="danger-zone">
                <hr className="danger-divider" />
                <h3>Faresone</h3>
                <p className="danger-description">Denne handlingen kan ikke angres. Dette vil permanent slette din konto og alle tilknyttede data.</p>
                
                {showDeleted ? (
                  <div className="delete-confirmation">
                    <Input
                      type="text"
                      placeholder="skriv SLETT MEG"
                      name="SLETT MEG"
                      onChange={() => {}}
                      onBlur={() => {}}
                    />
                    <CTA2
                      name="SLETT"
                      onClick={(e) => deleteProfile(e)}
                      path="/profile"
                    />
                  </div>
                ) : (
                  <DangerButton
                    name="slett min profil"
                    onClick={showInputField}
                    path="/profile"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {showSubscriptions ? (
        <div className="profileContainer">
          <h1>Mitt abonnement</h1>
          <div className="subscription-wrapper">
            {!subscriptions.length ? (
              <div className="no-subscription-message">
                <p>Du har ingen aktive abonnementer</p>
                <PrimaryButton
                  name="Kjøp abonnement"
                  onClick={() => navigate('/payment')}
                />
              </div>
            ) : (
              <div className="subscription-list">
                {subscriptions.map((subscription) => (
                  <div key={subscription.idpayment} className="subscription-item">
                    <div className="subscription-header">
                      <h3>Abonnement</h3>
                      <span className={`subscription-status ${subscription.subscriptionStatus === "ACTIVE" ? "active" : "inactive"}`}>
                        {subscription.subscriptionStatus === "ACTIVE" ? "Aktiv" : "Inaktiv"}
                      </span>
                    </div>
                    
                    <div className="subscription-details">
                      <div className="detail-row">
                        <span className="detail-label">Dato:</span>
                        <span className="detail-value">{subscription.date}</span>
                      </div>
                      <div className="detail-row">
                        <span className="detail-label">ID:</span>
                        <span className="detail-value">{subscription.uuid}</span>
                      </div>
                      <div className="detail-row">
                        <span className="detail-label">Avtale ID:</span>
                        <span className="detail-value">{subscription.agreementId}</span>
                      </div>
                      {subscription.chargeId && (
                        <div className="detail-row">
                          <span className="detail-label">Betalings ID:</span>
                          <span className="detail-value">{subscription.chargeId}</span>
                        </div>
                      )}
                    </div>
                    
                    {subscription.subscriptionStatus === "ACTIVE" && (
                      <div className="subscription-actions">
                        <DangerButton
                          name="Avbryt abonnement"
                          onClick={() => cancelSubscription(subscription.agreementId)}
                        />
                      </div>
                    )}
                    
                    {message && (
                      <p className="subscription-message">{message}</p>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
