import "../css/Design.css";
import { useState, useEffect } from "react";
import saveData from "./saveData";
import updateCV from "./updateCV";
import axios from "axios";
import { useAuth } from "../components/AuthContext";
import { useLocalStorage } from '../hooks/useLocalStorage';


export default function FontDropdown({templates, cvFonts}) {
    const { isAuthenticated } = useAuth();
    const [serverLastUpdated, setServerLastUpdated] = useState(null);
    const [fonts, setFonts] = useState([]);
    const [fontFamily, setFontFamily] = useLocalStorage('fontFamily', {});
    const [selectedFont, setSelectedFont] = useState(() => {
        const savedFont = localStorage.getItem('fontFamily');
        return savedFont ? JSON.parse(savedFont).fontFamily : "Lato";
    });

    useEffect(() => {
        retrieveFonts();
        
        if (isAuthenticated) {
            if(!localStorage.getItem('fontFamily')) {
                fetchFontSettings();
            }

            const interval = setInterval(() => {
                const localData = JSON.parse(localStorage.getItem('fontFamily'));
                const localLastUpdated = localData?.lastUpdated;
                
                if (localLastUpdated && (!serverLastUpdated || new Date(localLastUpdated) > new Date(serverLastUpdated))) {
                    syncWithServer(localData);
                }
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [isAuthenticated, serverLastUpdated]);

    const syncWithServer = async (data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/cv/fonts`,
                { 
                    ...data,
                    fontFamily: selectedFont,
                    cvId: localStorage.getItem('cvId')
                },
                { withCredentials: true }
            );
            if(response.status === 200) {
                setServerLastUpdated(response.data.lastUpdated);
            }
        } catch (error) {
            console.error('Error syncing with server:', error);
        }
    };

    const fetchFontSettings = async () => {
        let token = window.location.search.split('=')[0];
        let idcv = window.location.search.split('=')[1];
        let cvId = localStorage.getItem('cvId');

        if((cvId && idcv && (cvId === idcv)) || idcv && token === "?idcv") {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieveFonts`,
                    {
                        withCredentials: true,
                        params: { cvId: idcv }
                    }
                );
                if (response.data.fontFamily) {
                    setSelectedFont(response.data.fontFamily);
                    setFontFamily({
                        fontFamily: response.data.fontFamily,
                        lastUpdated: response.data.lastUpdated
                    });
                }
                setServerLastUpdated(response.data.lastUpdated);
            } catch (error) {
                console.error('Error fetching font settings:', error);
            }
        }
    };

    const handleFontChange = (e) => {
        const newFont = e.target.value;
        setSelectedFont(newFont);
        const lastUpdated = new Date().toISOString();
        setFontFamily({ fontFamily: newFont, lastUpdated });
        // let idcv = window.location.search.split('=')[1];
        // if(idcv) {
        //     updateCV({ font: newFont, lastUpdated }, idcv)
        // } else {
        //     saveData({ font: newFont, lastUpdated });
        // }
    };

    function retrieveFonts(){
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/getFonts`).then((response) => {
            setFonts(response.data);
        });
    }
    
    return(
        <div>
            <h2>Velg font</h2>
            <select 
                onChange={(e) => {

                    handleFontChange(e);
                }} 
                value={JSON.parse(localStorage.getItem('fontFamily'))?.fontFamily || selectedFont} 
                style={{fontFamily: cvFonts || selectedFont}}
            >
                {fonts.map((font, index) => (
                    <option 
                        key={index}
                        className={font.fontName} 
                        value={font.fontName}
                    >
                        {font.fontName}
                    </option>
                ))}
            </select>
        </div>
    )
}