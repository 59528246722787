import React from "react";
import "./templates.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Circle,
  Image,
  Link,
  Canvas,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
import Star from "../../img/Star.js";
import axios from "axios";
import { useEffect, useState } from "react";
import registerFonts from "./Fonts";
import renderStars from "./RenderStars";
import StarIcon from "./StarIcon.js";
import safeGet from "./SafeGet.js";
// import selectedCVMalDeets from "./SelectedMalDeets.js";
import convertImageToImg from "./convertToImage.js";
import Calendar from "../../components/cv/icons/Calendar.js";
import Email from "../../components/cv/icons/Email.js";
import Phone from "../../components/cv/icons/Phone.js";
import Location from "../../components/cv/icons/Location.js";
import Business from "../../components/cv/icons/Business.js";

const Filipina = ({
  cvData,
  cvDesign,
  selectedCVMal,
  cvFonts,
  cvSize,
  profilePicture,
  showIcons = true,
  uppercaseHeadlines = false,
}) => {
  registerFonts();
  let globalColors = {};
  const [references, setReferences] = useState(() => {
    try {
      const savedState = localStorage.getItem("referanser");
      if (!savedState) return false;
      const parsedState = JSON.parse(savedState);
      return parsedState?.toggleState ?? false;
    } catch (error) {
      console.error("Error reading references state:", error);
      return false;
    }
  });
  // if (cvDesign) {

  globalColors = {
    primary: cvDesign ? cvDesign.primaryColor : "#27384D",
    secondary: cvDesign ? cvDesign.secondaryColor : "#d6e4fe",
    accent: cvDesign ? cvDesign.tertiaryColor : globalColors.secondary,
    text: "#333333",
    font: cvFonts || "Poppins",
    textSizeBody: cvSize || 8,

    // };
  };
  // [{"id": 1, "hex": "#FEEED6"}, {"id": 2, "hex": "#d6e4fe"}, {"id": 3, "hex": "#d2d3f7"}, {"id": 4, "hex": "#f2dedc"}, {"id": 5, "hex": "#d4f2dc"}]
  // Create styles for the PDF
  const styles = StyleSheet.create({
    document: {
      width: "595px",
      height: "841px",
      backgroundColor: globalColors.secondary,
    },
    page: {
      display: "flex",
      flexDirection: "column",
      fontSize: globalColors.textSizeBody,
      height: "100%",
      position: "relative",
      backgroundColor: globalColors.secondary,
      padding: "30px",
    },

    hero: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      // justifyContent: "center",
      width: "100%",
      marginBottom: 20,
      // height: "200px",
    },
    contact: {
      display: "flex",
      width: "40%",
      justifyContent: "start",
      alignItems: "start",
      // marginLeft: "30px",
    },
    headline: {
      display: "flex",
      width: "60%",
      justifyContent: "center",
      alignItems: "center",
    },
    blob: {
      backgroundColor: globalColors.primary,
      width: 100,
      height: 100,
      borderRadius: "50%",
      position: "absolute",
      top: -20,
      left: -205,
      zIndex: 10,
    },
    blob2: {
      // backgroundColor: globalColors.primary,
      // width: 150,
      // height: 150,
      // borderRadius: "50%",
      position: "absolute",
      bottom: -20,
      right: -20,
      zIndex: 20,
    },
    firstNameSection: {
      // marginBottom: 40,
      // marginTop: 40,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginLeft: "30px",
    },
    firstName: {
      fontSize: globalColors.textSizeBody + 20,
      fontFamily: globalColors.font,
      fontWeight: "extrabold",
      display: "block",
    },
    divider2: {
      width: "60%",
      marginLeft: "0px",
      height: 1.7,
      backgroundColor: globalColors.primary,
      marginBottom: "10px",
    },
    divider3: {
      width: "100%",
      marginLeft: "0px",
      height: 1.7,
      backgroundColor: globalColors.primary,
      marginBottom: "10px",
    },
    line: {
      height: "100%",
      // minHeight: "90%",
      // maxHeight: "100%",
      width: 1.7,
      backgroundColor: "#D3D3D3", // lightgray
      // marginBottom: "10px",
      marginRight: "20px",
      marginTop: "50px",
    },
    sectionTitle: {
      fontSize: globalColors.textSizeBody + 4,
      letterSpacing: 1.2,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: -8,
      marginTop: 5,
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 10,
      color: "black",
      // textTransform: "uppercase",
    },
    textItem: {
      marginBottom: 3,
      fontSize: globalColors.textSizeBody,
    },
    bold: {
      fontFamily: globalColors.font,
      fontWeight: "bold",
    },
    subTitle: {
      fontSize: globalColors.textSizeBody + 4,
      letterSpacing: 1.5,
      marginBottom: 2,
      marginTop: 2,
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
    },
    columnGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginBottom: "10px",
    },
    circle: {
      marginRight: 3,
      marginLeft: 10,
      marginTop: 3,
    },
    circleDot: {
      marginRight: 17,
      marginLeft: -26,
      // marginTop: 3,
    },
    light: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
      letterSpacing: 0.1,
      fontWeight: "400",
      fontFamily: globalColors.font,
      marginRight: "10px",
    },
    // sidebar: {
    //   width: "30%",
    //   padding: 15,
    //   margin: 20,
    //   border: "2px solid " + globalColors.primary,
    //   borderRadius: 20,
    //   color: globalColors.primary,
    //   display: "flex",
    //   flexDirection: "column",
    //   position: "relative",
    //   zIndex: 4,
    // },
    // profilePicture: {
    //   display: "flex",
    //   width: "120px",
    //   height: "120px",
    //   objectFit: "cover",
    //   borderRadius: "50%",
    //   borderColor: globalColors.primary,
    //   borderWidth: 2,
    //   marginBottom: 10,
    //   backgroundColor: globalColors.secondary,
    //   justifyContent: "center",
    //   alignItems: "center",
    //   alignSelf: "center",
    //   zIndex: 3,
    // },
    // stars: {
    //   display: "flex",
    //   width: "10px",
    //   height: "10px",
    //   marginBottom: 10,
    //   justifyContent: "center",
    //   alignItems: "center",
    //   alignSelf: "center",
    // },
    // mainContent: {
    //   width: "70%",
    //   padding: 10,
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "start",
    //   marginTop: "30px",
    //   position: "relative",
    // },
    // sectionTitle: {
    //   fontSize: globalColors.textSizeBody,
    //   fontFamily: globalColors.font,
    //   fontWeight: "bold",
    //   marginBottom: 0,
    //   marginTop: 5,
    //   paddingTop: 8,
    //   paddingBottom: 8,
    //   paddingRight: 10,
    //   color: globalColors.primary,
    //   textTransform: "uppercase",
    // },
    // firstNameSection: {
    //   marginBottom: 40,
    //   marginTop: 40,
    //   fontFamily: globalColors.font,
    //   fontWeight: "bold",
    // },
    // firstName: {
    //   fontSize: globalColors.textSizeBody + 20,
    //   fontFamily: globalColors.font,
    //   fontWeight: "bold",
    //   display: "block",
    // },
    // sectionTitleMain: {
    //   fontSize: globalColors.textSizeBody + 4,
    //   fontFamily: globalColors.font,
    //   fontWeight: "bold",
    //   marginBottom: 5,
    //   marginTop: 10,
    // },
    // starContainer: {
    //   display: "flex",
    //   flexDirection: "row",
    //   marginBottom: 5,
    // },
    // star: {
    //   width: 10,
    //   height: 10,
    //   marginRight: 3,
    // },
    // textItem: {
    //   marginBottom: 3,
    //   fontSize: globalColors.textSizeBody - 2,
    // },
    link: {
      color: "black",
      textDecoration: "none",
      fontSize: globalColors.textSizeBody,
      display: "inline",
    },
    groupItems: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 3,
    },
    groupItemsSoc: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      width: "60%",
    },
    // columnGroup: {
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "start",
    //   marginBottom: "10px",
    // },
    // divider: {
    //   backgroundColor: globalColors.primary,
    // },
    // divider2: {
    //   marginLeft: "0px",
    //   height: 1.7,
    //   backgroundColor: globalColors.secondary,
    //   marginBottom: "10px",
    // },
    // bold: {
    //   fontFamily: globalColors.font,
    //   fontWeight: "bold",
    // },
    // text: {
    //   fontSize: globalColors.textSizeBody,
    //   marginBottom: 2,
    //   marginTop: 2,
    // },
    // light: {
    //   fontSize: globalColors.textSizeBody,
    //   marginBottom: 2,
    //   marginTop: 2,
    //   fontWeight: "normal",
    //   fontFamily: globalColors.font,
    // },

    // blob2: {
    //   backgroundColor: globalColors.primary,
    //   width: 150,
    //   height: "100%",
    //   position: "absolute",
    //   top: 0,
    //   left: -150,
    //   zIndex: 20,
    // },
    // blob3: {
    //   backgroundColor: globalColors.accent,
    //   width: 120,
    //   height: 120,
    //   borderRadius: 100,
    //   objectPositionX: "-10px",
    //   objectPositionY: "-10px",
    //   position: "absolute",
    //   bottom: -30,
    //   right: -30,
    // },
  });

  const Divider = () => <View style={styles.divider} />;
  const Divider2 = () => <View style={styles.divider2} />;
  const Divider3 = () => <View style={styles.divider3} />;
  const Line = () => <View style={styles.line} />;

  const [cvMal, setCVMal] = useState(null);
  convertImageToImg();
  useEffect(() => {
    const selectedCVFromLocalStorage = localStorage.getItem("selectedCV");
    if (selectedCVFromLocalStorage) {
      setCVMal(JSON.parse(selectedCVFromLocalStorage));
    }
    // selectedCVMalDeets(selectedCVMal, setCVMal);
  }, []);
  // }, [selectedCVMal]);

  if (!cvData) return null;

  // Function to handle section title text transformation
  const formatSectionTitle = (title) => {
    return uppercaseHeadlines ? title.toUpperCase() : title;
  };

  return (
    <Document
      size="A4"
      orientation="portrait"
      className="document"
      style={styles.document}
    >
      <Page style={styles.page}>
        {/* Sidebar for personal details */}

        {/* <Canvas id="magic" style={styles.blob2}></Canvas> */}
        <Svg
          style={styles.blob2}
          width="112"
          height="113"
          viewBox="0 0 112 113"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M111.5 112.5V8.99993C111.5 8.99993 57.4997 -17.5001 19.4997 27.9999C-18.5003 73.4999 13.9997 112.5 13.9997 112.5H111.5Z"
            fill={globalColors.primary}
            stroke={globalColors.primary}
          />
        </Svg>

        <View style={styles.hero}>
          <View style={styles.contact}>
            <Text style={styles.sectionTitle}>
              {formatSectionTitle("Kontakt")}
            </Text>

            <Divider2 />
            {safeGet(cvData, "Personalia.date") && (
              <View style={styles.groupItems}>
                {showIcons && <Calendar globalColors={globalColors.text} />}
                {showIcons && <View style={{ width: "5px" }}></View>}
                <Text style={styles.textItem}>
                  Født: {safeGet(cvData, "Personalia.date")}
                </Text>
              </View>
            )}
            {safeGet(cvData, "Personalia.email") && (
              <View style={styles.groupItems}>
                {showIcons && <Email globalColors={globalColors.text} />}
                {showIcons && <View style={{ width: "5px" }}></View>}
                {!showIcons && <Text>Epost: </Text>}
                <Text style={styles.textItem}>
                  {safeGet(cvData, "Personalia.email")}
                </Text>
              </View>
            )}
            {safeGet(cvData, "Personalia.phonenumber") && (
              <View style={styles.groupItems}>
                {showIcons && <Phone globalColors={globalColors.text} />}
                {showIcons && <View style={{ width: "5px" }}></View>}
                {!showIcons && <Text>Telefon: </Text>}
                <Text style={styles.textItem}>
                  {safeGet(cvData, "Personalia.phonenumber")}
                </Text>
              </View>
            )}
            {safeGet(cvData, "Personalia.address") && (
              <View style={styles.groupItems}>
                {showIcons && <Location globalColors={globalColors.text} />}
                {showIcons && <View style={{ width: "5px" }}></View>}
                {!showIcons && <Text>Adresse: </Text>}
                <Text style={styles.textItem}>
                  {safeGet(cvData, "Personalia.address")}
                </Text>
              </View>
            )}
          </View>
          <View style={styles.headline}>
            <View>
              <Canvas style={styles.blob}></Canvas>
            </View>

            <View style={styles.firstNameSection}>
              {" "}
              <Text
                style={{
                  ...styles.firstName,
                  // color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {uppercaseHeadlines
                  ? `${safeGet(
                      cvData,
                      "Personalia.firstName",
                      ""
                    ).toUpperCase()} ${safeGet(
                      cvData,
                      "Personalia.lastName",
                      ""
                    ).toUpperCase()}`
                  : `${safeGet(cvData, "Personalia.firstName", "")} ${safeGet(
                      cvData,
                      "Personalia.lastName",
                      ""
                    )}`}
              </Text>
              <Text style={{ ...styles.bold, ...styles.subTitle }}>
                {safeGet(cvData, "Personalia.jobtitle").toUpperCase()}
              </Text>
            </View>

            {/* <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvDesign, "primaryColor", "#000000"),
            }}
          >
            Arbeidserfaring
          </Text> */}
          </View>
        </View>

        <View style={styles.mainContent}>
          <Text style={styles.sectionTitle}>
            {formatSectionTitle("Om meg")}
          </Text>

          <Divider3 />
          <Text style={styles.light}>
            {safeGet(cvData, "kort oppsummering")}
          </Text>
        </View>

        <View style={styles.row}>
          {/* Education, skills, languages, etc */}
          <View style={{ ...styles.column, width: "40%" }}>
            {safeGet(cvData, "Utdanning", []).length > 0 && (
              <Text
                style={{
                  ...styles.sectionTitle,
                  // color: safeGet(cvDesign, "secondaryColor", "#000000"),
                }}
              >
                {formatSectionTitle("Utdanning")}
              </Text>
            )}
            <Divider2 />
            {safeGet(cvData, "Utdanning", []).map((education, index) => (
              <View key={index} style={styles.columnGroup}>
                <Text style={styles.bold}>
                  {safeGet(education, "formData.utdanningsnavn")}
                </Text>
                <Text style={styles.text}>
                  {safeGet(education, "formData.fra dato")} -{" "}
                  {safeGet(education, "formData.til dato", "Nåværende")}
                </Text>
                <Text style={styles.text}>
                  {safeGet(education, "formData.utdanningssted")}
                </Text>
                {/* <Text style={styles.text}>
                {safeGet(education, "formData.oppsummering")}
              </Text> */}
              </View>
            ))}

            {safeGet(cvData, "Ferdigheter", []).length > 0 && (
              <Text style={styles.sectionTitle}>
                {formatSectionTitle("Ferdigheter")}
              </Text>
            )}
            <Divider2 />
            {safeGet(cvData, "Ferdigheter", []).map((skill, index) => (
              <View key={index} style={styles.groupItems}>
                <Text style={styles.textItem}>
                  {safeGet(skill, "formData.ferdigheter")}
                </Text>
              </View>
            ))}

            {safeGet(cvData, "Språk", []).length > 0 && (
              <Text style={styles.sectionTitle}>
                {formatSectionTitle("Språk")}
              </Text>
            )}
            <Divider2 />
            {safeGet(cvData, "Språk", []).map((language, index) => (
              <View key={index} style={styles.groupItems}>
                <Text style={styles.textItem}>
                  {safeGet(language, "formData.språk")}
                </Text>
                <Text style={styles.textItem}>
                  {safeGet(language, "formData.navn")}
                </Text>
              </View>
            ))}

            {safeGet(cvData, "Interesser", []).length > 0 && (
              <Text style={styles.sectionTitle}>
                {formatSectionTitle("Interesser")}
              </Text>
            )}
            {safeGet(cvData, "Interesser", []).length > 0 && <Divider2 />}
            {/* <Text style={styles.sectionTitle}>Interesser</Text> */}
            {/* <Divider2 /> */}
            {safeGet(cvData, "Interesser", []).map((interest, index) => (
              <View key={index} style={styles.groupItems}>
                <Text style={styles.textItem}>
                  {safeGet(interest, "formData.interesser")}
                </Text>
                <Text style={styles.textItem}>
                  {safeGet(interest, "formData.navn")}
                </Text>
              </View>
            ))}

            <View wrap={false}>
              {safeGet(cvData, "Portefølje") &&
                Object.keys(safeGet(cvData, "Portefølje")).length > 0 && (
                  <Text
                    style={{
                      ...styles.sectionTitle,
                      // color: safeGet(cvDesign, "primaryColor", "#000000"),
                    }}
                  >
                    {formatSectionTitle("Portefølje")}
                  </Text>
                )}
              {safeGet(cvData, "Portefølje") &&
                Object.keys(safeGet(cvData, "Portefølje")).length > 0 && (
                  <Divider2 />
                )}
              <View style={styles.groupItemsSoc}>
                {safeGet(cvData, "Portefølje.instagram") && (
                  <Link
                    style={styles.link}
                    src={safeGet(cvData, "Portefølje.instagram")}
                  >
                    Instagram
                  </Link>
                )}
                {safeGet(cvData, "Portefølje.twitter") && (
                  <Link
                    style={styles.link}
                    src={safeGet(cvData, "Portefølje.twitter")}
                  >
                    Twitter
                  </Link>
                )}
                {safeGet(cvData, "Portefølje.github") && (
                  <Link
                    style={styles.link}
                    src={safeGet(cvData, "Portefølje.github")}
                  >
                    Github
                  </Link>
                )}
                {safeGet(cvData, "Portefølje.linkedin") && (
                  <Link
                    style={styles.link}
                    src={safeGet(cvData, "Portefølje.linkedin")}
                  >
                    Linkedin
                  </Link>
                )}
                {safeGet(cvData, "Portefølje.tiktok") && (
                  <Link
                    style={styles.link}
                    src={safeGet(cvData, "Portefølje.tiktok")}
                  >
                    Tiktok
                  </Link>
                )}
                {safeGet(cvData, "Portefølje.nettside") && (
                  <Link
                    style={styles.link}
                    src={safeGet(cvData, "Portefølje.nettside")}
                  >
                    Nettside
                  </Link>
                )}
              </View>
            </View>
            <View wrap={false}>
              {safeGet(cvData, "Referanser", []).length > 0 && (
                <Text
                  style={{
                    ...styles.sectionTitle,
                    // color: safeGet(cvDesign, "secondaryColor", "#000000"),
                  }}
                >
                  {formatSectionTitle("Referanser")}
                </Text>
              )}
              <Divider2 />
              {references ? (
                <Text style={styles.text}>
                  Referanser oppgis ved forespørsel
                </Text>
              ) : (
                safeGet(cvData, "Referanser", []).map((ref, index) => (
                  <View key={index} style={styles.columnGroup}>
                    <Text style={styles.bold}>
                      {safeGet(ref, "formData.navn")}
                    </Text>{" "}
                    <Text style={styles.bold}>
                      {safeGet(ref, "formData.stillingstittel")}
                    </Text>
                    {!showIcons && <Text>Bedrift: </Text>}
                    <View style={styles.groupItems}>
                      {showIcons && <Business globalColors={"#000000"} />}
                      {showIcons && <View style={{ width: "5px" }}></View>}

                      <Text style={styles.text}>
                        {safeGet(ref, "formData.arbeidssted")}
                      </Text>
                    </View>
                    <View style={styles.groupItems}>
                      {showIcons && <Phone globalColors={globalColors.text} />}
                      {showIcons && <View style={{ width: "5px" }}></View>}
                      {!showIcons && <Text>Telefon: </Text>}
                      <Text style={styles.text}>
                        {safeGet(ref, "formData.telefon")}
                      </Text>
                    </View>
                    <View style={styles.groupItems}>
                      {showIcons && <Email globalColors={globalColors.text} />}
                      {showIcons && <View style={{ width: "5px" }}></View>}
                      {!showIcons && <Text>Epost: </Text>}

                      <Text style={styles.text}>
                        {safeGet(ref, "formData.epost")}
                      </Text>
                    </View>
                  </View>
                ))
              )}
            </View>
          </View>
          {/* Experience, References, Courses, etc */}
          <View style={{ ...styles.column, width: "60%" }}>
            <View style={styles.row}>
              {/* Stylistic Line + Experience */}
              <Line />
              <View style={{ ...styles.column, width: "90%" }}>
                {safeGet(cvData, "Arbeidserfaring", []).length > 0 && (
                  <Text
                    style={{
                      ...styles.sectionTitle,
                      // color: safeGet(cvDesign, "secondaryColor", "#000000"),
                    }}
                  >
                    {formatSectionTitle("Arbeidserfaring")}
                  </Text>
                )}
                <Divider2 />
                {safeGet(cvData, "Arbeidserfaring", []).map((job, index) => (
                  <View key={index} style={styles.columnGroup}>
                    <View style={styles.row}>
                      <Svg
                        style={styles.circleDot}
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <Circle
                          cx="5"
                          cy="5"
                          r="5"
                          fill={globalColors.primary}
                        />
                      </Svg>

                      <Text style={styles.bold}>
                        {safeGet(job, "formData.arbeidssted")}
                      </Text>
                    </View>

                    <Text style={styles.bold}>
                      {safeGet(job, "formData.stillingstittel")}
                    </Text>
                    <Text style={styles.text}>
                      {safeGet(job, "formData.fra dato")} -{" "}
                      {safeGet(job, "formData.til dato", "Nåværende")}
                    </Text>
                    <View style={styles.row}>
                      {/* <Svg width="10" height="10" viewBox="0 0 10 10"> */}
                      <Svg
                        style={styles.circle}
                        width="5"
                        height="5"
                        viewBox="0 0 5 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <Circle
                          cx="2.5"
                          cy="2.5"
                          r="2.5"
                          fill={globalColors.text}
                        />
                      </Svg>

                      {/* <Path d="M0 0 L10 10" stroke="black" strokeWidth={2} /> */}
                      {/* </Svg> */}
                      <Text style={styles.light}>
                        {safeGet(job, "formData.oppsummering")}
                      </Text>
                    </View>
                  </View>
                ))}

                <View wrap={false}>
                  {safeGet(cvData, "Kurs", []).length > 0 && (
                    <Text
                      style={{
                        ...styles.sectionTitle,
                        // color: safeGet(cvDesign, "primaryColor", "#000000"),
                      }}
                    >
                      {formatSectionTitle("Kurs")}
                    </Text>
                  )}
                  {safeGet(cvData, "Kurs", []).length > 0 && <Divider2 />}
                  {safeGet(cvData, "Kurs", []).map((kurs, index) => (
                    <View key={index} style={styles.columnGroup}>
                      <View style={styles.row}>
                        <Svg
                          style={styles.circleDot}
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <Circle
                            cx="5"
                            cy="5"
                            r="5"
                            fill={globalColors.primary}
                          />
                        </Svg>
                        <Text style={styles.bold}>
                          {safeGet(kurs, "formData.kurs")}
                        </Text>
                      </View>
                      <Text style={styles.bold}>
                        {safeGet(kurs, "formData.sted")}
                      </Text>
                      <Text style={styles.text}>
                        {safeGet(kurs, "formData.fra dato")} -{" "}
                        {safeGet(kurs, "formData.til dato", "Nåværende")}
                      </Text>

                      <View style={styles.row}>
                        {/* <Svg width="10" height="10" viewBox="0 0 10 10"> */}
                        <Svg
                          style={styles.circle}
                          width="5"
                          height="5"
                          viewBox="0 0 5 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <Circle
                            cx="2.5"
                            cy="2.5"
                            r="2.5"
                            fill={globalColors.text}
                          />
                        </Svg>

                        {/* <Path d="M0 0 L10 10" stroke="black" strokeWidth={2} /> */}
                        {/* </Svg> */}
                        <Text style={styles.light}>
                          {safeGet(kurs, "formData.oppsummering")}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
                <View wrap={false}>
                  {safeGet(cvData, "Frivillighet", []).length > 0 && (
                    <Text
                      style={{
                        ...styles.sectionTitle,
                        // color: safeGet(cvDesign, "secondaryColor", "#000000"),
                      }}
                    >
                      {formatSectionTitle("Frivillighet")}
                    </Text>
                  )}
                  {safeGet(cvData, "Frivillighet", []).length > 0 && (
                    <Divider2 />
                  )}
                  {safeGet(cvData, "Frivillighet", []).map(
                    (frivillighet, index) => (
                      <View key={index} style={styles.columnGroup}>
                        <View style={styles.row}>
                          <Svg
                            style={styles.circleDot}
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <Circle
                              cx="5"
                              cy="5"
                              r="5"
                              fill={globalColors.primary}
                            />
                          </Svg>
                          <Text style={styles.bold}>
                            {safeGet(frivillighet, "formData.frivillighet")}
                          </Text>{" "}
                        </View>
                        <Text style={styles.bold}>
                          {safeGet(frivillighet, "formData.sted")}
                        </Text>
                        <Text style={styles.text}>
                          {safeGet(frivillighet, "formData.fra dato")} -{" "}
                          {safeGet(
                            frivillighet,
                            "formData.til dato",
                            "Nåværende"
                          )}
                        </Text>
                        <View style={styles.row}>
                          {/* <Svg width="10" height="10" viewBox="0 0 10 10"> */}
                          <Svg
                            style={styles.circle}
                            width="5"
                            height="5"
                            viewBox="0 0 5 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <Circle
                              cx="2.5"
                              cy="2.5"
                              r="2.5"
                              fill={globalColors.text}
                            />
                          </Svg>

                          {/* <Path d="M0 0 L10 10" stroke="black" strokeWidth={2} /> */}
                          {/* </Svg> */}
                          <Text style={styles.light}>
                            {safeGet(frivillighet, "formData.oppsummering")}
                          </Text>
                        </View>
                      </View>
                    )
                  )}
                </View>

                <View wrap={false}>
                  {safeGet(cvData, "Militærtjeneste", []).length > 0 && (
                    <Text
                      style={{
                        ...styles.sectionTitle,
                        // color: safeGet(cvDesign, "secondaryColor", "#000000"),
                      }}
                    >
                      {formatSectionTitle("Militærtjeneste")}
                    </Text>
                  )}
                  {safeGet(cvData, "Militærtjeneste", []).length > 0 && (
                    <Divider2 />
                  )}
                  {safeGet(cvData, "Militærtjeneste", []).map(
                    (military, index) => (
                      <View key={index} style={styles.columnGroup}>
                        <View style={styles.row}>
                          <Svg
                            style={styles.circleDot}
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <Circle
                              cx="5"
                              cy="5"
                              r="5"
                              fill={globalColors.primary}
                            />
                          </Svg>
                          <Text style={styles.bold}>
                            {safeGet(military, "formData.militærtjeneste")}
                          </Text>{" "}
                        </View>
                        <Text style={styles.bold}>
                          {safeGet(military, "formData.sted")}
                        </Text>
                        <Text style={styles.text}>
                          {safeGet(military, "formData.fra dato")} -{" "}
                          {safeGet(military, "formData.til dato", "Nåværende")}
                        </Text>
                        <View style={styles.row}>
                          {/* <Svg width="10" height="10" viewBox="0 0 10 10"> */}
                          <Svg
                            style={styles.circle}
                            width="5"
                            height="5"
                            viewBox="0 0 5 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <Circle
                              cx="2.5"
                              cy="2.5"
                              r="2.5"
                              fill={globalColors.text}
                            />
                          </Svg>

                          {/* <Path d="M0 0 L10 10" stroke="black" strokeWidth={2} /> */}
                          {/* </Svg> */}
                          <Text style={styles.light}>
                            {safeGet(military, "formData.oppsummering")}
                          </Text>
                        </View>
                      </View>
                    )
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Filipina;

/*
        <Canvas id="magic" style={styles.blob}></Canvas>

        <View style={styles.hero}> 
          <View style={styles.contact}></View>
          <View style={styles.headline}>
            <View>
            <Canvas style={styles.blob}></Canvas>
            </View>
            <View style={styles.firstNameSection}>
            {" "}
            <Text
              style={{
                ...styles.firstName,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {`${safeGet(cvData, "Personalia.firstName", "")} ${safeGet(
                cvData,
                "Personalia.lastName",
                ""
              )}`}
            </Text>
            <Text style={{ ...styles.bold, ...styles.text }}>
              {safeGet(cvData, "Personalia.jobtitle")}
            </Text>
          </View>

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvDesign, "primaryColor", "#000000"),
            }}
          >
            Arbeidserfaring
          </Text>
          </View>


        </View>
        

        
      </Page>
    </Document>
  );
};

export default Filipina;
*/
