import CVTemplateSlider from "./TemplateSlider";
import FontDropdown from "./FontDropdown.js";
import FontSizeSlider from "./FontSizeSlider.js";
import ColorPicker from "./ColorPicker.js";
import CustomSlider from "./Slider.js";
import axios from "axios";
import { useState, useEffect } from "react";
import getCVMal from "./getCVMal.js";
import { useLocalStorage } from '../hooks/useLocalStorage';

export default function ShowDesign({selectedTemplate, setSelectedTemplate, cvFonts, cvSize}){
    const [cvTemplates, setCvTemplates] = useState([]);
    const [primaryColors, setPrimaryColors] = useState([]);
    const [secondaryColors, setSecondaryColors] = useState([]);
    const [tertiaryColors, setTertiaryColors] = useState([]);
    const [designSettings, setDesignSettings] = useLocalStorage('designSettings', {
        showIcons: true,
        uppercaseHeadlines: false
    });
    
    useEffect(() => {
        getCVMal({setCvTemplates});
        getColors();
    }, []);

    async function getColors(){
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/cvmal/colors`, {idcvMal: selectedTemplate.idcvMal}, {
            withCredentials: true,
        });
        setPrimaryColors(response.data.primaryColors);
        setSecondaryColors(response.data.secondaryColors);
        setTertiaryColors(response.data.tertiaryColors);
    }

    const handleToggleChange = (setting) => {
        const newSettings = {
            ...designSettings,
            [setting]: !designSettings[setting]
        };
        
        // Update state
        setDesignSettings(newSettings);
        
        // Explicitly update localStorage
        localStorage.setItem('designSettings', JSON.stringify(newSettings));
        
        // Dispatch event to notify other components
        window.dispatchEvent(new Event('designSettingsChange'));
        
        console.log(`Design setting changed: ${setting} = ${!designSettings[setting]}`);
        console.log('Updated settings:', newSettings);
    };

    return(
            <div className="design-container">
            <CVTemplateSlider 
                templates={cvTemplates} 
                selectedTemplate={selectedTemplate} 
                setSelectedTemplate={setSelectedTemplate}
            />
            
            <div className="design-options">
                <h2>Design Alternativer</h2>
                <div className="toggle-container">
                    <div className="toggle-item">
                        <span className="toggle-label">Vis ikoner</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={designSettings.showIcons}
                                onChange={() => handleToggleChange('showIcons')}
                            />
                            <span className="toggle-slider"></span>
                        </label>
                    </div>
                    
                    <div className="toggle-item">
                        <span className="toggle-label">Store bokstaver i overskrifter</span>
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={designSettings.uppercaseHeadlines}
                                onChange={() => handleToggleChange('uppercaseHeadlines')}
                            />
                            <span className="toggle-slider"></span>
                        </label>
                    </div>
                </div>
            </div>

                <div className="font-selector">
                    <FontDropdown templates={cvTemplates} cvFonts={cvFonts} />
                </div>
            
                <div className="font-size-slider">  
                    <CustomSlider templates={cvTemplates} cvSize={cvSize} />
            </div>

                <div className="color-picker">
                <ColorPicker 
                    primaryColors={primaryColors} 
                    secondaryColors={secondaryColors} 
                    tertiaryColors={tertiaryColors} 
                />
            </div>
        </div>
    );
}   