// Create styles
// Register custom fonts if needed
import React, { useEffect, useRef, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Canvas,
  pdf,
} from "@react-pdf/renderer";
import * as pdfjsLib from "pdfjs-dist";
import "./templates.css";
import safeGet from "./SafeGet.js";
import registerFonts from "./Fonts.js";
import selectedCVMalDeets from "./SelectedMalDeets.js";
import { ReactComponent as ArrowLeft } from "../../img/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../img/arrowRight.svg";
import Phone from "../../components/cv/icons/Phone.js";
import Email from "../../components/cv/icons/Email.js";
import Location from "../../components/cv/icons/Location.js";
import Calendar from "../../components/cv/icons/Calendar.js";
import Business from "../../components/cv/icons/Business.js";
pdfjsLib.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.10.38/build/pdf.worker.min.mjs`;

const Cali = ({
  cvData,
  cvDesign,
  selectedCVMal,
  cvFonts,
  cvSize,
  profilePicture,
  showIcons = true,
  uppercaseHeadlines = false,
}) => {
  registerFonts();

  let globalColors = {};

  const [references, setReferences] = useState(() => {
    try {
      const savedState = localStorage.getItem("referanser");
      if (!savedState) return false;
      const parsedState = JSON.parse(savedState);
      return parsedState?.toggleState ?? false;
    } catch (error) {
      console.error("Error reading references state:", error);
      return false;
    }
  });

  console.log(cvData, "CVDATA");

  globalColors = {
    primary: cvDesign ? cvDesign.primaryColor : "#672E58",
    secondary: cvDesign ? cvDesign.secondaryColor : "#ffffff",
    accent: cvDesign ? cvDesign.tertiaryColor : "#f2dedc",
    text: "#333333",
    font: cvFonts || "Inter",
    textSizeBody: cvSize || 10,
  };

  // Function to handle section title text transformation
  const formatSectionTitle = (title) => {
    return uppercaseHeadlines ? title.toUpperCase() : title;
  };

  // Create styles for the PDF
  const styles = StyleSheet.create({
    document: {
      width: "595px",
      height: "841px",
      backgroundColor: globalColors.secondary,
    },
    page: {
      display: "flex",
      flexDirection: "row",
      fontSize: globalColors.textSizeBody,
      height: "100%",
      position: "relative",
    },
    sidebar: {
      width: "30%",
      padding: 15,
      margin: 20,
      border: "2px solid " + globalColors.primary,
      borderRadius: 20,
      color: globalColors.primary,
      display: "flex",
      flexDirection: "column",
      position: "relative",
      zIndex: 4,
    },
    profilePicture: {
      display: "flex",
      width: "120px",
      height: "120px",
      objectFit: "cover",
      borderRadius: "50%",
      borderColor: globalColors.primary,
      borderWidth: 2,
      marginBottom: 10,
      backgroundColor: globalColors.secondary,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      zIndex: 3,
    },
    stars: {
      display: "flex",
      width: "10px",
      height: "10px",
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },
    mainContent: {
      width: "70%",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginTop: "0px",
      position: "relative",
    },
    sectionTitle: {
      fontSize: globalColors.textSizeBody,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 0,
      marginTop: 10,
      paddingTop: 8,
      paddingBottom: 5,
      paddingRight: 10,
      color: globalColors.primary,
    },
    firstNameSection: {
      marginBottom: 40,
      marginTop: 40,
      fontSize: globalColors.textSizeBody + 10,
      fontFamily: globalColors.font,
      fontWeight: "bold",
    },
    firstName: {
      fontSize: globalColors.textSizeBody + 10,
      fontFamily: globalColors.font,
      color: globalColors.secondary,
      fontWeight: "bold",
      display: "block",
    },
    sectionTitleMain: {
      fontSize: globalColors.textSizeBody + 4,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 5,
      marginTop: 15,
    },
    starContainer: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 5,
    },
    star: {
      width: 10,
      height: 10,
      marginRight: 3,
    },
    textItem: {
      marginBottom: 3,
      fontSize: globalColors.textSizeBody,
      color: globalColors.text,
    },
    link: {
      color: globalColors.accent,
      textDecoration: "underline",
      fontSize: globalColors.textSizeBody,
      display: "inline",
    },
    groupItems: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 3,
    },
    groupItemsSoc: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      width: "70%",
    },
    columnGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginBottom: "10px",
      color: "black",
    },
    rowGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "10px",
      width: "90%",
    },
    divider: {
      backgroundColor: globalColors.primary,
      marginBottom: "10px",
    },
    divider2: {
      width: "30%",
      marginLeft: "0px",
      height: 1,
      backgroundColor: globalColors.primary,
      marginBottom: "5px",
    },
    bold: {
      fontFamily: globalColors.font,
      fontWeight: "bold",
      fontSize: globalColors.textSizeBody + 2,
    },
    text: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
    },
    light: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
      fontWeight: "normal",
      // fontFamily: globalColors.font,
    },
    blob: {
      backgroundColor: globalColors.accent,
      width: "100%",
      height: 180,
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 10,
    },
    blob2: {
      backgroundColor: globalColors.primary,
      width: 150,
      height: "100%",
      position: "absolute",
      top: 0,
      left: -150,
      zIndex: 20,
    },
    blob3: {
      backgroundColor: globalColors.accent,
      width: 120,
      height: 120,
      borderRadius: 100,
      objectPositionX: "-10px",
      objectPositionY: "-10px",
      position: "absolute",
      bottom: -30,
      right: -30,
    },
  });

  const Divider = () => <View style={styles.divider} />;
  const Divider2 = () => <View style={styles.divider2} />;

  const convertImageToImg = () => {
    const imageTags = document.querySelectorAll("image");

    imageTags.forEach((imageTag) => {
      // Create a new <img> element
      const imgTag = document.createElement("img");

      // Copy over all attributes from the <image> tag to the new <img> tag
      for (let attr of imageTag.attributes) {
        imgTag.setAttribute(attr.name, attr.value);
      }

      // Replace the <image> tag with the new <img> tag in the DOM
      imageTag.parentNode.replaceChild(imgTag, imageTag);
    });
  };

  const [cvMal, setCVMal] = useState(null);
  // useConvertImageToImg();
  convertImageToImg();
  useEffect(() => {
    selectedCVMalDeets(selectedCVMal, setCVMal);
  }, [selectedCVMal]);

  if (!cvData) return null;

  return (
    <Document
      size="A4"
      orientation="portrait"
      className="document"
      style={styles.document}
    >
      <Page style={styles.page}>
        <Canvas id="magic" style={styles.blob}></Canvas>
        <View style={{ ...styles.sidebar }}>
          <Divider />
          <Image
            style={{
              ...styles.profilePicture,
              width: "125px",
              height: "125px",
            }}
            src={profilePicture}
          />
          <Divider />
          <Divider />
          <Text style={styles.sectionTitle}>
            {formatSectionTitle("Kontaktinformasjon")}
          </Text>
          <Divider2 />

          {safeGet(cvData, "Personalia.date") && (
            <View style={styles.groupItems}>
              {showIcons && <Calendar globalColors={globalColors.primary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text style={styles.textItem}>
                Født: {safeGet(cvData, "Personalia.date")}
              </Text>
            </View>
          )}
          {safeGet(cvData, "Personalia.email") && (
            <View style={styles.groupItems}>
              {showIcons && <Email globalColors={globalColors.primary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text style={styles.textItem}>
                {safeGet(cvData, "Personalia.email")}
              </Text>
            </View>
          )}
          {safeGet(cvData, "Personalia.phonenumber") && (
            <View style={styles.groupItems}>
              {showIcons && <Phone globalColors={globalColors.primary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text style={styles.textItem}>
                {safeGet(cvData, "Personalia.phonenumber")}
              </Text>
            </View>
          )}
          {safeGet(cvData, "Personalia.address") && (
            <View style={styles.groupItems}>
              {showIcons && <Location globalColors={globalColors.primary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text style={styles.textItem}>
                {safeGet(cvData, "Personalia.address")}
              </Text>
            </View>
          )}

          {safeGet(cvData, "Ferdigheter", []).length > 0 && (
            <Text style={styles.sectionTitle}>
              {formatSectionTitle("Ferdigheter")}
            </Text>
          )}
          <Divider2 />
          {safeGet(cvData, "Ferdigheter", []).map((skill, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(skill, "formData.ferdigheter")}
              </Text>
            </View>
          ))}

          {safeGet(cvData, "Interesser", []).length > 0 && (
            <Text style={styles.sectionTitle}>
              {formatSectionTitle("Interesser")}
            </Text>
          )}
          {safeGet(cvData, "Interesser", []).length > 0 && <Divider2 />}
          {safeGet(cvData, "Interesser", []).map((interest, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.interesser")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.navn")}
              </Text>
            </View>
          ))}

          {safeGet(cvData, "Språk", []).length > 0 && (
            <Text style={styles.sectionTitle}>
              {formatSectionTitle("Språk")}
            </Text>
          )}
          <Divider2 />
          {safeGet(cvData, "Språk", []).map((language, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.språk")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.navn")}
              </Text>
            </View>
          ))}

          {safeGet(cvData, "Kurs", []).length > 0 && (
            <Text style={styles.sectionTitle}>
              {formatSectionTitle("Kurs")}
            </Text>
          )}
          {safeGet(cvData, "Kurs", []).length > 0 && <Divider2 />}
          {safeGet(cvData, "Kurs", []).map((kurs, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={styles.text}>
                {safeGet(kurs, "formData.fra dato")} -{" "}
                {safeGet(kurs, "formData.til dato", "Nåværende")}
              </Text>
              <Text
                style={{ ...styles.bold, fontSize: globalColors.textSizeBody }}
              >
                {safeGet(kurs, "formData.kurs")} |{" "}
                {safeGet(kurs, "formData.sted")}
              </Text>
              <Text style={styles.light}>
                {safeGet(kurs, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <View wrap={false}>
            {safeGet(cvData, "Frivillighet", []).length > 0 && (
              <Text style={styles.sectionTitle}>
                {formatSectionTitle("Frivillighet")}
              </Text>
            )}
            {safeGet(cvData, "Frivillighet", []).length > 0 && <Divider2 />}
            {safeGet(cvData, "Frivillighet", []).map((frivillighet, index) => (
              <View key={index} style={styles.columnGroup}>
                <Text style={styles.text}>
                  {safeGet(frivillighet, "formData.fra dato")} -{" "}
                  {safeGet(frivillighet, "formData.til dato", "Nåværende")}
                </Text>
                <Text style={styles.bold}>
                  {safeGet(frivillighet, "formData.frivillighet")} |{" "}
                  {safeGet(frivillighet, "formData.sted")}
                </Text>
                <Text style={styles.light}>
                  {safeGet(frivillighet, "formData.oppsummering")}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.mainContent}>
          <View style={styles.firstNameSection}>
            <Text
              style={{
                ...styles.firstName,
                color: safeGet(cvDesign, "secondaryColor", "#000000"),
              }}
            >
              {uppercaseHeadlines
                ? `${safeGet(
                    cvData,
                    "Personalia.firstName",
                    ""
                  ).toUpperCase()} ${safeGet(
                    cvData,
                    "Personalia.lastName",
                    ""
                  ).toUpperCase()}`
                : `${safeGet(cvData, "Personalia.firstName", "")} ${safeGet(
                    cvData,
                    "Personalia.lastName",
                    ""
                  )}`}
            </Text>
            <Text
              style={{
                ...styles.text,
                ...styles.light,
                fontSize: globalColors.textSizeBody + 4,
                color: safeGet(cvDesign, "secondaryColor", "#000000"),
              }}
            >
              {safeGet(cvData, "Personalia.jobtitle")}
            </Text>
            <Text
              style={{
                ...styles.light,
                color: safeGet(cvDesign, "secondaryColor", "#000000"),
              }}
            >
              {safeGet(cvData, "kort oppsummering")}
            </Text>
          </View>

          {safeGet(cvData, "Arbeidserfaring", []).length > 0 && (
            <Text style={styles.sectionTitleMain}>
              {formatSectionTitle("Arbeidserfaring")}
            </Text>
          )}
          <Divider />
          {safeGet(cvData, "Arbeidserfaring", []).map((job, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={styles.text}>
                {safeGet(job, "formData.fra dato")} -{" "}
                {safeGet(job, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.bold}>
                {safeGet(job, "formData.stillingstittel")} |{" "}
                {safeGet(job, "formData.arbeidssted")}
              </Text>
              <Text style={styles.light}>
                {safeGet(job, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          {safeGet(cvData, "Utdanning", []).length > 0 && (
            <Text style={styles.sectionTitleMain}>
              {formatSectionTitle("Utdanning")}
            </Text>
          )}
          <Divider />
          {safeGet(cvData, "Utdanning", []).map((education, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={styles.text}>
                {safeGet(education, "formData.fra dato")} -{" "}
                {safeGet(education, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.bold}>
                {safeGet(education, "formData.utdanningsnavn")} |{" "}
                {safeGet(education, "formData.utdanningssted")}
              </Text>
              <Text style={styles.light}>
                {safeGet(education, "formData.oppsummering")}
              </Text>
            </View>
          ))}
          <View wrap={false}>
            {safeGet(cvData, "Militærtjeneste", []).length > 0 && (
              <Text style={styles.sectionTitleMain}>
                {formatSectionTitle("Militærtjeneste")}
              </Text>
            )}
            <Divider />
            {safeGet(cvData, "Militærtjeneste", []).map((military, index) => (
              <View key={index} style={styles.columnGroup}>
                <Text style={styles.text}>
                  {safeGet(military, "formData.fra dato")} -{" "}
                  {safeGet(military, "formData.til dato", "Nåværende")}
                </Text>
                <Text style={styles.bold}>
                  {safeGet(military, "formData.militærtjeneste")} |{" "}
                  {safeGet(military, "formData.sted")}
                </Text>
                <Text style={styles.light}>
                  {safeGet(military, "formData.oppsummering")}
                </Text>
              </View>
            ))}
          </View>
          {safeGet(cvData, "Referanser", []).length > 0 && (
            <Text style={styles.sectionTitleMain}>
              {formatSectionTitle("Referanser")}
            </Text>
          )}
          <Divider />
          {references ? (
            <Text style={styles.text}>Referanser oppgis ved forespørsel</Text>
          ) : (
            safeGet(cvData, "Referanser", []).map((ref, index) => (
              <View key={index} style={styles.columnGroup}>
                <Text style={styles.bold}>
                  {safeGet(ref, "formData.navn")} |{" "}
                  {safeGet(ref, "formData.stillingstittel")}
                </Text>
              
                  <View style={styles.groupItems}>
                  {showIcons && (
                    <Business globalColors={globalColors.primary} />
                  )}
                  {showIcons && <View style={{ width: "5px" }}></View>}

                  <Text style={styles.text}>
                    {safeGet(ref, "formData.arbeidssted")}
                  </Text>

                </View>
                <View style={styles.groupItems}>
                  {showIcons && <Phone globalColors={globalColors.primary} />}
                  {showIcons && <View style={{ width: "5px" }}></View>}
                  <Text style={styles.text}>
                    {safeGet(ref, "formData.telefon")}
                  </Text>
                </View>
                <View style={styles.groupItems}>
                  {showIcons && <Email globalColors={globalColors.primary} />}
                  {showIcons && <View style={{ width: "5px" }}></View>}
                  <Text style={styles.text}>
                    {safeGet(ref, "formData.epost")}
                  </Text>
                </View>
              </View>
            ))
          )}

          {safeGet(cvData, "Portefølje") &&
            Object.keys(safeGet(cvData, "Portefølje")).length > 0 && (
              <Text style={styles.sectionTitleMain}>
                {formatSectionTitle("Portefølje")}
              </Text>
            )}
          <Divider />
          <View style={styles.groupItemsSoc}>
            {safeGet(cvData, "Portefølje.instagram") && (
              <Link
                style={styles.link}
                src={safeGet(cvData, "Portefølje.instagram")}
              >
                Instagram
              </Link>
            )}
            {safeGet(cvData, "Portefølje.twitter") && (
              <Link
                style={styles.link}
                src={safeGet(cvData, "Portefølje.twitter")}
              >
                Twitter
              </Link>
            )}
            {safeGet(cvData, "Portefølje.github") && (
              <Link
                style={styles.link}
                src={safeGet(cvData, "Portefølje.github")}
              >
                Github
              </Link>
            )}
            {safeGet(cvData, "Portefølje.linkedin") && (
              <Link
                style={styles.link}
                src={safeGet(cvData, "Portefølje.linkedin")}
              >
                Linkedin
              </Link>
            )}
            {safeGet(cvData, "Portefølje.tiktok") && (
              <Link
                style={styles.link}
                src={safeGet(cvData, "Portefølje.tiktok")}
              >
                Tiktok
              </Link>
            )}
            {safeGet(cvData, "Portefølje.nettside") && (
              <Link
                style={styles.link}
                src={safeGet(cvData, "Portefølje.nettside")}
              >
                Nettside
              </Link>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Cali;
