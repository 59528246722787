import { ReactComponent as ArrowLeft } from "../img/arrowLeft.svg"
import { ReactComponent as ArrowRight } from "../img/arrowRight.svg"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios";
import getCVMal from "./getCVMal.js"
import PrimaryButton from "./buttons/PrimaryButton.js"

export default function TemplatesList() {
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleTemplates, setVisibleTemplates] = useState([]);
    const [cvTemplates, setCvTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    // Minimum swipe distance (in px) to trigger navigation
    const minSwipeDistance = 50;

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        
        if (isLeftSwipe) {
            nextTemplates();
        }
        if (isRightSwipe) {
            prevTemplates();
        }
    };

    const handleTemplateClick = (template) => {
      axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/edit-cv`, {
        idcvMal: template.idcvMal
      }, {withCredentials: true} ).then((response) => {
        setIsTransitioning(true);
        setTimeout(() => {
            setSelectedTemplate(template);
            navigate("/lagecv", { state: { template: template, isTransitioning: isTransitioning } });
            setIsTransitioning(false);
        }, 500); // 500ms tr
      });
    };

    const nextTemplates = () => {
        setCurrentIndex((prev) => (prev + 1) % cvTemplates.length);
    };
    
    const prevTemplates = () => {
        setCurrentIndex((prev) => (prev - 1 + cvTemplates.length) % cvTemplates.length);
    };    

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "ArrowLeft") {
              prevTemplates();
            } else if (event.key === "ArrowRight") {
              nextTemplates();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [cvTemplates.length]); 

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (cvTemplates.length > 0) {
          updateVisibleTemplates();
        }
    }, [cvTemplates, currentIndex, isMobile]);
    
    const updateVisibleTemplates = () => {
        const templates = [];
        const numTemplates = isMobile ? 1 : 3;
        
        for (let i = 0; i < numTemplates; i++) {
          const index = (currentIndex + i) % cvTemplates.length;
          templates.push(cvTemplates[index]);
        }
        setVisibleTemplates(templates);
    };

    useEffect(() => {
        getCVMal({setCvTemplates});
    }, []);

    return (
        <section 
            className="template-slider" 
            onKeyDown={(e) => {
                if (e.key === "ArrowLeft") {
                   prevTemplates();
                }
                if (e.key === "ArrowRight") {
                   nextTemplates();
                }
            }}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
        >
            <h2>Prøv en av våre CV-maler</h2>
            <p>Våre ferdige maler gjør mye av jobben for deg. Ikke forvil, gjennom en prosess kan du redigere elementer ved malene til å bedre passe deg.</p>
            <div className="slider-container">
                <ArrowLeft className="arrowLeft" onClick={prevTemplates}></ArrowLeft>
                <div className="templates-wrapper">
                    {visibleTemplates.map((template) => (
                        <div key={template.id} className="template-preview" onClick={() => handleTemplateClick(template)}>
                            <img className="cvTemplateImages" src={`${template.previewImage}`} alt={template.name}></img>
                            <h3>{template.name}</h3>
                        </div>
                    ))}
                </div>
                <ArrowRight className="arrowRight" onClick={nextTemplates}></ArrowRight>
            </div>
            <PrimaryButton
                name="Lag CV"
                isMalChosen={false}
                path="/velgeMal"
            />
        </section>
    );
}

