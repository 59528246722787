import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CVAccordion from "../components/CVAccordion";
import TemplatesList from "../components/Templates";
import PricesComponent from "../components/Prices";
import PrimaryButton from "../components/buttons/PrimaryButton.js";
import "../css/LandingPage.css"; // We'll create this CSS file

import { ReactComponent as YellowBackground } from "../img/yellowBackground.svg";
import { ReactComponent as BlueBackground } from "../img/blueBackground.svg";
import { ReactComponent as BlackBackground } from "../img/blackBackground.svg";
import { ReactComponent as BackgroundFooterBlue } from "../img/backgroundFooterSvg/backgroundBlue.svg";
import { ReactComponent as BackgroundFooterYellow } from "../img/backgroundFooterSvg/backgroundYellow.svg";
import { ReactComponent as BackgroundFooterBlack } from "../img/backgroundFooterSvg/backgroundDark.svg";

import Contact from "./ContactUs.js";
export default function LandingPage() {
  const [isMalChosen, setIsMalChosen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [visibleTemplates, setVisibleTemplates] = useState([]);

  const navigate = useNavigate();

  const [isTransitioning, setIsTransitioning] = useState(false);

  return (
    <div className="landing-page">
      <div className="blob"></div>
      {/* <div className="blob1"></div> */}
      <div className="blob2"></div>
      <div className="blob3"></div>
      {/* <div className="blob4"></div> */}
      <section className="hero-section">
        <div className="hero-container">
          <div className="hero-content">
            {/* <h1>Bygg din CV på rekordtid: Velg en mal og få AI-hjelp til å finjustere innholdet</h1> */}
            <h1>Lag din perfekte CV med hjelp av AI</h1>
            <p>
              Bruk vår CV bygger med integrert AI for å lage din perfekte CV.
              Velg blant flere CV maler og flere forskjellige farger og stiler.
              Få også en søknad skrevet av deg av vår egen SøknadsSvein. Få full
              tilgang til alle funksjoner når du logger inn og aktiverer ditt
              abonnement. La oss hjelpe deg med å få jobben!
            </p>
          </div>

          <div className="hero-image">
            <div className="why-choose-us-image hero-image">
              <svg
                id="imageHeroBlob"
                width="395"
                height="543"
                viewBox="0 0 395 543"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <clipPath id="blobClip2">
                    <path d="M379.526 130.982C379.526 66.9836 319.534 21.1772 270.852 14.0124C222.17 6.84766 186.76 11.8999 151.526 51.983C97.0263 113.983 127.007 138.984 103.389 189.268C79.7705 239.551 39.7506 256.051 23.8882 314.768C6.42215 379.419 15.0262 465.984 75.5265 497.984C136.027 529.984 248.526 534.982 299.026 493.483C360.879 442.655 315.412 381.588 315.026 324.482C314.64 267.376 379.526 213.984 379.526 130.982Z" />
                  </clipPath>
                </defs>

                {/* Image inside the SVG with adjusted positioning */}
                <image
                  xlinkHref={`${process.env.REACT_APP_FRONTEND_URL}/img/manny.jpg`}
                  width="450" // Slightly larger than the SVG
                  height="580" // Slightly larger than the SVG
                  x="-30" // Move left to center better
                  y="-30" // Move up to center better
                  preserveAspectRatio="xMidYMid slice" // Ensures proper fit
                  style={{ clipPath: "url(#blobClip2)", objectFit: "cover" }}
                />
              </svg>
              <svg
                id="yellowBlob"
                width="395"
                height="543"
                viewBox="0 0 395 543"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    d="M379.526 130.982C379.526 66.9836 319.534 21.1772 270.852 14.0124C222.17 6.84766 186.76 11.8999 151.526 51.983C97.0263 113.983 127.007 138.984 103.389 189.268C79.7705 239.551 39.7506 256.051 23.8882 314.768C6.42215 379.419 15.0262 465.984 75.5265 497.984C136.027 529.984 248.526 534.982 299.026 493.483C360.879 442.655 315.412 381.588 315.026 324.482C314.64 267.376 379.526 213.984 379.526 130.982Z"
                    fill="#F6F6FA"
                  />
                  <path
                    d="M379.526 130.982C379.526 66.9836 319.534 21.1772 270.852 14.0124C222.17 6.84766 186.76 11.8999 151.526 51.983C97.0263 113.983 127.007 138.984 103.389 189.268C79.7705 239.551 39.7506 256.051 23.8882 314.768C6.42215 379.419 15.0262 465.984 75.5265 497.984C136.027 529.984 248.526 534.982 299.026 493.483C360.879 442.655 315.412 381.588 315.026 324.482C314.64 267.376 379.526 213.984 379.526 130.982Z"
                    fill="#FAC600"
                  />
              </svg>


            </div>
          </div>
        </div>
        <PrimaryButton
          name="Prøv gratis nå"
          isMalChosen={false}
          path="/velgeMal"
        />

        {/* <div className="vipps-badge-container" style={{ width: "10%" }}>
          <VippsLogo />
        </div> */}
      </section>
      <div className="background-divs">
        <section className="features-section">
          <h2>Vi hjelper deg med å få jobben</h2>
          <p>
            Med vår AI bygger samt våre profesjonelle cver, gjør vi at du
            stikker deg ut fra de andre søknadene.
          </p>
          <div className="features-container">
            <div className="feature">
              <img
                className="feature-image"
                src={
                  process.env.REACT_APP_FRONTEND_URL + "/img/søknadssvein.png"
                }
                alt="apply"
              ></img>
              <h3>SøknadsSvein</h3>
              <p>Få en søknad skrevet av vår egen SøknadsSvein.</p>
            </div>
            <div className="feature">
              <img
                className="feature-image"
                src={process.env.REACT_APP_FRONTEND_URL + "/img/applyImg.png"}
                alt="apply"
              ></img>
              <h3>AI Bygger</h3>
              <p>
                Bygg din egen CV med vår AI bygger. Få hjelp til å lage
                profesjonelle beskrivelser.
              </p>
            </div>

            <div className="feature">
              <img
                className="feature-image"
                src={
                  process.env.REACT_APP_FRONTEND_URL + "/img/responsiveCV.png"
                }
                alt="apply"
              ></img>
              <h3>Responsiv CV-bygger</h3>
              <p>
                Bygg en overbevisende curriculum vitae enkelt, også fra mobil.
              </p>
            </div>
          </div>
        </section>
        <div className="background-blob-yellow">
          <YellowBackground id="yellowBackgroundBlob" />
        </div>
        <div className="background-blob-blue">
          <BlueBackground id="blueBackgroundBlob" />
        </div>
        <div className="background-blob-black">
          <BlackBackground id="blackBackgroundBlob" />
        </div>

        <TemplatesList />
        {/* </div> */}
      </div>

      <section className="why-choose-us">
        <div className="why-choose-us-container">
          <h2 style={{ fontSize: "47px" }}>Hvorfor velge oss?</h2>
          <p>
            Vi har samarbeid med store og små bedrifter for å kunne hjelpe deg
            til å legge inn relevante opplysninger i din CV.{" "}
          </p>
          {/* <p>Vi jobber også med NAV.</p> */}
          <p>
            Dersom du ønsker at vi skal se gjennom din CV, så kan du sende den
            til <a href="mailto:support@lagecv.no">support@lagecv.no</a>
          </p>
          <PrimaryButton
            name="Start her"
            isMalChosen={false}
            path="/velgeMal"
          />
        </div>
        <div className="why-choose-us-image hero-image">
          <svg
            id="blobContainer"
            width="393"
            height="630"
            viewBox="0 0 393 630"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <clipPath id="blobClip">
                <path
                  d="M73.3006 119.675C94.979 59.8851 201.956 -8.44127 264.814 1.23788C323.096 10.2126 375.636 109.193 389.5 163C406.426 228.694 353.955 245.306 342 321.5C328.665 406.488 417.909 518.446 364.314 585.738C288.892 680.436 115.284 607.102 35.2577 516.261C-49.1316 420.468 44.6817 371.813 55.948 291.943C67.3231 211.301 55.8862 167.704 73.3006 119.675Z"
                  fill="#0061FA"
                />
              </clipPath>
            </defs>

            {/* Image inside SVG with proper JSX attributes */}
            <image
              id="imageBlob"
              xlinkHref={`${process.env.REACT_APP_FRONTEND_URL}/img/Img Getty.jpg`}
              width="393"
              height="700"
              style={{ clipPath: "url(#blobClip)" }}
            />
          </svg>
          <svg
            id="blueBlob"
            width="393"
            height="630"
            viewBox="0 0 393 630"
            fill="#0061FA"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M73.3006 119.675C94.979 59.8851 201.956 -8.44127 264.814 1.23788C323.096 10.2126 375.636 109.193 389.5 163C406.426 228.694 353.955 245.306 342 321.5C328.665 406.488 417.909 518.446 364.314 585.738C288.892 680.436 115.284 607.102 35.2577 516.261C-49.1316 420.468 44.6817 371.813 55.948 291.943C67.3231 211.301 55.8862 167.704 73.3006 119.675Z"
              fill="#E7E7F1"
            />
            <path
              d="M73.3006 119.675C94.979 59.8851 201.956 -8.44127 264.814 1.23788C323.096 10.2126 375.636 109.193 389.5 163C406.426 228.694 353.955 245.306 342 321.5C328.665 406.488 417.909 518.446 364.314 585.738C288.892 680.436 115.284 607.102 35.2577 516.261C-49.1316 420.468 44.6817 371.813 55.948 291.943C67.3231 211.301 55.8862 167.704 73.3006 119.675Z"
              fill="#0061FA"
            />
          </svg>
        </div>
      </section>

      <PricesComponent isMalChosen={isMalChosen} />
      <div className="background-divs-footer">
        <section className="cv-info-section">
          <div className="cv-info-container">
            <h2>Ofte stilte spørsmål</h2>
            <CVAccordion />
          </div>
          <div className="contact-container-landing">
            <Contact isComponent={true} />
          </div>
        </section>
        <div className="background-blob-yellow-footer">
          <BackgroundFooterYellow id="backgroundFooterYellow" />
        </div>
        <div className="background-blob-blue-footer">
          <BackgroundFooterBlue id="backgroundFooterBlue" />
        </div>
        <div className="background-blob-black-footer">
          <BackgroundFooterBlack id="backgroundFooterBlack" />
        </div>
      </div>
    </div>
  );
}
