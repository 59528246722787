import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { useLocalStorage } from '../hooks/useLocalStorage';

// import { useLocation } from "react-router-dom";
// Create a context for authentication
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [userInfo, setUserInfo] = useState();
  const [paid, setPaid] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({}); // Default template
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [cvId, setCvId] = useLocalStorage('cvId', null);
  // const pathname = useLocation().pathname;
  let pathname = window.location.pathname;
  useEffect(() => {
    // Check if user is authenticated when the app loads
    checkLogin();

    if (isAuthenticated) {
      checkPaid();
    }
    // if (Object.keys(selectedTemplate).length <= 0) {
    //   axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/cvmal/userSelectedMal`, {}, {
    //     withCredentials: true,
    //   }).then((response) => {
    //     setSelectedTemplate(response.data[0]);
    //   });
    // }

    const selectedCV = localStorage.getItem('selectedCV');
    if (selectedCV) {
      setSelectedTemplate(JSON.parse(selectedCV));
    }
  }, []);

  async function checkLogin() {
    // if(pathname === "/profile") {
      axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/check-auth`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
          setUserInfo(response.data.user);
          saveEverythingFromLocalStorage(setCvId);
         
        } else {
          setIsAuthenticated(false);
          
        }
      })
      .catch(() => setIsAuthenticated(false));
    // }
    if (pathname === "/createCVMal") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/isAdmin`, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.authenticated) {
            setIsAdmin(true);
            setUserInfo(response.data.user);
          } else {
            setIsAdmin(false);
          }
        })
        .catch(() => setIsAdmin(false));

    }

    if (
      !isAuthenticated
      // (isAuthenticated === undefined && userInfo === undefined)
    ) {
      // createAnonymousUser();
    }
  }

  async function createAnonymousUser() {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/create-anonymous-token`,
        {},
        { withCredentials: true }
      )
      .then((response) => {
        setUserInfo(response.data);
      });
    // .catch(() => setIsAuthenticated(false));
  }

  async function checkPaid() {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/check-paid`,
        {},
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.subscriptionStatus === "ACTIVE") {
          setPaid(true);
        } else {
          setPaid(false);
        }
      });
  }


  function saveEverythingFromLocalStorage(setCvId) {


const allLocalStorage = {};

for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
   try {
    allLocalStorage[key] = JSON.parse(localStorage.getItem(key));
   } catch (error) {
    allLocalStorage[key] = localStorage.getItem(key);
   }
}

const cvId = localStorage.getItem('cvId');

if(!cvId) {
axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/CV/saveLocalStorage`, { cv: allLocalStorage }, { withCredentials: true }).then((response) => {

if (response.data.cvId) {
  setCvId(response.data.cvId);

}
});
}

  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        isAdmin,
        setIsAdmin,
        userInfo,
        setUserInfo,
        paid,
        setPaid,
        setSelectedTemplate,
        selectedTemplate,
        isTransitioning,
        setIsTransitioning,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
