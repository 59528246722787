import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Canvas,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
import Star from "../../img/Star.js";
import registerFonts from "./Fonts";
import axios from "axios";
import { useEffect, useState } from "react";
import Calendar from "../../components/cv/icons/Calendar.js";
import Email from "../../components/cv/icons/Email.js";
import Phone from "../../components/cv/icons/Phone.js";
import Location from "../../components/cv/icons/Location.js";
import renderStars from "./RenderStars";
import StarIcon from "./StarIcon";
import FontSizeSlider from "../../components/FontSizeSlider.js";
import profilePicPlaceholder from "../../img/profilePicPlaceholder.png";
import safeGet from "./SafeGet.js";
import selectedCVMalDeets from "./SelectedMalDeets.js";
import convertImageToImg from "./convertToImage.js";
import Business from "../../components/cv/icons/Business.js";

const Stokkholm = ({
  cvData,
  cvDesign,
  selectedCVMal,
  cvFonts,
  cvSize,
  profilePicture,
  showIcons = true,
  uppercaseHeadlines = false
}) => {
  registerFonts();
  convertImageToImg();

  let globalColors = {};
  const [references, setReferences] = useState(() => {
    try {
      const savedState = localStorage.getItem('referanser');
      if (!savedState) return false;
      const parsedState = JSON.parse(savedState);
      return parsedState?.toggleState ?? false;
    } catch (error) {
      console.error("Error reading references state:", error);
      return false;
    }
  });
  // if (cvDesign) {
  globalColors = {
    primary: cvDesign ? cvDesign.primaryColor : "#27384D",
    secondary: "#ffffff",
    accent: cvDesign ? cvDesign.tertiaryColor : "#D4F2DC",
    text: "#333333",
    font: cvFonts || "Inter",
    textSizeBody: cvSize || 10,
  };
  // }

  // Function to handle section title text transformation
  const formatSectionTitle = (title) => {
    return uppercaseHeadlines ? title.toUpperCase() : title;
  };

  // Create styles for the PDF
  const styles = StyleSheet.create({
    document: {
      width: "60%",
      height: "100%",
      minHeight: "800px",
      backgroundColor: globalColors.secondary,
      overflow: "hidden",
    },
    page: {
      display: "flex",
      flexDirection: "row",
      fontSize: globalColors.textSizeBody,
      height: "100%",
      position: "relative",
    },
    sidebar: {
      width: "40%",
      padding: 15,
      borderRight: `2px solid ${globalColors.accent}`,
      color: "black",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      zIndex: 2,
    },
    profilePicture: {
      display: "flex",
      width: "120px",
      height: "120px",
      objectFit: "cover",
      borderRadius: "50%",
      borderColor: globalColors.secondary,
      borderWidth: 9,
      marginBottom: 10,
      backgroundColor: globalColors.secondary,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      zIndex: 2,
    },
    stars: {
      display: "flex",
      width: "10px",
      height: "10px",
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },
    mainContent: {
      width: "70%",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginTop: "30px",
      position: "relative",
    },
    sectionTitle: {
      fontSize: globalColors.textSizeBody,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 0,
      marginTop: 5,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 10,
      paddingRight: 10,
      color: globalColors.primary,
      // textTransform: "uppercase",
    },
    firstNameSection: {
      marginBottom: 20,
      marginTop: 20,
      fontSize: globalColors.textSizeBody + 10,
      fontFamily: globalColors.font,
      fontWeight: "bold",
    },
    firstName: {
      fontSize: globalColors.textSizeBody + 10,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      display: "block",
    },
    sectionTitleMain: {
      fontSize: globalColors.textSizeBody + 4,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 10,
      marginTop: 10,
    },
    starContainer: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 5,
    },
    star: {
      width: 10,
      height: 10,
      marginRight: 3,
    },
    textItem: {
      marginBottom: 3,
      fontSize: globalColors.textSizeBody - 1,
    },
    link: {
      color: "black",
      textDecoration: "underline",
      fontSize: globalColors.textSizeBody - 2,
    },
    groupItems: {
      display: "inline",
      flexDirection: "row",
      alignItems: "center",
    },
    groupItemsSoc: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
    },
    columnGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginBottom: "10px",
    },
    divider: {
      width: "90%",
      height: 1.7,
      backgroundColor: globalColors.accent,
      marginBottom: "10px",
    },
    divider2: {
      width: "110%",
      marginLeft: "0px",
      height: 1.7,
      backgroundColor: globalColors.accent,
      marginBottom: "10px",
    },
    bold: {
      fontFamily: globalColors.font,
      fontWeight: "bold",
      fontSize: globalColors.textSizeBody + 1,
    },
    text: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
    },
    light: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
      fontWeight: "normal",
      fontFamily: globalColors.font,
    },
    blob: {
      backgroundColor: globalColors.accent,
      width: "110%",
      height: 30,
      position: "absolute",
      top: 0,
      left: -50,
      zIndex: 4,
    },
    blob2: {
      backgroundColor: globalColors.primary,
      width: 150,
      height: "100%",
      position: "absolute",
      top: 0,
      left: -150,
      zIndex: 20,
    },
    blob3: {
      backgroundColor: globalColors.accent,
      width: 120,
      height: 120,
      borderRadius: 100,
      objectPositionX: "-10px",
      objectPositionY: "-10px",
      position: "absolute",
      bottom: -30,
      right: -30,
    },
  });

  const Divider = () => <View style={styles.divider} />;
  const Divider2 = () => <View style={styles.divider2} />;

  const [cvMal, setCVMal] = useState(null);

  useEffect(() => {
    selectedCVMalDeets(selectedCVMal, setCVMal);
  }, [selectedCVMal]);

  if (!cvData) return null;

  return (
    <Document
      size="A4"
      orientation="portrait"
      className="document"
      style={styles.document}
    >
      <Page style={styles.page}>
        {/* Sidebar for personal details */}
        <Canvas id="magic" style={styles.blob}></Canvas>
        <View style={{ ...styles.sidebar }}>
          <Image
            style={styles.profilePicture}
            // src={profilePicPlaceholder}
            src={profilePicture}
          />
          {/* <Image
            style={styles.profilePicture}
            // src={profilePicPlaceholder}
            src="https://plus.unsplash.com/premium_photo-1690294614341-cf346ba0a637?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          /> */}
          <Text style={styles.textItem}>
            {safeGet(cvData, "kort oppsummering")}
          </Text>
          <Text style={styles.sectionTitle}>{formatSectionTitle("Kontaktinformasjon")}</Text>
          <Divider />
          {safeGet(cvData, "Personalia.date") && (
            <View style={{ ...styles.groupItems, fontSize: 9 }}>
              {showIcons && <Calendar globalColors={globalColors.primary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text>{safeGet(cvData, "Personalia.date")}</Text>
            </View>
          )}
          {safeGet(cvData, "Personalia.email") && (
            <View style={{ ...styles.groupItems, fontSize: 9 }}>
              {showIcons && <Email globalColors={globalColors.primary} />}
              {showIcons && <View style={{ width: "5px" }}></View>} 
              <Text>{safeGet(cvData, "Personalia.email")}</Text>
            </View>
          )}
          {safeGet(cvData, "Personalia.phonenumber") && (
            <View style={{ ...styles.groupItems, fontSize: 9 }}>
              {showIcons && <Phone globalColors={globalColors.primary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text>{safeGet(cvData, "Personalia.phonenumber")}</Text>
            </View>
          )}
          {safeGet(cvData, "Personalia.address") && (
            <View style={{ ...styles.groupItems, fontSize: 9 }}>
              {showIcons && <Location globalColors={globalColors.primary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text>{safeGet(cvData, "Personalia.address")}</Text>
            </View>
          )}

          {safeGet(cvData, "Ferdigheter", []).length > 0 && (
            <Text style={styles.sectionTitle}>{formatSectionTitle("Ferdigheter")}</Text>
          )}
          {safeGet(cvData, "Ferdigheter", []).length > 0 && <Divider />}
          {safeGet(cvData, "Ferdigheter", []).map((skill, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(skill, "formData.ferdigheter")}
              </Text>
              {/* {renderStars(safeGet(skill, "formData.grad"), styles)} */}
            </View>
          ))}

          {safeGet(cvData, "Interesser", []).length > 0 && (
            <Text style={styles.sectionTitle}>{formatSectionTitle("Interesser")}</Text>
          )}
          {safeGet(cvData, "Interesser", []).length > 0 && <Divider />}
          {safeGet(cvData, "Interesser", []).map((interest, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.interesser")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.navn")}
              </Text>
              {/* {renderStars(safeGet(interest, "formData.grad"), styles)} */}
            </View>
          ))}

          {safeGet(cvData, "Språk", []).length > 0 && (
            <Text style={styles.sectionTitle}>{formatSectionTitle("Språk")}</Text>
          )}
          {safeGet(cvData, "Språk", []).length > 0 && <Divider />}
          {safeGet(cvData, "Språk", []).map((language, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.navn")}
                {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.språk")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* {renderStars(safeGet(language, "formData.grad"), styles)} */}
            </View>
          ))}

          {safeGet(cvData, "Frivillighet", []).length > 0 && (
            <Text
              style={{
                ...styles.sectionTitle,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {formatSectionTitle("Frivillighet")}
            </Text>
          )}
          {safeGet(cvData, "Frivillighet", []).length > 0 && <Divider />}
          {safeGet(cvData, "Frivillighet", []).map((frivillighet, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={{ ...styles.textItem }}>
                {safeGet(frivillighet, "formData.fra dato")} -{" "}
                {safeGet(frivillighet, "formData.til dato", "Nåværende")}{" "}
              </Text>
              <Text style={{ ...styles.bold, ...styles.textItem }}>
                {safeGet(frivillighet, "formData.frivillighet")} -{" "}
                <Text>{safeGet(frivillighet, "formData.sted")}</Text>{" "}
              </Text>
              <Text style={{ ...styles.light, ...styles.textItem }}>
                {safeGet(frivillighet, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          {safeGet(cvData, "Portefølje") && Object.keys(safeGet(cvData, "Portefølje")).length > 0 && (
            <Text
              style={{
                ...styles.sectionTitle,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {formatSectionTitle("Portefølje")}
            </Text>
          )}
          {safeGet(cvData, "Portefølje") && <Divider />}
          <View style={styles.groupItemsSoc}>
            <View>
              {safeGet(cvData, "Portefølje.instagram") && (
                <Text style={styles.link}>
                  <Link
                    style={styles.link}
                    src={safeGet(cvData, "Portefølje.instagram")}
                  >
                    Instagram
                  </Link>
                </Text>
              )}
              {safeGet(cvData, "Portefølje.twitter") && (
                <Text style={styles.link}>
                  <Link
                    style={styles.link}
                    src={safeGet(cvData, "Portefølje.twitter")}
                  >
                    Twitter
                  </Link>
                </Text>
              )}
            </View>

            <View>
              {safeGet(cvData, "Portefølje.github") && (
                <Text style={styles.link}>
                  <Link
                    style={styles.link}
                    src={safeGet(cvData, "Portefølje.github")}
                  >
                    Github
                  </Link>
                </Text>
              )}
              {safeGet(cvData, "Portefølje.linkedin") && (
                <Text style={styles.link}>
                  <Link
                    style={styles.link}
                    src={safeGet(cvData, "Portefølje.linkedin")}
                  >
                    Linkedin
                  </Link>
                </Text>
              )}
            </View>

            <View>
              {safeGet(cvData, "Portefølje.tiktok") && (
                <Text style={styles.link}>
                  <Link
                    style={styles.link}
                    src={safeGet(cvData, "Portefølje.tiktok")}
                  >
                    Tiktok
                  </Link>
                </Text>
              )}{" "}
              {safeGet(cvData, "Portefølje.nettside") && (
                <Text style={styles.link}>
                  <Link
                    style={styles.link}
                    src={safeGet(cvData, "Portefølje.nettside")}
                  >
                    Nettside
                  </Link>
                </Text>
              )}
            </View>
          </View>
        </View>

        {/* Main content */}
        <View style={styles.mainContent}>
          {/* <Canvas style={styles.blob2}></Canvas> */}
          {/* <Canvas style={styles.blob3}></Canvas> */}
          <View style={styles.firstNameSection}>
            {" "}
            <Text
              style={{
                ...styles.firstName,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {`${safeGet(cvData, "Personalia.firstName", "")} ${safeGet(
                cvData,
                "Personalia.lastName",
                ""
              )}`}
            </Text>
            <Text style={{ ...styles.bold, ...styles.text }}>
              {safeGet(cvData, "Personalia.jobtitle")}
            </Text>
          </View>

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvDesign, "primaryColor", "#000000"),
            }}
          >
            {formatSectionTitle("Arbeidserfaring")}
          </Text>
          <Divider />
          {safeGet(cvData, "Arbeidserfaring", []).map((job, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={styles.text}>
                {safeGet(job, "formData.fra dato")} -{" "}
                {safeGet(job, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.bold}>
                {safeGet(job, "formData.stillingstittel")} |{" "}
                {safeGet(job, "formData.arbeidssted")}
              </Text>
              <Text style={styles.light}>
                {safeGet(job, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvDesign, "primaryColor", "#000000"),
            }}
          >
            {formatSectionTitle("Utdanning")}
          </Text>
          <Divider />
          {safeGet(cvData, "Utdanning", []).map((education, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={{ ...styles.text }}>
                {safeGet(education, "formData.fra dato")} -{" "}
                {safeGet(education, "formData.til dato", "Nåværende")}{" "}
              </Text>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(education, "formData.utdanningsnavn")} -{" "}
                <Text>{safeGet(education, "formData.utdanningssted")}</Text>
              </Text>
              <Text style={styles.light}>
                {safeGet(education, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <View wrap={false}>
            {safeGet(cvData, "Militærtjeneste", []).length > 0 && (
              <Text
                style={{
                  ...styles.sectionTitleMain,
                  color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {formatSectionTitle("Militærtjeneste")}
              </Text>
            )}
            {safeGet(cvData, "Militærtjeneste", []).length > 0 && <Divider />}
            {safeGet(cvData, "Militærtjeneste", []).map((military, index) => (
              <View key={index} style={styles.columnGroup}>
                <Text style={{ ...styles.text }}>
                  {safeGet(military, "formData.fra dato")} -{" "}
                  {safeGet(military, "formData.til dato", "Nåværende")}{" "}
                </Text>
                <Text style={{ ...styles.bold, ...styles.text }}>
                  {safeGet(military, "formData.militærtjeneste")} -{" "}
                  <Text>{safeGet(military, "formData.sted")}</Text>{" "}
                </Text>
                <Text style={styles.light}>
                  {safeGet(military, "formData.oppsummering")}
                </Text>
              </View>
            ))}
          </View>
          <View wrap={false}>
            {safeGet(cvData, "Kurs", []).length > 0 && (
              <Text
                style={{
                  ...styles.sectionTitleMain,
                  color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {formatSectionTitle("Kurs")}
              </Text>
            )}
            {safeGet(cvData, "Kurs", []).length > 0 && <Divider />}
            {safeGet(cvData, "Kurs", []).map((kurs, index) => (
              <View key={index} style={styles.columnGroup}>
                <Text style={{ ...styles.text }}>
                  {safeGet(kurs, "formData.fra dato")} -{" "}
                  {safeGet(kurs, "formData.til dato", "Nåværende")}{" "}
                </Text>
                <Text style={{ ...styles.bold, ...styles.text }}>
                  {safeGet(kurs, "formData.kurs")} -{" "}
                  <Text>{safeGet(kurs, "formData.sted")}</Text>{" "}
                </Text>
                <Text style={styles.light}>
                  {safeGet(kurs, "formData.oppsummering")}
                </Text>
              </View>
            ))}
          </View>
          <View wrap={false}>
            {safeGet(cvData, "Referanser", []).length > 0 && (
              <Text
                style={{
                  ...styles.sectionTitleMain,
                  color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {formatSectionTitle("Referanser")}
              </Text>
            )}
            {safeGet(cvData, "Referanser", []).length > 0 && <Divider />}
            {references ? (
            <Text style={styles.text}>Referanser oppgis ved forespørsel</Text>
          ) : (
            safeGet(cvData, "Referanser", []).map((ref, index) => (
              <View key={index} style={styles.columnGroup}>
                <Text
                  style={{
                    ...styles.text,
                    color: safeGet(cvDesign, "#000000"),
                  }}
                >
                  {safeGet(ref, "formData.navn")} -{" "}
                  {safeGet(ref, "formData.stillingstittel")}
                </Text>
                <View style={styles.groupItems}>
                  {showIcons && (
                    <Business globalColors={"#000000"} />
                  )}
                  {showIcons && <View style={{ width: "5px" }}></View>}

                  <Text style={styles.text}>
                    {safeGet(ref, "formData.arbeidssted")}
                  </Text>
                </View>
                {/* <Text style={styles.text}>
                Telefon: {safeGet(ref, "formData.telefon")}
              </Text> */}
                <View style={{ ...styles.groupItems }}>
                  {showIcons && <Phone globalColors={"#000000"} />}
                  {showIcons && <View style={{ width: "5px" }}></View>}
                  <Text style={styles.text}>
                    {safeGet(ref, "formData.telefon")}
                  </Text>
                </View>{" "}
                <View style={{ ...styles.groupItems }}>
                  {showIcons && <Email globalColors={"#000000"} />}
                  {showIcons && <View style={{ width: "5px" }}></View>}
                  <Text style={styles.text}>
                    {safeGet(ref, "formData.epost")}
                  </Text>
                </View>
              </View>
            )))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Stokkholm;
