import axios from "axios";
import { useEffect } from "react";
import { useAuth } from "../components/AuthContext";
    
    const handleSubmission = (formData, path, setErrorMsg, redirect, setIsAuthenticated) => {


        try {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/${path}`, {formData},  { withCredentials: true }).then((result) => {
    
                if(result.status == 201) {
     
                    setIsAuthenticated(true);
                    if(redirect) {
                        window.location.href="/profile"

                    } else{
                        window.location.reload();
                    }
                }
   
            
            }).catch((error) => {

                if(error.status == 400) {
                    setErrorMsg(prevState => ({
                        ...prevState,          // Spread the previous state
                        isErrorMsg: true,      // Update the specific property
                        errorMsg: "Du må kanskje lage deg en ny bruker?" // Update the specific property
                      }));
                }
                if(error.status == 401) {
                    setErrorMsg(prevState => ({
                        ...prevState,          // Spread the previous state
                        isErrorMsg: true,      // Update the specific property
                        errorMsg: "Brukernavn og passord stemmer ikke"  // Update the specific property
                      }));
              
                }
                if(error.status == 500) {
                    setErrorMsg(prevState => ({
                        ...prevState,          // Spread the previous state
                        isErrorMsg: true,      // Update the specific property
                        errorMsg: "Noe skjedde, ta kontakt med kundeservice"  // Update the specific property
                      }));
              
                }
                
            })
        } catch (error) {

        }
   

    }
export default handleSubmission;