import { Link } from "react-router-dom";
import "../css/LandingPage.css";

import { ReactComponent as VippsLogo } from "../img/vipps-logo-badge.svg";

export default function Prices({ isMalChosen }) {
  return (
    <section className="pricing-section">
      <div className="blobBlue"></div>
      <div className="blobBlue2"></div>
      <div className="blobBlue3"></div>
      {/* <div className="blob3"></div> */}
      {/* <div className="blob4"></div> */}
      <div className="pricing-container">
        <h2>Velg din plan</h2>
        <div className="pricing-box-container">
          <div className="pricing-box free">
            <h3>Gratis</h3>
            <h2>Basis</h2>
            <p>Test ut plattformen vår uten å betale.</p>

            <div class="cta-container">
              <Link to="/velgeMal" className="cta-button freeCTA">
                Velg gratis
              </Link>
            </div>

            <ul>
              <li>✓ Tilgang til alle CV-maler</li>
              <li>✓ Lag og redigering av én CV</li>
              <li>✓ Direkte forhåndsvisning av dine dokumenter</li>
              <li>✓ Arbeidet er lagret i 2 dager, også uten konto</li>
              <li>✓ AI-assistert innhold</li>
              <li>✗ Last ned CVene</li>
              <li>✗ Mulighet til å skrive søknader</li>
              <li>✗ Avanserte tilpasninger</li>
              <li>✗ Prioritert support</li>
            </ul>
          </div>
          <div className="pricing-box pro">
            <h3>249 kr/mnd</h3>
            <h2>Pro</h2>
            <p>Test ut med en 7 dagers gratis prøveperiode for 29 kroner.</p>

            <div class="cta-container proCTA">
              <Link to="/velgeMal" className="cta-button">
                Velg pro
              </Link>
            </div>

            <ul>
              <li>✓ Tilgang til alle CV-maler</li>
              <li>✓ Lage ubegrenset antall CV-maler</li>
              <li>✓ Laste ned alle CVer</li>
              <li>✓ Lage søknader</li>
              <li>✓ AI-assistert innholdsskriving</li>
              <li>✓ Mulighet til å skrive søknader</li>
              <li>✓ Bruke Vipps til betaling</li>
              <li>✓ Avanserte tilpasninger</li>
              <li>✓ Prioritert support</li>
              <li>✓ Abonnementet kan avsluttes når som helst</li>
            </ul>

            {/* <Link to="/lagecv" className="cta-button">Velg Pro</Link> */}
          </div>
        </div>
        <div className="subscription-container">
          <div className="vipps-badge-container">
            <VippsLogo />
          </div>
          <p className="subscription-text">
            Du kan avslutte abonnementet når som helst.
          </p>
        </div>
      </div>
    </section>
  );
}
