import { Svg, Path } from "@react-pdf/renderer";

const Calendar = ({ globalColors }) => {
  return (
    <Svg width="10" height="10" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path d="M28.3333 3.33301C27.4167 3.33301 26.6667 4.08301 26.6667 4.99967V6.66634H13.3333V4.99967C13.3333 4.08301 12.5833 3.33301 11.6667 3.33301C10.75 3.33301 10 4.08301 10 4.99967V6.66634H8.33333C6.48333 6.66634 5.01667 8.16634 5.01667 9.99967L5 33.333C5 35.1663 6.48333 36.6663 8.33333 36.6663H31.6667C33.5 36.6663 35 35.1663 35 33.333V9.99967C35 8.16634 33.5 6.66634 31.6667 6.66634H30V4.99967C30 4.08301 29.25 3.33301 28.3333 3.33301ZM31.6667 33.333H8.33333V16.6663H31.6667V33.333ZM18.3333 21.6663C18.3333 20.7497 19.0833 19.9997 20 19.9997C20.9167 19.9997 21.6667 20.7497 21.6667 21.6663C21.6667 22.583 20.9167 23.333 20 23.333C19.0833 23.333 18.3333 22.583 18.3333 21.6663ZM11.6667 21.6663C11.6667 20.7497 12.4167 19.9997 13.3333 19.9997C14.25 19.9997 15 20.7497 15 21.6663C15 22.583 14.25 23.333 13.3333 23.333C12.4167 23.333 11.6667 22.583 11.6667 21.6663ZM25 21.6663C25 20.7497 25.75 19.9997 26.6667 19.9997C27.5833 19.9997 28.3333 20.7497 28.3333 21.6663C28.3333 22.583 27.5833 23.333 26.6667 23.333C25.75 23.333 25 22.583 25 21.6663ZM18.3333 28.333C18.3333 27.4163 19.0833 26.6663 20 26.6663C20.9167 26.6663 21.6667 27.4163 21.6667 28.333C21.6667 29.2497 20.9167 29.9997 20 29.9997C19.0833 29.9997 18.3333 29.2497 18.3333 28.333ZM11.6667 28.333C11.6667 27.4163 12.4167 26.6663 13.3333 26.6663C14.25 26.6663 15 27.4163 15 28.333C15 29.2497 14.25 29.9997 13.3333 29.9997C12.4167 29.9997 11.6667 29.2497 11.6667 28.333ZM25 28.333C25 27.4163 25.75 26.6663 26.6667 26.6663C27.5833 26.6663 28.3333 27.4163 28.3333 28.333C28.3333 29.2497 27.5833 29.9997 26.6667 29.9997C25.75 29.9997 25 29.2497 25 28.333Z" fill={globalColors}/>
    </Svg>
  );
};

export default Calendar;
