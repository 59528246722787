import React from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Link,
  pdf,
} from "@react-pdf/renderer";
import MyDocument from "../pages/templates/CVCreaterTest";
import CTA2 from "./buttons/CTA2";
import axios from "axios";
import Mainla from "../pages/templates/Mainla";
import Toyko from "../pages/templates/Toyko";
import Cali from "../pages/templates/Cali";
import Stokkholm from "../pages/templates/Stokkholm";
import Asu from "../pages/templates/Asu";
import NTNU from "../pages/templates/NTNU";
import Filipina from "../pages/templates/Filipina";
import registerFonts from "../pages/templates/Fonts";
// Import your CVPDF component and other necessary libraries

const CVPDF = ({ cvData }) => {
  // Your existing CVPDF component code remains the same...
};

// Component for rendering the download link
const DownloadablePDF = ({ cvData, cvDesign, selectedTemplate, cvFonts, cvSize, profilePicture, showIcons, uppercaseHeadlines }) => {
  const renderTemplate = () => {
    const commonProps = {
      cvData,
      cvDesign,
      selectedCVMal: selectedTemplate,
      cvFonts,
      cvSize,
      profilePicture,
      showIcons,
      uppercaseHeadlines
    };

    switch (selectedTemplate) {
      case "Toyko":
        return <Toyko {...commonProps} />;
      case "Mainla":
        return <Mainla {...commonProps} />;
      case "Cali":
        return <Cali {...commonProps} />;
      case "Stokkholm":
        return <Stokkholm {...commonProps} />;
      case "Asu":
        return <Asu {...commonProps} />;
      case "NTNU":
        return <NTNU {...commonProps} />;
      case "Filipina":
        return <Filipina {...commonProps} />;
      default:
        return <Toyko {...commonProps} />;
    }
  };

  return (
    <div className="downloadablePDF">
      <PDFDownloadLink
        
        document={renderTemplate()}
        // fileName={`cv.pdf`}
        // fileName={"Filipina-ny2.pdf"}
        fileName={cvData ? `${cvData.Personalia.firstName}${cvData.Personalia.lastName}.pdf` : "cv.pdf"}

      >
        {({ loading, error }) => { console.log(loading, error, "loading, error"); return (loading ? "Laster inn dokument..." : error ? `Error: ${error}` : "Last ned CV")}}
      </PDFDownloadLink>
    </div>
  );
};

export default DownloadablePDF;
