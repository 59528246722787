import Input from "../components/Input";
import { useState, useEffect } from "react";
import TrashIcon from "../components/Trash";
import { ReactComponent as Toggle1 } from '../img/Toggle1.svg';
import { ReactComponent as Toggle2 } from '../img/Toggle2.svg';
import saveData from "./saveData";
import updateCV from "./updateCV";
import axios from "axios";
import { useAuth } from "../components/AuthContext";
import { v4 as uuidv4 } from 'uuid';
import { useLocalStorage } from "../hooks/useLocalStorage";

export default function ReferenceBox({ handleSubmit, name, description, formData, setFormData }) {
    const [serverLastUpdated, setServerLastUpdated] = useState(null);
    const { isAuthenticated } = useAuth();
    const [reference, setReference] = useLocalStorage(name.toLowerCase(), {});
    // Initialize state with localStorage data if it exists
    const [containers, setContainers] = useState(() => {
        try {
            const savedData = localStorage.getItem(name.toLowerCase());
            if (savedData) {
                const parsedData = JSON.parse(savedData);
                return parsedData[name]?.map(container => ({
                    ...container,
                    id: container.id || uuidv4()
                })) || [{ id: uuidv4(), formData }];
            }
        } catch (error) {
            console.error('Error parsing localStorage data:', error);
        }
        return [{ id: uuidv4(), formData }];
    });

    const [showMore, setShowMore] = useState(false);
    const [activateToggle, setActivateToggle] = useState(() => {
        try {
            const savedData = localStorage.getItem(name.toLowerCase());
            if (savedData) {
                const parsedData = JSON.parse(savedData);
                return parsedData.toggleState || false;
            }
        } catch (error) {
            console.error('Error parsing localStorage toggle state:', error);
        }
        return false;
    });

    const showMoreFunc = () => {
        setShowMore(!showMore);
    };

    const activateToggleFunc = () => {
        const newToggleState = !activateToggle;
        setActivateToggle(newToggleState);

        if (newToggleState) {
            setFormData({ "Request": "Referanser oppgis ved forespørsel" });
        }

        // Save toggle state to localStorage
        const localStorageKey = name.toLowerCase();
        const savedData = localStorage.getItem(localStorageKey);
        const dataToStore = savedData ? JSON.parse(savedData) : {};
        dataToStore.toggleState = newToggleState;
        // localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));
        setReference(dataToStore);
    };

    useEffect(() => {
        // fetchLatestCVData();
    }, []);

    useEffect(() => {

        if (isAuthenticated) {

          if(!localStorage.getItem("referanser")) {
          // if(!localStorage.getItem(name.toLowerCase())) {
            
            fetchData();
          }
    
            // Set up interval to check for updates
            const interval = setInterval(() => {
                const localData = JSON.parse(localStorage.getItem(name.toLowerCase()));
                const localLastUpdated = localData?.lastUpdated;

                
                if (localLastUpdated && (!serverLastUpdated || new Date(localLastUpdated) > new Date(serverLastUpdated))) {
                    syncWithServer(localData);
                }
            }, 10000); // Check every 10 seconds
    
            return () => clearInterval(interval);
        }
    }, [isAuthenticated, serverLastUpdated]);
    
    const syncWithServer = async (data) => {

      
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/cv/${name.toLowerCase() === 'språk' ? 'sprak' : name.toLowerCase()}`,
                { ...data, cvId: localStorage.getItem('cvId') },
                { withCredentials: true }
            );
            if(response.status === 200) {
                setServerLastUpdated(response.data.lastUpdated);
            }
        } catch (error) {
            console.error('Error syncing with server:', error);
        }
    };
    
    const fetchData = async () => {
        let token = window.location.search.split('=')[0];
        let idcv = window.location.search.split('=')[1];
        let cvId = localStorage.getItem('cvId');

        //if localstorage and browser has id, and they are the same, fetch latest data from server
        if((cvId && idcv && (cvId === idcv))|| idcv && token === "?idcv" ) {

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieve${name}`, {
                withCredentials: true,
                params: {cvId: idcv}
            }).then((response) => {
    
                const localData = JSON.parse(localStorage.getItem(name.toLowerCase()));
                if (!localData) {
                    setReference(response.data);
                    // localStorage.setItem(name.toLowerCase(), JSON.stringify(response.data));
                }
    
                setServerLastUpdated(response.data.lastUpdated);
            });
        } else if (!idcv && cvId) {

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieve${name}`, {
                withCredentials: true,
                params: {cvId}
            }).then((response) => {
                const localData = JSON.parse(localStorage.getItem(name.toLowerCase()));
                if (!localData) {
                    setReference(response.data);
                    // localStorage.setItem(name.toLowerCase(), JSON.stringify(response.data));
                }
                setServerLastUpdated(response.data.lastUpdated);
            });
        } else if ((idcv && token === "?idcv") && cvId !== idcv) {

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieve${name}`, {
                withCredentials: true,
                params: {cvId: idcv}
            }).then((response) => {
    
                    setReference(response.data);
                    // localStorage.setItem(name.toLowerCase(), JSON.stringify(response.data));
    
                setServerLastUpdated(response.data.lastUpdated);
            });
        }
    }

    const addMore = () => {
        // Create a new container with empty formData fields and UUID
        const newContainer = {
            id: uuidv4(),
            formData: {
                "navn": "",
                "stillingstittel": "",
                "arbeidssted": "",
                "telefon": "",
                "epost": ""
            }
        };
        
        const updatedContainers = [...containers, newContainer];
        setContainers(updatedContainers);

        // Update localStorage
        const localStorageKey = name.toLowerCase();
        const dataToStore = {
            [name]: updatedContainers,
            lastUpdated: new Date().toISOString(),
            toggleState: activateToggle
        };
        // localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));
        setReference(dataToStore);
    };

    const handleInputChange = (key, value, index) => {
        const updatedContainers = containers.map((container, i) =>
            i === index ? {
                ...container,
                formData: { ...container.formData, [key]: value }
            } : container
        );

        // Update localStorage
        const localStorageKey = name.toLowerCase();
        const dataToStore = {
            [name]: updatedContainers,
            lastUpdated: new Date().toISOString(),
            toggleState: activateToggle
        };
        // localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));
        setReference(dataToStore);

        setContainers(updatedContainers);
    };

    return (
        <div className="personaliaBox">
            <div className="personaliaBoxPad">
                <h2>{name}</h2>
                <div className="referenceBox">
                    <div className="toggleReference" onClick={activateToggleFunc}>
                        {activateToggle ? <Toggle2></Toggle2> : <Toggle1></Toggle1>}
                    </div>
                    <p>{description}</p>
                </div>
                <div className="sectionBox">
                    {containers.map((container, index) => (
                        <div className="sectionBoxRow" key={index}>
                            <div className="inputsBox" key={index}>
                                {Object.entries(container.formData).map(([key, value]) => (
                                    <Input
                                        key={key}
                                        placeholder={key}
                                        name={key}
                                        value={value || ''}
                                        onChange={(value) => handleInputChange(key, value, index)}
                                    />
                                ))}
                            </div>
                            <TrashIcon 
                                containers={containers} 
                                setContainers={setContainers} 
                                name={name}
                                index={index}
                            />
                        </div>
                    ))}
                </div>
                <div className="moreButton" onClick={showMoreFunc}>
                    <p onClick={addMore}>Legg til</p>
                </div>
            </div>
        </div>
    );
}

