import { Svg, Path } from "@react-pdf/renderer";

const Phone = ({ globalColors }) => {
  return (
    <Svg width="10" height="10" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M32.0501 25.4333L27.8167 24.9499C26.8001 24.8333 25.8001 25.1833 25.0834 25.8999L22.0167 28.9666C17.3001 26.5666 13.4334 22.7166 11.0334 17.9833L14.1167 14.8999C14.8334 14.1833 15.1834 13.1833 15.0667 12.1666L14.5834 7.9666C14.3834 6.28327 12.9667 5.0166 11.2667 5.0166H8.3834C6.50007 5.0166 4.9334 6.58327 5.05007 8.4666C5.9334 22.6999 17.3167 34.0666 31.5334 34.9499C33.4167 35.0666 34.9834 33.4999 34.9834 31.6166V28.7333C35.0001 27.0499 33.7334 25.6333 32.0501 25.4333Z" fill={globalColors}/>

    </Svg>
  );
};

export default Phone;