import React from "react";
import "./templates.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Canvas,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
import Star from "../../img/Star.js";
import axios from "axios";
import { useEffect, useState } from "react";
import registerFonts from "./Fonts";
import renderStars from "./RenderStars";
import StarIcon from "./StarIcon.js";
import safeGet from "./SafeGet.js";
import Calendar from "../../components/cv/icons/Calendar.js";
import Email from "../../components/cv/icons/Email.js";
import Phone from "../../components/cv/icons/Phone.js";
import Location from "../../components/cv/icons/Location.js";
import Business from "../../components/cv/icons/Business.js";

// import selectedCVMalDeets from "./SelectedMalDeets.js";
import convertImageToImg from "./convertToImage.js";
// import phoneIcon from "../../../public/cvlager/phoneIcon.png";
const NTNU = ({
  cvData,
  cvDesign,
  selectedCVMal,
  cvFonts,
  cvSize,
  profilePicture,
  showIcons = true,
  uppercaseHeadlines = false
}) => {
  registerFonts();
  let globalColors = {};
  const [references, setReferences] = useState(() => {
    try {
      const savedState = localStorage.getItem('referanser');
      if (!savedState) return false;
      const parsedState = JSON.parse(savedState);
      return parsedState?.toggleState ?? false;
    } catch (error) {
      console.error("Error reading references state:", error);
      return false;
    }
  });
  // if (cvDesign) {

  globalColors = {
    primary: cvDesign ? cvDesign.primaryColor : "#27384D",
    secondary: cvDesign ? cvDesign.secondaryColor : "#d6e4fe",
    accent: cvDesign ? cvDesign.tertiaryColor : globalColors.secondary,
    text: "#333333",
    font: "GlacialIndifference" || cvFonts,
    textSizeBody: cvSize || 8,

    // };
  };
  
  // Function to handle section title text transformation
  const formatSectionTitle = (title) => {
    return uppercaseHeadlines ? title.toUpperCase() : title;
  };
  
  // [{"id": 1, "hex": "#FEEED6"}, {"id": 2, "hex": "#d6e4fe"}, {"id": 3, "hex": "#d2d3f7"}, {"id": 4, "hex": "#f2dedc"}, {"id": 5, "hex": "#d4f2dc"}]
  // Create styles for the PDF
  const styles = StyleSheet.create({
    document: {
      width: "595px",
      height: "841px",
      backgroundColor: globalColors.secondary,
    },
    page: {
      display: "flex",
      flexDirection: "row",
      fontSize: globalColors.textSizeBody,
      height: "100%",
      position: "relative",
      backgroundColor: globalColors.secondary,
      paddingRight: 30
    },
    sidebar: {
      width: "30%",
      padding: "0px 15px 15px 15px",
      margin: "20px 0px 20px 20px",
      border: "2px solid " + globalColors.primary,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      color: globalColors.accent,
      display: "flex",
      flexDirection: "column",
      position: "relative",
      zIndex: 4,
      backgroundColor: globalColors.primary,
      overflow: "hidden",
    },
    clipPath: {
      position: "absolute",
      top: 145,
      left: -3,
      width: "187px",
      zIndex: -10,
    },
    profilePicture: {
      display: "flex",
      width: "167px",
      height: "200px",
      objectFit: "cover",
      marginTop: -10,
      // borderRadius: "50%",
      borderColor: globalColors.primary,
      borderWidth: 2,
      marginBottom: 10,
      backgroundColor: globalColors.secondary,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      zIndex: 3,
      // clipPath: "polygon(0% 0%, 100% 0%, 100% 70%, 0% 100%)"
    },
    stars: {
      display: "flex",
      width: "10px",
      height: "10px",
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },
    mainContent: {
      width: "70%",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginTop: "30px",
      position: "relative",
    },
    sectionTitle: {
      fontSize: globalColors.textSizeBody,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 0,
      marginTop: 5,
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 10,
      color: globalColors.accent,
      // textTransform: "uppercase",
    },
    firstNameSection: {
      marginBottom: 40,
      marginTop: 40,
      fontFamily: globalColors.font,
      fontWeight: "bold",
    },
    firstName: {
      fontSize: globalColors.textSizeBody + 30,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      display: "block",
    },
    sectionTitleMain: {
      fontSize: 17 || globalColors.textSizeBody + 4,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 5,
      marginTop: 10,
    },
    starContainer: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 5,
    },
    star: {
      width: 10,
      height: 10,
      marginRight: 3,
    },
    textItem: {
      marginBottom: 3,
      fontSize: globalColors.textSizeBody,
    },
    link: {
      color: globalColors.accent,
      textDecoration: "underline",
      fontSize: globalColors.textSizeBody,
      display: "inline",
    },
    groupItems: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    groupItemsSoc: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      // paddingRight: "30px",
      width: "100%",
    },
    start: {
      justifyContent: "flex-start",
    },
    columnGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginBottom: "10px",
    },
    divider: {
      backgroundColor: globalColors.primary,
      height: 0.7,
      // backgroundColor: globalColors.secondary,
      marginBottom: "10px",
      marginRight: "30px",
      
    },
    divider2: {
      marginLeft: "0px",
      height: 0.7,
      backgroundColor: globalColors.accent,
      marginRight: "70px",
      marginBottom: "10px",
    },
    bold: {
      fontFamily: globalColors.font,
      fontWeight: "bold",
      fontSize: 12,
    },
    text: {
      fontSize: globalColors.textSizeBody || 10,
      marginBottom: 2,
      marginTop: 2,
      fontWeight: "400",
      fontFamily: globalColors.font,
    },
    light: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
      fontWeight: "400",
      fontFamily: globalColors.font,
    },
    blob: {
      backgroundColor: globalColors.secondary,
      width: "100%",
      height: 180,
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 10,
    },
    blob2: {
      backgroundColor: globalColors.primary,
      width: 150,
      height: "100%",
      position: "absolute",
      top: 0,
      left: -150,
      zIndex: 20,
    },
    blob3: {
      backgroundColor: globalColors.accent,
      width: 120,
      height: 120,
      borderRadius: 100,
      objectPositionX: "-10px",
      objectPositionY: "-10px",
      position: "absolute",
      bottom: -30,
      right: -30,
    },
  });

  const Divider = () => <View style={styles.divider} />;
  const Divider2 = () => <View style={styles.divider2} />;

  const [cvMal, setCVMal] = useState(null);
  convertImageToImg();
  useEffect(() => {
    const selectedCVFromLocalStorage = localStorage.getItem("selectedCV");
    if (selectedCVFromLocalStorage) {
      setCVMal(JSON.parse(selectedCVFromLocalStorage));
    }
    // selectedCVMalDeets(selectedCVMal, setCVMal);
  }, []);
  // }, [selectedCVMal]);

  if (!cvData) return null;

  return (
    <Document
      size="A4"
      orientation="portrait"
      className="document"
      style={styles.document}
    >
      <Page style={styles.page}>
        {/* Sidebar for personal details */}

        <Canvas id="magic" style={styles.blob}></Canvas>

        <View style={{ ...styles.sidebar }}>
          <Image
            style={styles.profilePicture}
            // src={profilePicPlaceholder}
            src={profilePicture}
          />
          <Svg height="90" width="100%" style={styles.clipPath}>
            <Path d="M1 485V1L1785 485H1Z" fill={globalColors.primary} />
          </Svg>
          {/* <Image

            style={styles.profilePicture}
            // src={profilePicPlaceholder}
            // src={profilePicture}
            src="https://user-monhelle3-bucket.s3.eu-north-1.amazonaws.com/1730747035396_1700323325885.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIASBGQK6PGNAT25SM6%2F20241104%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Date=20241104T192401Z&X-Amz-Expires=7200&X-Amz-Signature=44032ac990696085d8300f335f5e74affad74764c95c7b5c30329655e1983ea4&X-Amz-SignedHeaders=host&x-id=GetObject"
            // , "https://plus.unsplash.com/premium_photo-1690294614341-cf346ba0a637?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")}
            // src="https://plus.unsplash.com/premium_photo-1690294614341-cf346ba0a637?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          /> */}
          <Text style={{ ...styles.light, fontSize: 9 }}>
            {safeGet(cvData, "kort oppsummering")}
          </Text>
          <Text style={styles.sectionTitle}>{formatSectionTitle("Kontaktinformasjon")}</Text>
          <Divider2 />

                 {safeGet(cvData, "Personalia.date") && (
                   <View style={{ ...styles.groupItems, fontSize: 9 }}>
                    {showIcons && <Calendar globalColors={globalColors.accent}/>}
                    {showIcons && <View style={{width: "5px"}}></View>}
                     <Text>
                       {safeGet(cvData, "Personalia.date")}
                     </Text>
                   </View>
                 )}
                 {safeGet(cvData, "Personalia.email") && (
                   <View style={{ ...styles.groupItems, fontSize: 9 }}>
                     {showIcons && <Email globalColors={globalColors.accent}/>}
                     {showIcons && <View style={{width: "5px"}}></View>}
                     <Text>
                       {safeGet(cvData, "Personalia.email")}
                     </Text>
                   </View>
                 )}
                 {safeGet(cvData, "Personalia.phonenumber") && (
                   <View style={{ ...styles.groupItems, fontSize: 9 }}>
                     {showIcons && <Phone globalColors={globalColors.accent}/>}
                     {showIcons && <View style={{width: "5px"}}></View>}
                     <Text>
                       {safeGet(cvData, "Personalia.phonenumber")}
                     </Text>
                   </View>
                 )}
          {safeGet(cvData, "Personalia.address") && (
            <View style={{ ...styles.groupItems, fontSize: 9 }}>
              {showIcons && <Location globalColors={globalColors.accent}/>}
              {showIcons && <View style={{width: "5px"}}></View>}
              <Text>
                {safeGet(cvData, "Personalia.address")}
              </Text>
            </View>
          )}

      
    

          {safeGet(cvData, "Ferdigheter", []).length > 0 && (
            <Text style={styles.sectionTitle}>{formatSectionTitle("Ferdigheter")}</Text>
          )}
          <Divider2 />
          {safeGet(cvData, "Ferdigheter", []).map((skill, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(skill, "formData.ferdigheter")}
              </Text>
              {/* {renderStars(safeGet(skill, "formData.grad"), styles)} */}
            </View>
          ))}

          {safeGet(cvData, "Interesser", []).length > 0 && (
            <Text style={styles.sectionTitle}>{formatSectionTitle("Interesser")}</Text>
          )}
          {safeGet(cvData, "Interesser", []).length > 0 && (
            <Divider2 />
          )}
          {safeGet(cvData, "Interesser", []).map((interest, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.interesser")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.navn")}
              </Text>
              {/* {renderStars(safeGet(interest, "formData.grad"), styles)} */}
            </View>
          ))}

          {safeGet(cvData, "Språk", []).length > 0 && (
            <Text style={styles.sectionTitle}>{formatSectionTitle("Språk")}</Text>
          )}
          {safeGet(cvData, "Språk", []).length > 0 && (
            <Divider2 />
          )}
          {safeGet(cvData, "Språk", []).map((language, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.navn")}
                {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.språk")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* {renderStars(safeGet(language, "formData.grad"), styles)} */}
            </View>
          ))}
          {safeGet(cvData, "Portefølje") && Object.keys(safeGet(cvData, "Portefølje")).length > 0 && (
            <Text
              style={{
                ...styles.sectionTitle,
                // color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {formatSectionTitle("Portefølje")}
            </Text>
          )}
          {safeGet(cvData, "Portefølje") && Object.keys(safeGet(cvData, "Portefølje")).length > 0 && (
            <Divider2 />
          )}
          <View style={styles.groupItemsSoc}>


            {safeGet(cvData, "Portefølje.instagram") && (
              <Link style={styles.link} src={safeGet(cvData, "Portefølje.instagram")}>
                Instagram
              </Link>
            )}
            {safeGet(cvData, "Portefølje.twitter") && (
              <Link style={styles.link} src={safeGet(cvData, "Portefølje.twitter")}>
                Twitter
              </Link>
            )}

            {safeGet(cvData, "Portefølje.github") && (
              <Link style={styles.link} src={safeGet(cvData, "Portefølje.github")}>
              </Link>
            )}
            {safeGet(cvData, "Portefølje.linkedin") && (
              <Link style={styles.link} src={safeGet(cvData, "Portefølje.linkedin")}>
                Linkedin
              </Link>
            )}
            {safeGet(cvData, "Portefølje.tiktok") && (
                <Link style={styles.link} src={safeGet(cvData, "Portefølje.tiktok")}>
                  Tiktok
                </Link>
              )}
              {safeGet(cvData, "Portefølje.nettside") && (
                <Link style={styles.link} src={safeGet(cvData, "Portefølje.nettside")}>
                  Nettside
                </Link>
              )}

          </View>
          <View wrap={false}>

          {safeGet(cvData, "Frivillighet", []).length > 0 && (
            <Text
              style={{
                ...styles.sectionTitle,
                // color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {formatSectionTitle("Frivillighet")}
            </Text>
          )}
          {safeGet(cvData, "Frivillighet", []).length > 0 && (
            <Divider2 />
          )}
          {safeGet(cvData, "Frivillighet", []).map((frivillighet, index) => (
            <View key={index} style={{ ...styles.columnGroup }} wrap={false}>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(frivillighet, "formData.fra dato")} -{" "}
                {safeGet(frivillighet, "formData.til dato", "Nåværende")}{" "}
              </Text>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(frivillighet, "formData.frivillighet")} -{" "}
                <Text>{safeGet(frivillighet, "formData.sted")}</Text>{" "}
              </Text>
              <Text style={styles.light}>
                {safeGet(frivillighet, "formData.oppsummering")}
              </Text>
            </View>
          ))}
          </View>

          <View wrap={false}>
            {safeGet(cvData, "Militærtjeneste", []).length > 0 && (
              <Text
                style={{
                  ...styles.sectionTitle,
                  // color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {formatSectionTitle("Militærtjeneste")}
              </Text>
            )}
            {safeGet(cvData, "Militærtjeneste", []).length > 0 && (
              <Divider2 />
            )}
            {safeGet(cvData, "Militærtjeneste", []).map((military, index) => (
              <View key={index} style={{ ...styles.columnGroup }} wrap={false}>
                <Text style={{ ...styles.bold, ...styles.text }}>
                  {safeGet(military, "formData.fra dato")} -{" "}
                  {safeGet(military, "formData.til dato", "Nåværende")}{" "}
                </Text>
                <Text style={{ ...styles.bold, ...styles.text }}>
                  {safeGet(military, "formData.militærtjeneste")} -{" "}
                  <Text>{safeGet(military, "formData.sted")}</Text>{" "}
                </Text>
                <Text style={styles.light}>
                  {safeGet(military, "formData.oppsummering")}
                </Text>
              </View>
            ))}
          </View>


        </View>

        {/* Main content */}
        <View style={styles.mainContent}>
          {/* <Canvas style={styles.blob2}></Canvas> */}
          {/* <Canvas style={styles.blob3}></Canvas> */}
          <View style={styles.firstNameSection}>
            {" "}
            <Text
              style={{
                ...styles.firstName,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {uppercaseHeadlines ? 
                `${safeGet(cvData, "Personalia.firstName", "").toUpperCase()}\n${safeGet(
                  cvData,
                  "Personalia.lastName",
                  ""
                ).toUpperCase()}` :
                `${safeGet(cvData, "Personalia.firstName", "")}\n${safeGet(
                  cvData,
                  "Personalia.lastName",
                  ""
                )}`
              }
            </Text>
            <Text
              style={{
                ...styles.bold,
                ...styles.text,
                fontSize: globalColors.textSizeBody + 10,
              }}
            >
              {safeGet(cvData, "Personalia.jobtitle")}
            </Text>
          </View>

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvDesign, "primaryColor", "#000000")
            }}
          >
            {formatSectionTitle("Arbeidserfaring")}
          </Text>
          <Divider />
          {safeGet(cvData, "Arbeidserfaring", []).map((job, index) => (
            <View key={index} style={styles.columnGroup}>
              <View style={styles.groupItemsSoc}>
                <Text style={styles.bold}>
                  {safeGet(job, "formData.stillingstittel")} |{" "}
                  <Text style={{ ...styles.bold, fontSize: 10 }}>
                  {safeGet(job, "formData.arbeidssted")}
                </Text>
                </Text>  
                
                <Text style={styles.text}>
                  {safeGet(job, "formData.fra dato")} -{" "}
                  {safeGet(job, "formData.til dato", "Nåværende")}
                </Text>
              </View>
              <Text style={styles.light}>
                {safeGet(job, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvDesign, "primaryColor", "#000000"),
            }}
          >
            {formatSectionTitle("Utdanning")}
          </Text>
          <Divider />
          {safeGet(cvData, "Utdanning", []).map((education, index) => (
            <View key={index} style={styles.columnGroup}>
              <View style={styles.groupItemsSoc}>
                <Text style={{ ...styles.bold }}>
                  {safeGet(education, "formData.utdanningsnavn")} |{" "}
                  <Text style={{ ...styles.bold, fontSize: 10 }}>
                  {safeGet(education, "formData.utdanningssted")}
                  </Text>
                  </Text>
                

                <Text style={{ ...styles.text }}>
                  {safeGet(education, "formData.fra dato")} -{" "}
                  {safeGet(education, "formData.til dato", "Nåværende")}{" "}
                </Text>
              </View>
              <Text style={styles.light}>
                {safeGet(education, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <View wrap={false}>
            {safeGet(cvData, "Referanser", []).length > 0 && (
              <Text
                style={{
                  ...styles.sectionTitleMain,
                  color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {formatSectionTitle("Referanser")}
              </Text>
            )}
            <Divider />
            {references ? (
            <Text style={styles.text}>Referanser oppgis ved forespørsel</Text>
          ) : (
            <View style={{ ...styles.groupItemsSoc, ...styles.start }}>
              {safeGet(cvData, "Referanser", []).map((ref, index) => (
                <View
                  key={index}
                  style={{ ...styles.columnGroup, paddingRight: "40px" }}
                >
                  <Text
                    style={{
                      ...styles.bold,
                      ...styles.text,
                      // color: safeGet(cvDesign, "primaryColor", "#000000"),
                    }}
                  >
                    {safeGet(ref, "formData.navn")}
                  </Text>
                  <Text style={{ ...styles.bold, fontSize: 10 }}>
                  {safeGet(ref, "formData.stillingstittel")}
                </Text>
                <View style={styles.groupItems}>
                  {showIcons && (
                    <Business globalColors={globalColors.accent} />
                  )}
                  {showIcons && <View style={{ width: "5px" }}></View>}

                  <Text style={styles.text}>
                    {safeGet(ref, "formData.arbeidssted")}
                  </Text>
                </View>
                
                {/* <View style={{width: "5px"}}></View> */}
                  <View style={{ ...styles.groupItems, fontSize: 9 }}>
                    {showIcons ? (
                      <>
                        <Phone globalColors={globalColors.accent} />
                        <View style={{width: "5px"}}></View>
                      </>
                    ) : (
                      <Image src={"/cvIcons/png/phone.png"} style={{ width: 10, height: 10 }} />
                    )}
                    <Text style={styles.text}>
                      {safeGet(ref, "formData.telefon")}
                    </Text>
                  </View>
                  <View style={{ ...styles.groupItems, fontSize: 9 }}>
                    {showIcons ? (
                      <>
                        <Email globalColors={globalColors.accent} />
                        <View style={{width: "5px"}}></View>
                      </>
                    ) : (
                      <Image src={"/cvIcons/png/email.png"} style={{ width: 10, height: 10 }} />
                    )}
                    <Text style={styles.text}>
                      {safeGet(ref, "formData.epost")}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          )}
          </View>
          <View wrap={false}>
            {safeGet(cvData, "Kurs", []).length > 0 && (
              <Text
                style={{
                  ...styles.sectionTitleMain,
                  color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {formatSectionTitle("Kurs")}
              </Text>
            )}
               {safeGet(cvData, "Kurs", []).length > 0 && (
            <Divider />
               )}
            <View style={{ ...styles.groupItemsSoc, ...styles.start }}>
              {safeGet(cvData, "Kurs", []).map((kurs, index) => (
                <View
                  key={index}
                  style={{ ...styles.columnGroup, paddingRight: "40px" }}
                  wrap={false}
                >
                  <Text style={{ ...styles.bold }}>
                    {safeGet(kurs, "formData.fra dato")} -{" "}
                    {safeGet(kurs, "formData.til dato", "Nåværende")}{" "}
                  </Text>
                  <Text style={{ ...styles.text }}>
                    {safeGet(kurs, "formData.kurs")} -{" "}
                    <Text>{safeGet(kurs, "formData.sted")}</Text>{" "}
                  </Text>
                  <Text style={styles.light}>
                    {safeGet(kurs, "formData.oppsummering")}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default NTNU;
