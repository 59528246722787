// Create styles
// Register custom fonts if needed
import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Canvas,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
// import Star from '../img/StarFilled.svg';
// import Star from './Star.js';
import Star from "../../img/Star.js";
import axios from "axios";
import registerFonts from "./Fonts";
import { useEffect, useState } from "react";
import renderStars from "./RenderStars";
import profilePicPlaceholder from "../../img/profilePicPlaceholder.png";
import StarIcon from "./StarIcon.js";
import safeGet from "./SafeGet.js";
import Calendar from "../../components/cv/icons/Calendar.js";
import Email from "../../components/cv/icons/Email.js";
import Phone from "../../components/cv/icons/Phone.js";
import Location from "../../components/cv/icons/Location.js";
import selectedCVMalDeets from "./SelectedMalDeets.js";
import convertImageToImg from "./convertToImage.js";
import Business from "../../components/cv/icons/Business.js";

const Toyko = ({
  cvData,
  cvDesign,
  selectedCVMal,
  cvFonts,
  cvSize,
  profilePicture,
  showIcons = true,
  uppercaseHeadlines = false,
}) => {
  registerFonts();
  convertImageToImg();

  let globalColors = {};
  const [references, setReferences] = useState(() => {
    try {
      const savedState = localStorage.getItem('referanser');
      if (!savedState) return false;
      const parsedState = JSON.parse(savedState);
      return parsedState?.toggleState ?? false;
    } catch (error) {
      console.error("Error reading references state:", error);
      return false;
    }
  });
  // if (cvDesign) {
  globalColors = {
    primary: cvDesign ? cvDesign.primaryColor : "#27384D",
    secondary: "#ffffff",
    accent: cvDesign ? cvDesign.tertiaryColor : globalColors.secondary,
    text: "#333333",
    font: cvFonts || "Inter",
    textSizeBody: cvSize || 10,
  };
  // }

  // Function to handle section title text transformation
  const formatSectionTitle = (title) => {
    return uppercaseHeadlines ? title.toUpperCase() : title;
  };

  // Create styles for the PDF
  const styles = StyleSheet.create({
    document: {
      width: "60%",
      height: "100%",
      minHeight: "800px",
      backgroundColor: globalColors.secondary,
      overflow: "hidden",
    },
    page: {
      display: "flex",
      flexDirection: "row",
      fontSize: globalColors.textSizeBody,
      height: "100%",
      // backgroundColor: globalColors.secondary,
      position: "relative",
    },
    sidebar: {
      width: "40%",
      padding: 15,
      marginLeft: 30,
      backgroundColor: globalColors.primary,
      color: globalColors.secondary,
      display: "flex",
      flexDirection: "column",
      position: "relative",
      zIndex: 2,
    },
    profilePicture: {
      display: "flex",
      width: "120px",
      height: "120px",
      objectFit: "cover",
      borderRadius: "50%",
      borderColor: globalColors.primary,
      borderWidth: 2,
      marginBottom: 10,
      backgroundColor: globalColors.secondary,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      zIndex: 999,
    },
    stars: {
      display: "flex",
      width: "10px",
      height: "10px",
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },
    mainContent: {
      width: "70%",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginTop: "30px",
      position: "relative",
    },
    sectionTitle: {
      fontSize: globalColors.textSizeBody,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 0,
      marginTop: 5,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 10,
      paddingRight: 10,
      color: globalColors.secondary,
      // textTransform: "uppercase",
    },
    firstNameSection: {
      marginBottom: 20,
      marginTop: 20,
      fontSize: globalColors.textSizeBody + 10,
      fontFamily: globalColors.font,
      fontWeight: "bold",
    },
    firstName: {
      fontSize: globalColors.textSizeBody + 20,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      display: "block",
    },
    sectionTitleMain: {
      fontSize: globalColors.textSizeBody + 4,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 10,
      marginTop: 10,
    },
    starContainer: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 5,
    },
    star: {
      width: 10,
      height: 10,
      marginRight: 3,
    },
    textItem: {
      marginBottom: 3,
      fontSize: globalColors.textSizeBody - 2,
    },
    link: {
      color: globalColors.primary,
      textDecoration: "underline",
      fontSize: globalColors.textSizeBody,
    },
    groupItems: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 3,
    },
    groupItemsSoc: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      width: "80%",
    },
    columnGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginBottom: "10px",
    },
    divider: {
      height: 1.7,
      backgroundColor: globalColors.primary,
      marginBottom: "10px",
    },
    divider2: {
      marginLeft: "0px",
      height: 1.7,
      backgroundColor: globalColors.secondary,
      marginBottom: "10px",
    },
    bold: {
      fontFamily: globalColors.font,
      fontWeight: "bold",
    },
    text: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
    },
    light: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
      fontWeight: "normal",
      fontFamily: globalColors.font,
    },
    blob: {
      backgroundColor: globalColors.tertiary,
      width: "110%",
      height: 150,
      position: "absolute",
      top: 40,
      left: -50,
      zIndex: 6,
    },
    blob2: {
      backgroundColor: globalColors.primary,
      width: 150,
      height: "100%",
      position: "absolute",
      top: 0,
      left: -150,
      zIndex: 20,
    },
    blob3: {
      backgroundColor: globalColors.accent,
      width: 120,
      height: 120,
      borderRadius: 100,
      objectPositionX: "-10px",
      objectPositionY: "-10px",
      position: "absolute",
      bottom: -30,
      right: -30,
    },
  });

  const Divider = () => <View style={styles.divider} />;
  const Divider2 = () => <View style={styles.divider2} />;

  const [cvMal, setCVMal] = useState(null);

  useEffect(() => {
    selectedCVMalDeets(selectedCVMal, setCVMal);
  }, [selectedCVMal]);

  if (!cvData) return null;

  return (
    <Document
      size="A4"
      orientation="portrait"
      className="document"
      style={styles.document}
    >
      <Page style={styles.page}>
        {/* Sidebar for personal details */}
        <Canvas id="magic" style={styles.blob}></Canvas>
        <View style={{ ...styles.sidebar }}>
          {/* Problem here */}
          <Image
            style={styles.profilePicture}
            // src={profilePicPlaceholder}
            src={profilePicture}
          />
          <Text style={styles.light}>
            {safeGet(cvData, "kort oppsummering")}
          </Text>
          <Text style={styles.sectionTitle}>
            {formatSectionTitle("Kontaktinformasjon")}
          </Text>
          <Divider2 />

          {safeGet(cvData, "Personalia.date") && (
            <View style={styles.groupItems}>
              {showIcons && <Calendar globalColors={globalColors.secondary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text style={styles.textItem}>
                Født: {safeGet(cvData, "Personalia.date")}
              </Text>
            </View>
          )}
          {safeGet(cvData, "Personalia.email") && (
            <View style={styles.groupItems}>
              {showIcons && <Email globalColors={globalColors.secondary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text style={styles.textItem}>
                {safeGet(cvData, "Personalia.email")}
              </Text>
            </View>
          )}
          {safeGet(cvData, "Personalia.phonenumber") && (
            <View style={styles.groupItems}>
              {showIcons && <Phone globalColors={globalColors.secondary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text style={styles.textItem}>
                {safeGet(cvData, "Personalia.phonenumber")}
              </Text>
            </View>
          )}
          {safeGet(cvData, "Personalia.address") && (
            <View style={styles.groupItems}>
              {showIcons && <Location globalColors={globalColors.secondary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text style={styles.textItem}>
                {safeGet(cvData, "Personalia.address")}
              </Text>
            </View>
          )}

          <Text style={styles.sectionTitle}>
            {formatSectionTitle("Ferdigheter")}
          </Text>
          <Divider2 />
          {safeGet(cvData, "Ferdigheter", []).map((skill, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(skill, "formData.ferdigheter")}
              </Text>

              {/* {renderStars(safeGet(skill, "formData.grad"), styles)} */}
            </View>
          ))}

          {safeGet(cvData, "Interesser", []).length > 0 && (
            <Text
              style={{
                ...styles.sectionTitle,
                // color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {formatSectionTitle("Interesser")}
            </Text>
          )}
          {safeGet(cvData, "Interesser", []).length > 0 && <Divider2 />}
          {safeGet(cvData, "Interesser", []).map((interest, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.interesser")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.navn")}
                {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* {renderStars(safeGet(skill, "formData.grad"), styles)} */}
            </View>
          ))}

          {safeGet(cvData, "Språk", []).length > 0 && (
            <Text style={styles.sectionTitle}>
              {formatSectionTitle("Språk")}
            </Text>
          )}
          {safeGet(cvData, "Språk", []).length > 0 && <Divider2 />}
          {safeGet(cvData, "Språk", []).map((language, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.navn")}
                {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.språk")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* {renderStars(safeGet(language, "formData.grad"), styles)} */}
            </View>
          ))}
          {safeGet(cvData, "Kurs", []).length > 0 && (
            <Text
              style={{
                ...styles.sectionTitle,
                // color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {formatSectionTitle("Kurs")}
            </Text>
          )}
          {safeGet(cvData, "Kurs", []).length > 0 && <Divider2 />}
          {safeGet(cvData, "Kurs", []).map((course, index) => (
            <View key={index}>
              <Text style={styles.textItem}>
                {safeGet(course, "formData.kurs")}
                {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(course, "formData.sted")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* <Text style={styles.text}>
              </Text>    */}
              <Text style={styles.textItem}>
                {safeGet(course, "formData.fra dato")} -{" "}
                {safeGet(course, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(course, "formData.oppsummering")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* {renderStars(safeGet(language, "formData.grad"), styles)} */}
            </View>
          ))}

          <View wrap={false}>
            {safeGet(cvData, "Frivillighet", []).length > 0 && (
              <Text
                style={{
                  ...styles.sectionTitle,
                  // color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {formatSectionTitle("Frivillighet")}
              </Text>
            )}
            {safeGet(cvData, "Frivillighet", []).length > 0 && <Divider2 />}
            {safeGet(cvData, "Frivillighet", []).map((volunteer, index) => (
              <View key={index}>
                <Text style={styles.textItem}>
                  {safeGet(volunteer, "formData.frivillighet")}
                  {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
                </Text>
                <Text style={styles.textItem}>
                  {safeGet(volunteer, "formData.sted")}
                  {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
                </Text>
                {/* <Text style={styles.text}>
              </Text>    */}
                <Text style={styles.textItem}>
                  {safeGet(volunteer, "formData.fra dato")} -{" "}
                  {safeGet(volunteer, "formData.til dato", "Nåværende")}
                </Text>
                <Text style={styles.textItem}>
                  {safeGet(volunteer, "formData.oppsummering")}
                  {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
                </Text>
                {/* {renderStars(safeGet(language, "formData.grad"), styles)} */}
              </View>
            ))}
          </View>
          <View wrap={false}>
            {safeGet(cvData, "Militærtjeneste", []).length > 0 && (
              <Text style={styles.sectionTitle}>
                {formatSectionTitle("Militærtjeneste")}
              </Text>
            )}
            {safeGet(cvData, "Militærtjeneste", []).length > 0 && <Divider2 />}
            {safeGet(cvData, "Militærtjeneste", []).map((military, index) => (
              <View key={index}>
                <Text style={styles.textItem}>
                  {safeGet(military, "formData.militærtjeneste")}
                  {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
                </Text>
                <Text style={styles.textItem}>
                  {safeGet(military, "formData.sted")}
                  {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
                </Text>
                {/* <Text style={styles.text}>
              </Text>    */}
                <Text style={styles.textItem}>
                  {safeGet(military, "formData.fra dato")} -{" "}
                  {safeGet(military, "formData.til dato", "Nåværende")}
                </Text>
                <Text style={styles.textItem}>
                  {safeGet(military, "formData.oppsummering")}
                  {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
                </Text>
                {/* {renderStars(safeGet(language, "formData.grad"), styles)} */}
              </View>
            ))}
          </View>
        </View>

        {/* Main content */}
        <View style={styles.mainContent}>
          {/* <Canvas style={styles.blob2}></Canvas> */}
          {/* <Canvas style={styles.blob3}></Canvas> */}
          <View style={styles.firstNameSection}>
            {" "}
            <Text
              style={{
                ...styles.firstName,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {uppercaseHeadlines
                ? `${safeGet(
                    cvData,
                    "Personalia.firstName",
                    ""
                  ).toUpperCase()} ${safeGet(
                    cvData,
                    "Personalia.lastName",
                    ""
                  ).toUpperCase()}`
                : `${safeGet(cvData, "Personalia.firstName", "")} ${safeGet(
                    cvData,
                    "Personalia.lastName",
                    ""
                  )}`}
            </Text>
            <Text style={{ ...styles.bold, ...styles.text }}>
              {safeGet(cvData, "Personalia.jobtitle")}
            </Text>
          </View>

          {safeGet(cvData, "Arbeidserfaring", []).length > 0 && (
            <Text
              style={{
                ...styles.sectionTitleMain,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {formatSectionTitle("Arbeidserfaring")}
            </Text>
          )}
          <Divider />
          {safeGet(cvData, "Arbeidserfaring", []).map((job, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={styles.text}>
                {safeGet(job, "formData.fra dato")} -{" "}
                {safeGet(job, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.bold}>
                {safeGet(job, "formData.stillingstittel")} |{" "}
                {safeGet(job, "formData.arbeidssted")}
              </Text>
              <Text style={styles.light}>
                {safeGet(job, "formData.oppsummering")}
              </Text>
            </View>
          ))}
          {safeGet(cvData, "Utdanning", []).length > 0 && (
            <Text
              style={{
                ...styles.sectionTitleMain,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {formatSectionTitle("Utdanning")}
            </Text>
          )}
          <Divider />
          {safeGet(cvData, "Utdanning", []).map((education, index) => (
            <View key={index} style={styles.columnGroup} wrap={false}>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(education, "formData.utdanningsnavn")} -{" "}
                <Text>{safeGet(education, "formData.utdanningssted")}</Text>
              </Text>
              <Text style={styles.text}>
                {safeGet(education, "formData.fra dato")} -{" "}
                {safeGet(education, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.light}>
                {safeGet(education, "formData.oppsummering")}
              </Text>
            </View>
          ))}
          <View wrap={false}>
            {safeGet(cvData, "Referanser", []).length > 0 && (
              <Text
                style={{
                  ...styles.sectionTitleMain,
                  color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {formatSectionTitle("Referanser")}
              </Text>
            )}
            {safeGet(cvData, "Referanser", []).length > 0 && <Divider />}
            {references ? (
              <Text style={styles.text}>Referanser oppgis ved forespørsel</Text>
            ) : (
              safeGet(cvData, "Referanser", []).map((ref, index) => (
                <View key={index} style={styles.columnGroup}>
                  <Text
                    style={{
                      ...styles.bold,
                      ...styles.text,
                      color: safeGet(cvDesign, "primaryColor", "#000000"),
                    }}
                  >
                    {safeGet(ref, "formData.navn")} -{" "}
                    {safeGet(ref, "formData.stillingstittel")}
                  </Text>
                  <View style={styles.groupItems}>
                  {showIcons && (
                    <Business globalColors={globalColors.primary} />
                  )}
                  {showIcons && <View style={{ width: "5px" }}></View>}

                  <Text style={styles.text}>
                    {safeGet(ref, "formData.arbeidssted")}
                  </Text>
                </View>
                  <View style={styles.groupItems}>
                    {showIcons && <Phone globalColors={globalColors.primary} />}
                    {showIcons && <View style={{ width: "5px" }}></View>}
                    <Text style={styles.text}>
                      {safeGet(ref, "formData.telefon")}
                    </Text>
                  </View>
                  <View style={styles.groupItems}>
                    {showIcons && <Email globalColors={globalColors.primary} />}
                    {showIcons && <View style={{ width: "5px" }}></View>}
                    <Text style={styles.text}>
                      {safeGet(ref, "formData.epost")}
                    </Text>
                  </View>
                </View>
              ))
            )}
          </View>

          {safeGet(cvData, "Portefølje") && Object.keys(safeGet(cvData, "Portefølje")).length > 0 && (
            <Text
              style={{
                ...styles.sectionTitleMain,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {formatSectionTitle("Portefølje")}
            </Text>
          )}
                    {safeGet(cvData, "Portefølje") && Object.keys(safeGet(cvData, "Portefølje")).length > 0 && (

          <Divider />)}
          <View style={styles.groupItemsSoc}>
            {safeGet(cvData, "Portefølje.instagram") && (
              <Text style={styles.link}>
                <Link
                  style={styles.link}
                  src={safeGet(cvData, "Portefølje.instagram")}
                >
                  Instagram
                </Link>
              </Text>
            )}
            {safeGet(cvData, "Portefølje.twitter") && (
              <Text style={styles.link}>
                <Link
                  style={styles.link}
                  src={safeGet(cvData, "Portefølje.twitter")}
                >
                  Twitter
                </Link>
              </Text>
            )}
            {safeGet(cvData, "Portefølje.github") && (
              <Text style={styles.link}>
                <Link
                  style={styles.link}
                  src={safeGet(cvData, "Portefølje.github")}
                >
                  Github
                </Link>
              </Text>
            )}
            {safeGet(cvData, "Portefølje.linkedin") && (
              <Text style={styles.link}>
                <Link
                  style={styles.link}
                  src={safeGet(cvData, "Portefølje.linkedin")}
                >
                  Linkedin
                </Link>
              </Text>
            )}{" "}
            {safeGet(cvData, "Portefølje.tiktok") && (
              <Text style={styles.link}>
                <Link
                  style={styles.link}
                  src={safeGet(cvData, "Portefølje.tiktok")}
                >
                  Tiktok
                </Link>
              </Text>
            )}
            {safeGet(cvData, "Portefølje.nettside") && (
              <Text style={styles.link}>
                <Link
                  style={styles.link}
                  src={safeGet(cvData, "Portefølje.nettside")}
                >
                  Nettside
                </Link>
              </Text>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Toyko;
