import Input from "../components/Input";
import { useState, useEffect } from "react";
import axios from "axios";
import saveData from "./saveData";
import updateCV from "./updateCV";
import pdfIcon from '../img/pdf-icon.png';
import { useAuth } from "../components/AuthContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
export default function PortfolioBox({handleSubmit}) {

    const { user, isAuthenticated } = useAuth();
    const [serverLastUpdated, setServerLastUpdated] = useState(null);
    const [portfolio, setPortfolio] = useLocalStorage('portefølje', {});
    const [formData, setFormData] = useState(() => {
        const savedData = localStorage.getItem('portefølje');
        return savedData ? JSON.parse(savedData) : {
            "github": '',
            "linkedin": '',
            "twitter": '',
            "instagram": '',
            "tiktok": '',
            "nettside": '',
            "portfolioUrl": '',
        };
    });

    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        // fetchLatestCVData();
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            if(!localStorage.getItem('portefølje')) {
                fetchPortfolio();
            }

            // Set up interval to check for updates
            const interval = setInterval(() => {
                const localData = JSON.parse(localStorage.getItem('portefølje'));
                const localLastUpdated = localData?.lastUpdated;
                
                if (localLastUpdated && (!serverLastUpdated || new Date(localLastUpdated) > new Date(serverLastUpdated))) {
                    syncWithServer(localData);
                }
            }, 10000); // Check every 10 seconds

            return () => clearInterval(interval);
        }
    }, [isAuthenticated, serverLastUpdated]);

    const syncWithServer = async (data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/cv/portfolio`,
                { ...data, cvId: localStorage.getItem('cvId') },
                { withCredentials: true }
            );
            if(response.status === 200) {
                setServerLastUpdated(response.data.lastUpdated);
            }
        } catch (error) {
            console.error('Error syncing with server:', error);
        }
    };

    const fetchPortfolio = async () => {
        let token = window.location.search.split('=')[0];
        let idcv = window.location.search.split('=')[1];
        let cvId = localStorage.getItem('cvId');

        //if localstorage and browser has id, and they are the same, fetch latest data from server
        if((cvId && idcv && (cvId === idcv))|| idcv && token === "?idcv" ) {

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrievePortfolio`, {
                withCredentials: true,
                params: {cvId: idcv}
            }).then((response) => {

                const localData = JSON.parse(localStorage.getItem('portefølje'));
                if (!localData) {
                    // setStorageValue('portefølje', response.data);
                    // localStorage.setItem('portefølje', JSON.stringify(response.data));
                    setPortfolio(response.data);
                }

                setServerLastUpdated(response.data.lastUpdated);
            });
        } else if (!idcv && cvId) {

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrievePortfolio`, {
                withCredentials: true,
                params: {cvId}
            }).then((response) => {
                const localData = JSON.parse(localStorage.getItem('portefølje'));
                if (!localData) {
                    // localStorage.setItem('portefølje', JSON.stringify(response.data));
                    setPortfolio(response.data);
                }
                setServerLastUpdated(response.data.lastUpdated);
            });
        } else if ((idcv && token === "?idcv") && cvId !== idcv) {

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrievePortfolio`, {
                withCredentials: true,
                params: {cvId: idcv}
            }).then((response) => {

                // localStorage.setItem('portefølje', JSON.stringify(response.data));
                setPortfolio(response.data);
                setServerLastUpdated(response.data.lastUpdated);
            });
        }
    }

    const handleLocalInputChange = async (field, value) => {
        const lastUpdated = new Date().toISOString();
        const newData = { ...formData, [field]: value, lastUpdated };
        // localStorage.setItem('portefølje', JSON.stringify(newData));
        setPortfolio(newData);
        setFormData(newData);
    };

    const handleFileUpload = (e) => {
        let file = e.target.files[0];
        const fileData = new FormData();
        fileData.append('portfolio', file);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload/uploadPortfolio`, fileData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }, 
            withCredentials: true
        })
        .then(response => {
            // Handle successful upload
        })
        .catch(error => {
            console.error('Error uploading file:', error);
        });
    };

    return (
        <div className="personaliaBox">
            <div className="personaliaBoxPad">
                <h2>Portefølje</h2>
                <p>Her fyller du ut lenker til dine sosiale lenker og portføljer</p>
                <div className="inputs">
                    <Input 
                        placeholder="github" 
                        value={formData.github} 
                        onChange={(value) => handleLocalInputChange("github", value)} 
                    />
                    <Input 
                        placeholder="linkedin" 
                        value={formData.linkedin} 
                        onChange={(value) => handleLocalInputChange("linkedin", value)} 
                    />
                    <Input 
                        placeholder="twitter" 
                        value={formData.twitter} 
                        onChange={(value) => handleLocalInputChange("twitter", value)} 
                    />
                    <Input 
                        placeholder="instagram" 
                        value={formData.instagram} 
                        onChange={(value) => handleLocalInputChange("instagram", value)} 
                    />
                    <Input 
                        placeholder="tiktok" 
                        value={formData.tiktok} 
                        onChange={(value) => handleLocalInputChange("tiktok", value)} 
                    />
                    <Input 
                        placeholder="nettside" 
                        value={formData.nettside} 
                        onChange={(value) => handleLocalInputChange("nettside", value)} 
                    />
                </div>

                {formData.portfolioUrl ? 
                    <div>
                        <img src={pdfIcon} alt="PDF Icon" width="40px" />
                        <span onClick={() => {
                            const newData = { ...formData, portfolioUrl: '' };
                            // localStorage.setItem('portefølje', JSON.stringify(newData));
                            setPortfolio(newData);
                            setFormData(newData);
                        }}>x</span>
                    </div> 
                    : 
                    <input 
                        type="file" 
                        onChange={(e) => {
                            handleFileUpload(e);
                            const newData = { ...formData, portfolioUrl: e.target.files[0].name };
                            // localStorage.setItem('portefølje', JSON.stringify(newData));
                            setPortfolio(newData);
                            setFormData(newData);
                        }} 
                        name={formData.portfolioUrl || "Last opp digital portefølje"} 
                    />
                }
            </div>
        </div>
    );
}