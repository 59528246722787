import Logo from "./Logo";
import CTA from "./buttons/CTA";
import CTA3 from "./buttons/CTA3";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useState, useEffect } from "react";

export default function NavbarLandingPage() {
  const { isAuthenticated } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };



  return (
    <div className="nav-wrapper2">
      <nav className="navbar">
        <ul>
          <div className="nav.logo-container">
            <Link to="/">
              <Logo></Logo>
            </Link>
          </div>

          <div className="navbar-links">
            <div>
              <Link to="/templates">CV-maler</Link>
            </div>
            <div>
              <Link to="/faq">FAQ</Link>
            </div>
            <div>
              <Link to="/contact">Kontakt oss</Link>
            </div>
          </div>
          <div className="navbar-cta">
          {!isAuthenticated && (
              <div>
                <Link to="/login">Logg inn</Link>
              </div>
            )}
            <li>
              <CTA3
                name="Lag CV Nå"
                isMalChosen={false}
                path="/velgemal"
              ></CTA3>
            </li>
            {isAuthenticated ? (
              <li>
                <Link to="/application">Lag søknad</Link>
              </li>
            ) : null}
          </div>
        </ul>
      </nav>

      <nav className="navbar-mobile">
        <div className="mobile-header">
          <Link to="/">
            <Logo></Logo>
          </Link>
          <div
            className={`hamburger ${isMenuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
          <Link to="/templates">CV-maler</Link>
          <Link to="/faq">FAQ</Link>
          <Link to="/contact">Kontakt oss</Link>
          {isAuthenticated ? <Link to="/application">Lag søknad</Link> : null}
          <div className="mobile-cta">
            <CTA3 name="Lag CV Nå" isMalChosen={false} path="/velgemal"></CTA3>
          </div>
        </div>
      </nav>
    </div>
  );
}
