import Input from "../components/Input";
import InputWithToggle from "../components/InputToggle";
import TrashIcon from "../components/Trash";
import { useEffect, useState } from "react";
import saveData from "./saveData";
import updateCV from "./updateCV";
import axios from "axios";
import EducationEditor from "./Editors/EducationEditor";
import { useAuth } from "../components/AuthContext";
import {v4 as uuidv4} from "uuid";
import { useLocalStorage } from '../hooks/useLocalStorage';


export default function EducationBox({
  handleSubmit,
  name,
  description,
  formData,
  setFormdata,
}) {
  const [showMore, setShowMore] = useState(false);

  const [serverLastUpdated, setServerLastUpdated] = useState(null);
  const { isAuthenticated } = useAuth();
  const [activateToggle, setActivateToggle] = useState(false);
  const [content, setContent] = useState({ oppsummering: "" });
  const [storedData, setStoredData] = useLocalStorage(name.toLowerCase(), {});

  const [containers, setContainers] = useState(() => {
    try {
      const savedData = localStorage.getItem(name.toLowerCase());
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        return parsedData[name]?.map(container => ({
          ...container,
          id: container.id || uuidv4()
        })) || [{ id: uuidv4(), formData }];
      }
    } catch (error) {
      console.error('Error parsing localStorage data:', error);
    }
    return [{ id: uuidv4(), formData }];
  });
  const [activateToggles, setActivateToggles] = useState(() => {
    const toggleStates = {};
    containers.forEach((container, index) => {
      if (container.formData && container.formData["til dato"] === "Nåværende jobb") {
        toggleStates[index] = true;
      }
    });
    return toggleStates;
  });

  const showMoreFunc = () => {
    setShowMore(!showMore);
  };
  // const activateToggleFunc = () => {

  //     setActivateToggle(!activateToggle);

  //     if(activateToggle) {

  //     }
  // }

  useEffect(() => {
    // fetchLatestCVData();
  }, []);
  useEffect(() => {

    if (isAuthenticated) {

      if(!localStorage.getItem("arbeidserfaring")) {
      // if(!localStorage.getItem(name.toLowerCase())) {
        
        fetchData();
      }

        // Set up interval to check for updates
        const interval = setInterval(() => {
            const localData = JSON.parse(localStorage.getItem(name.toLowerCase()));
            const localLastUpdated = localData?.lastUpdated;
            
            if (localLastUpdated && (!serverLastUpdated || new Date(localLastUpdated) > new Date(serverLastUpdated))) {
                syncWithServer(localData);
            }
        }, 10000); // Check every 10 seconds

        return () => clearInterval(interval);
    }
}, [isAuthenticated, serverLastUpdated]);

const syncWithServer = async (data) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/cv/${name.toLowerCase() === 'militærtjeneste' ? 'military' : name.toLowerCase()}`,
            { ...data, cvId: localStorage.getItem('cvId') },
            { withCredentials: true }
        );
        if(response.status === 200) {
            setServerLastUpdated(response.data.lastUpdated);
        }
    } catch (error) {
        console.error('Error syncing with server:', error);
    }
};

const fetchData = async () => {
    let token = window.location.search.split('=')[0];
    let idcv = window.location.search.split('=')[1];
    let cvId = localStorage.getItem('cvId');

    //if localstorage and browser has id, and they are the same, fetch latest data from server
    if((cvId && idcv && (cvId === idcv))|| idcv && token === "?idcv" ) {

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieve${name.toLowerCase() === 'militærtjeneste' ? 'military' : name.toLowerCase()}`, {
            withCredentials: true,
            params: {cvId: idcv}
        }).then((response) => {

            const localData = JSON.parse(localStorage.getItem(name.toLowerCase()));
            if (!localData) {
              setStoredData(response.data); 
                // localStorage.setItem(name.toLowerCase(), JSON.stringify(response.data));
            }

            setServerLastUpdated(response.data.lastUpdated);
        });
    } else if (!idcv && cvId) {

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieve${name}`, {
            withCredentials: true,
            params: {cvId}
        }).then((response) => {
            const localData = JSON.parse(localStorage.getItem(name.toLowerCase()));
            if (!localData) {
              setStoredData(response.data); 
                //localStorage.setItem(name.toLowerCase(), JSON.stringify(response.data));
            }
            setServerLastUpdated(response.data.lastUpdated);
        });
    } else if ((idcv && token === "?idcv") && cvId !== idcv) {

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieve${name}`, {
            withCredentials: true,
            params: {cvId: idcv}
        }).then((response) => {
          setStoredData(response.data); 
                // setStorageValue(name.toLowerCase(), response.data);
                //  localStorage.setItem(name.toLowerCase(), JSON.stringify(response.data));

            setServerLastUpdated(response.data.lastUpdated);
        });
    }
}



  const fetchLatestCVData = async () => {
    let token = window.location.search.split("=")[0];
    let idcv = window.location.search.split("=")[1];

    if (idcv && token === "?idcv") {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/fetch-cv-id`,
          { idcv: idcv },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.data[0] && response.data[0].cvContent[name]) {
            const fetchedData = response.data[0].cvContent[name];
            if (Array.isArray(fetchedData)) {
              setContainers(fetchedData);
              // Set toggle states based on "til dato" values
              const newToggleStates = {};
              fetchedData.forEach((item, index) => {
                if (
                  item.formData &&
                  item.formData["til dato"] === "Nåværende jobb"
                ) {
                  newToggleStates[index] = true;
                }
              });
              setActivateToggles(newToggleStates);
            } else if (typeof fetchedData === "object") {
              setContainers([{ formData: fetchedData }]);
              // Set toggle state for single object
              if (fetchedData["til dato"] === "Nåværende jobb") {
                setActivateToggles({ 0: true });
              }
            }
            // Update content if 'oppsummering' exists in the fetched data
            if (fetchedData.oppsummering) {
              setContent((prevContent) => ({
                ...prevContent,
                oppsummering: fetchedData.oppsummering,
              }));
            }
          }
        });
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/fetch-cv`,
          {},
          {
            withCredentials: true,
          }
        );
        if (response.data && response.data.cvContent[name]) {
          const fetchedData = response.data.cvContent[name];
          if (Array.isArray(fetchedData)) {
            setContainers(fetchedData);
            // Set toggle states based on "til dato" values
            const newToggleStates = {};
            fetchedData.forEach((item, index) => {
              if (
                item.formData &&
                item.formData["til dato"] === "Nåværende jobb"
              ) {
                newToggleStates[index] = true;
              }
            });
            setActivateToggles(newToggleStates);
          } else if (typeof fetchedData === "object") {
            setContainers([{ formData: fetchedData }]);
            // Set toggle state for single object
            if (fetchedData["til dato"] === "Nåværende jobb") {
              setActivateToggles({ 0: true });
            }
          }
          // Update content if 'oppsummering' exists in the fetched data
          if (fetchedData.oppsummering) {
            setContent((prevContent) => ({
              ...prevContent,
              oppsummering: fetchedData.oppsummering,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching CV data:", error);
      }
    }
  };

  const handleInputChange = (key, value, index) => {
    setContent((prevContent) => ({
      ...prevContent,
      [key]: value,
    }));
    
    const updatedContainers = containers.map((container, i) =>
      i === index ? {
        ...container,
        formData: { ...container.formData, [key]: value }
      } : container
    );
    
    const lastUpdated = new Date().toISOString();
    const localStorageKey = `${name.toLowerCase()}`;
    const dataToStore = {
      [name]: updatedContainers,
      lastUpdated: lastUpdated,
    };
    setStoredData(dataToStore); 
    // setStorageValue(localStorageKey, dataToStore);
    // localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));
    setContainers(updatedContainers);

    if (key === "til dato") {
      setActivateToggles((prev) => ({
        ...prev,
        [index]: value === "Nåværende jobb",
      }));
    }
  };

  const updateFormData = (value, index) => {
    const updatedContainers = containers.map((container, i) =>
      i === index
        ? {
            ...container,
            formData: { ...container.formData, oppsummering: value },
          }
        : container
    );


    const localStorageKey = `${name.toLowerCase()}`;
        const dataToStore = JSON.parse(localStorage.getItem(localStorageKey));
    if (dataToStore) {
      dataToStore[name][index].formData.oppsummering = value;
      setStoredData(dataToStore); 
      // setStorageValue(localStorageKey, dataToStore);
      // localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));
    }

    setContainers(updatedContainers);
  };

  const addMore = () => {
    // Create a new container with empty formData fields and a UUID
    const newContainer = {
      id: uuidv4(),
      formData: {
        ...(() => {
          switch (name) {
            case "Arbeidserfaring":
              return {
                "stillingstittel": "",
                "arbeidssted": "",
                "fra dato": "",
                "til dato": "",
                "oppsummering": ""
              };
              case "Kurs":
                return {
                  "kurs": "",
                  "sted": "",
                  "fra dato": "",
                  "til dato": "",
                  "oppsummering": ""
                };
                case "Militærtjeneste":
                  return {
                    "militærtjeneste": "",
                    "sted": "",
                    "fra dato": "",
                    "til dato": "",
                    "oppsummering": ""
                  };
            default:
              return {
                "utdanningsnavn": "",
                "utdanningssted": "",
                "fra dato": "",
                "til dato": "",
                "oppsummering": ""
              };
          }
        })()
      }
    };
    
    const updatedContainers = [...containers, newContainer];
    setContainers(updatedContainers);

    // Update localStorage with the new container
    const localStorageKey = `${name.toLowerCase()}`;
    const dataToStore = {
      [name]: updatedContainers,
      lastUpdated: new Date().toISOString(),
    };
    setStoredData(dataToStore); 
    // setStorageValue(localStorageKey, dataToStore);
    // localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));
  };

  const handleMouseLeave = () => {
    let idcv = window.location.search.split("=")[1];
    if (idcv) {
      updateCV({ [name]: containers }, idcv);
    } else {
      saveData({ [name]: containers });
      // saveData({ [name]: containers[0].formData })
    }
  };

  

  return (
    <div className="personaliaBox">
    {/* <div className="personaliaBox" onMouseLeave={handleMouseLeave}> */}
      <div className="personaliaBoxPad">
        <h2>{name}</h2>
        <p>{description}</p>
        <div className="sectionBox">
          {containers.map((container, index) => (
            <div className="sectionBoxRow" key={index}>
              <div className="inputsBox">
                {Object.entries(container.formData).map(([key, value]) => {
                  if (key === "til dato") {
                    return (
                      <InputWithToggle
                        key={key}
                        placeholder={key}
                        value={value || ""}
                        name={key}
                        type="text"
                        onChange={(value) => handleInputChange(key, value, index)}
                        onClick={(value) => handleInputChange(key, value, index)}
                        activateToggle={activateToggles[index] || false}
                        setActivateToggle={(newState) => {
                          setActivateToggles((prev) => ({
                            ...prev,
                            [index]: newState,
                          }));
                          handleInputChange(
                            key,
                            newState ? "Nåværende jobb" : "",
                            index
                          );
                        }}
                      />
                    );
                  }
                  if (key !== "oppsummering" && key !== "til dato") {
                    return (
                      <Input
                        key={key}
                        placeholder={key}
                        value={value || ""}
                        name={key}
                        onChange={(value) => handleInputChange(key, value, index)}
                      />
                    );
                  }
                  if (key === "oppsummering") {
                    return (
                      <EducationEditor
                        key={key}
                        index={index}
                        content={value}
                        setContent={setContent}
                        name={name}
                        updateFormData={updateFormData}
                      />
                    );
                  }
                  return null;
                })}
              </div>
              <TrashIcon
                containers={containers}
                setContainers={setContainers}
                name={name}
                index={index}
              />
            </div>
          ))}
        </div>
        <div className="moreButton" onClick={showMoreFunc}>
          <p onClick={addMore}>Legg til</p>
        </div>
      </div>
    </div>
  );
}
