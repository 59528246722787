import React, { useRef, useState, useEffect } from 'react';
import { pdf } from "@react-pdf/renderer";
import * as pdfjsLib from "pdfjs-dist";
import { ReactComponent as ArrowLeft } from "../../img/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../img/arrowRight.svg";

const PdfViewer2 = ({ 
  template, 
  profilePicture, 
  cvDesign, 
  cvFonts, 
  cvSize, 
  selectedTemplate,
  showIcons,
  uppercaseHeadlines
}) => {
    const canvasRef = useRef(null);
    const [pdfData, setPdfData] = useState(null);
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [isRendering, setIsRendering] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // A4 dimensions in pixels (at 96 DPI)
    const A4_WIDTH = 595;
    const A4_HEIGHT = 842;
  
    useEffect(() => {
      // Draw blank A4 page initially
      const canvas = canvasRef.current;
      if (canvas) {
        const context = canvas.getContext("2d");
        canvas.width = A4_WIDTH;
        canvas.height = A4_HEIGHT;
        context.fillStyle = "white";
        context.fillRect(0, 0, canvas.width, canvas.height);
      }

      const generatePdfBlob = async () => {
        setIsLoading(true);
        setError(null);
        
        try {
          if (!template) {
            setIsLoading(false);
            return;
          }
          
          const pdfBlob = await pdf(template).toBlob();
          const pdfURL = URL.createObjectURL(pdfBlob);
          
          try {
            const loadedPdf = await pdfjsLib.getDocument(pdfURL).promise;
            setPdfData(loadedPdf);
            setPageCount(loadedPdf.numPages);
            setError(null);
            setIsLoading(false);
          } catch (pdfLoadError) {
            console.error('Error loading PDF document:', pdfLoadError);
            setError(pdfLoadError);
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error generating PDF:', error);
          setError(error);
          setIsLoading(false);
        }
      };
      
      try {
        const hasPersonalia = localStorage.getItem('personalia') !== null;
        if (hasPersonalia && template) {
          generatePdfBlob();
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error checking personalia:', error);
        setError(error);
        setIsLoading(false);
      }
    }, [template]);
  
    const renderPage = async (pageNum) => {
      if (!pdfData) return;
      setIsRendering(true);
  
      try {
        const page = await pdfData.getPage(pageNum);
        const canvas = canvasRef.current;
        if (!canvas) {
          setIsRendering(false);
          return;
        }
        
        const context = canvas.getContext("2d");
        const viewport = page.getViewport({ scale: 1 });
        canvas.width = viewport.width;
        canvas.height = A4_HEIGHT;
    
        await page.render({ canvasContext: context, viewport }).promise;
        setIsRendering(false);
      } catch (error) {
        console.error('Error rendering page:', error);
        setError(error);
        setIsRendering(false);
        
        // Attempt to show a basic blank page as fallback
        if (canvasRef.current) {
          const context = canvasRef.current.getContext("2d");
          context.fillStyle = "white";
          context.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
          
          // Display error message on canvas
          context.font = "14px Arial";
          context.fillStyle = "red";
          context.fillText("Error rendering PDF. Please try again.", 50, 50);
        }
      }
    };
  
    useEffect(() => {
      if (pdfData) {
        renderPage(currentPage);
      }
    }, [currentPage, pdfData]);
  
    const goToPreviousPage = () =>
      setCurrentPage((prev) => Math.max(prev - 1, 1));
    const goToNextPage = () =>
      setCurrentPage((prev) => Math.min(prev + 1, pageCount));
    const disableContextMenu = (event) => {
      event.preventDefault();
    };

    // Error indicator overlay
    const ErrorOverlay = () => (
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '20px',
        borderRadius: '5px',
        color: 'red',
        maxWidth: '80%',
        textAlign: 'center',
        zIndex: 10
      }}>
        <h3>Error loading PDF</h3>
        <p>Please try refreshing the page or updating your information.</p>
        <p style={{fontSize: '0.8rem'}}>{error?.message || 'Unknown error'}</p>
      </div>
    );

    // Loading indicator
    const LoadingOverlay = () => (
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '20px',
        borderRadius: '5px',
        color: '#333',
        maxWidth: '80%',
        textAlign: 'center',
        zIndex: 10
      }}>
        <p>Loading PDF preview...</p>
      </div>
    );
    
    return (
      <div style={{ textAlign: "center", position: "relative" }} className="pdfViewer" onContextMenu={disableContextMenu}>
        <canvas 
          ref={canvasRef} 
          style={{ 
            border: "1px solid #ccc",
            backgroundColor: "white",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }} 
        />
        
        {error && <ErrorOverlay />}
        {isLoading && <LoadingOverlay />}
        
        <div style={{ marginTop: "10px" }}>
          <ArrowLeft 
            onClick={goToPreviousPage} 
            disabled={currentPage === 1 || isRendering} 
            className={`arrowLeft ${currentPage === 1 || isRendering ? 'disabled' : ''}`}
          />
          <span style={{ 
            margin: "0px 50px", 
            position: "absolute", 
            bottom: -100, 
            left: 0, 
            right: 0, 
            textAlign: "center", 
            fontSize: 36 
          }}>
            {currentPage} / {Math.max(pageCount, 1)}
          </span>
          <ArrowRight 
            onClick={goToNextPage} 
            disabled={currentPage === pageCount || isRendering} 
            className={`arrowRight ${currentPage === pageCount || isRendering ? 'disabled' : ''}`}
          />
        </div>
      </div>
    );
};
  
export default PdfViewer2;
  