// import logo from "../img/LageCV.png"
import { ReactComponent as LageCV } from "../img/LageCV.svg"
import "../css/Logo.css"

export default function Logo() {
    return (
        <div className="logo-container">
            <img src="/logo/lagecv_main_logo.png" alt="LageCV logo" className="logo-svg" />
            {/* <LageCV className="logo-svg" /> */}
        </div>
    );
}
