import React from "react";
import "./templates.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Canvas,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
import Star from "../../img/Star.js";
import axios from "axios";
import { useEffect, useState } from "react";
import registerFonts from "./Fonts";
import renderStars from "./RenderStars";
import StarIcon from "./StarIcon.js";
import safeGet from "./SafeGet.js";
import Calendar from "../../components/cv/icons/Calendar.js";
import Email from "../../components/cv/icons/Email.js";
import Phone from "../../components/cv/icons/Phone.js";
import Location from "../../components/cv/icons/Location.js";
// import selectedCVMalDeets from "./SelectedMalDeets.js";
import convertImageToImg from "./convertToImage.js";
import Business from "../../components/cv/icons/Business.js";

const Asu = ({
  cvData,
  cvDesign,
  selectedCVMal,
  cvFonts,
  cvSize,
  profilePicture,
  showIcons = true,
  uppercaseHeadlines = false,
}) => {
  registerFonts();
  let globalColors = {};

  // if (cvDesign) {

  const [references, setReferences] = useState(() => {
    try {
      const savedState = localStorage.getItem("referanser");
      if (!savedState) return false;
      const parsedState = JSON.parse(savedState);
      return parsedState?.toggleState ?? false;
    } catch (error) {
      console.error("Error reading references state:", error);
      return false;
    }
  });
  globalColors = {
    primary: cvDesign ? cvDesign.primaryColor : "#27384D",
    secondary: cvDesign ? cvDesign.secondaryColor : "#d6e4fe",
    accent: cvDesign ? cvDesign.tertiaryColor : globalColors.secondary,
    text: "#333333",
    font: cvFonts || "Poppins",
    textSizeBody: cvSize || 8,

    // };
  };

  // Function to handle section title text transformation
  const formatSectionTitle = (title) => {
    return uppercaseHeadlines ? title.toUpperCase() : title;
  };

  // [{"id": 1, "hex": "#FEEED6"}, {"id": 2, "hex": "#d6e4fe"}, {"id": 3, "hex": "#d2d3f7"}, {"id": 4, "hex": "#f2dedc"}, {"id": 5, "hex": "#d4f2dc"}]
  // Create styles for the PDF
  const styles = StyleSheet.create({
    document: {
      width: "595px",
      height: "841px",
      backgroundColor: globalColors.secondary,
    },
    page: {
      display: "flex",
      flexDirection: "row",
      fontSize: globalColors.textSizeBody,
      height: "100%",
      position: "relative",
      backgroundColor: globalColors.secondary,
    },
    sidebar: {
      width: "30%",
      padding: 15,
      margin: 20,
      border: "2px solid " + globalColors.accent,
      borderRadius: 20,
      color: globalColors.primary,
      display: "flex",
      flexDirection: "column",
      position: "relative",
      zIndex: 4,
    },
    profilePicture: {
      display: "flex",
      width: "120px",
      height: "120px",
      objectFit: "cover",
      borderRadius: "50%",
      borderColor: globalColors.primary,
      borderWidth: 2,
      marginBottom: 10,
      backgroundColor: globalColors.secondary,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      zIndex: 3,
    },
    stars: {
      display: "flex",
      width: "10px",
      height: "10px",
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },
    mainContent: {
      width: "70%",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginTop: "30px",
      position: "relative",
    },
    sectionTitle: {
      fontSize: globalColors.textSizeBody,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 0,
      marginTop: 5,
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 10,
      color: globalColors.primary,
      // textTransform: "uppercase",
    },
    firstNameSection: {
      marginBottom: 40,
      marginTop: 40,
      fontFamily: globalColors.font,
      fontWeight: "bold",
    },
    firstName: {
      fontSize: globalColors.textSizeBody + 20,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      display: "block",
    },
    sectionTitleMain: {
      fontSize: globalColors.textSizeBody + 4,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 5,
      marginTop: 10,
    },
    starContainer: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 5,
    },
    star: {
      width: 10,
      height: 10,
      marginRight: 3,
    },
    textItem: {
      marginBottom: 3,
      fontSize: globalColors.textSizeBody - 1,
    },
    link: {
      color: globalColors.primary,
      textDecoration: "underline",
      fontSize: globalColors.textSizeBody,
      display: "inline",
    },
    groupItems: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 3,
    },
    groupItemsSoc: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      width: "60%",
    },
    columnGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginBottom: "10px",
    },
    divider: {
      backgroundColor: globalColors.primary,
    },
    divider2: {
      marginLeft: "0px",
      height: 1.7,
      backgroundColor: globalColors.secondary,
      marginBottom: "10px",
    },
    bold: {
      fontFamily: globalColors.font,
      fontWeight: "bold",
      color: globalColors.primary,
      fontSize: globalColors.textSizeBody + 1,
    },
    text: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
      color: globalColors.primary,
    },
    light: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
      fontWeight: "normal",
      fontFamily: globalColors.font,
      color: globalColors.primary,
    },
    blob: {
      backgroundColor: globalColors.secondary,
      width: "100%",
      height: 180,
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 10,
    },
    blob2: {
      backgroundColor: globalColors.primary,
      width: 150,
      height: "100%",
      position: "absolute",
      top: 0,
      left: -150,
      zIndex: 20,
    },
    blob3: {
      backgroundColor: globalColors.accent,
      width: 120,
      height: 120,
      borderRadius: 100,
      objectPositionX: "-10px",
      objectPositionY: "-10px",
      position: "absolute",
      bottom: -30,
      right: -30,
    },
  });

  const Divider = () => <View style={styles.divider} />;
  const Divider2 = () => <View style={styles.divider2} />;

  const [cvMal, setCVMal] = useState(null);
  convertImageToImg();
  useEffect(() => {
    const selectedCVFromLocalStorage = localStorage.getItem("selectedCV");
    if (selectedCVFromLocalStorage) {
      setCVMal(JSON.parse(selectedCVFromLocalStorage));
    }
    // selectedCVMalDeets(selectedCVMal, setCVMal);
  }, []);
  // }, [selectedCVMal]);

  if (!cvData) return null;

  return (
    <Document
      size="A4"
      orientation="portrait"
      className="document"
      style={styles.document}
    >
      <Page style={styles.page}>
        {/* Sidebar for personal details */}

        <Canvas id="magic" style={styles.blob}></Canvas>

        <View style={{ ...styles.sidebar }}>
          {profilePicture ? (
            <Image style={styles.profilePicture} src={profilePicture} />
          ) : (
            <Text style={styles.textItem}>No profile picture available</Text>
          )}
          <Text style={styles.light}>
            {safeGet(cvData, "kort oppsummering")}
          </Text>
          <Text style={styles.sectionTitle}>
            {formatSectionTitle("Kontaktinformasjon")}
          </Text>
          {/* <Divider2 /> */}

          {safeGet(cvData, "Personalia.date") && (
            <View style={styles.groupItems}>
              {showIcons && <Calendar globalColors={globalColors.primary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text style={styles.textItem}>
                {safeGet(cvData, "Personalia.date")}
              </Text>
            </View>
          )}
          {safeGet(cvData, "Personalia.email") && (
            <View style={styles.groupItems}>
              {showIcons && <Email globalColors={globalColors.primary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text style={styles.textItem}>
                {safeGet(cvData, "Personalia.email")}
              </Text>
            </View>
          )}
          {safeGet(cvData, "Personalia.phonenumber") && (
            <View style={styles.groupItems}>
              {showIcons && <Phone globalColors={globalColors.primary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text style={styles.textItem}>
                {safeGet(cvData, "Personalia.phonenumber")}
              </Text>
            </View>
          )}
          {safeGet(cvData, "Personalia.address") && (
            <View style={styles.groupItems}>
              {showIcons && <Location globalColors={globalColors.primary} />}
              {showIcons && <View style={{ width: "5px" }}></View>}
              <Text style={styles.textItem}>
                {safeGet(cvData, "Personalia.address")}
              </Text>
            </View>
          )}

          {safeGet(cvData, "Ferdigheter", []).length > 0 && (
            <Text style={styles.sectionTitle}>
              {formatSectionTitle("Ferdigheter")}
            </Text>
          )}
          {/* <Divider2 /> */}
          {safeGet(cvData, "Ferdigheter", []).map((skill, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(skill, "formData.ferdigheter")}
              </Text>
              {/* {renderStars(safeGet(skill, "formData.grad"), styles)} */}
            </View>
          ))}

          {safeGet(cvData, "Interesser", []).length > 0 && (
            <Text style={styles.sectionTitle}>
              {formatSectionTitle("Interesser")}
            </Text>
          )}
          {/* <Divider2 /> */}
          {safeGet(cvData, "Interesser", []).map((interest, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.interesser")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.navn")}
              </Text>
              {/* {renderStars(safeGet(interest, "formData.grad"), styles)} */}
            </View>
          ))}

          {safeGet(cvData, "Språk", []).length > 0 && (
            <Text style={styles.sectionTitle}>
              {formatSectionTitle("Språk")}
            </Text>
          )}
          {/* <Divider2 /> */}
          {safeGet(cvData, "Språk", []).map((language, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.navn")}
                {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.språk")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* {renderStars(safeGet(language, "formData.grad"), styles)} */}
            </View>
          ))}

          {safeGet(cvData, "Frivillighet", []).length > 0 && (
            <Text
              style={{
                ...styles.sectionTitle,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {formatSectionTitle("Frivillighet")}
            </Text>
          )}
          <Divider />
          {safeGet(cvData, "Frivillighet", []).map((frivillighet, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={{ ...styles.bold, ...styles.textItem }}>
                {safeGet(frivillighet, "formData.fra dato")} -{" "}
                {safeGet(frivillighet, "formData.til dato", "Nåværende")}{" "}
              </Text>
              <Text style={{ ...styles.bold, ...styles.textItem }}>
                {safeGet(frivillighet, "formData.frivillighet")} -{" "}
                <Text>{safeGet(frivillighet, "formData.sted")}</Text>{" "}
              </Text>
              <Text style={{ ...styles.light, ...styles.textItem }}>
                {safeGet(frivillighet, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <View wrap={false}>
            {safeGet(cvData, "Referanser", []).length > 0 && (
              <Text
                style={{
                  ...styles.sectionTitle,
                  color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {formatSectionTitle("Referanser")}
              </Text>
            )}
            <Divider />
            {references ? (
              <Text style={styles.text}>Referanser oppgis ved forespørsel</Text>
            ) : (
              safeGet(cvData, "Referanser", []).map((ref, index) => (
                <View key={index} style={styles.columnGroup}>
                  <Text
                    style={{
                      ...styles.bold,
                      ...styles.text,
                      color: safeGet(cvDesign, "primaryColor", "#000000"),
                    }}
                  >
                    {safeGet(ref, "formData.navn")}
                  </Text>{" "}
                  <Text
                    style={{
                      // ...styles.bold,
                      ...styles.text,
                      color: safeGet(cvDesign, "primaryColor", "#000000"),
                    }}
                  >
                    {safeGet(ref, "formData.stillingstittel")}
                  </Text>
                  <View style={styles.groupItems}>
                    {showIcons && (
                      <Business globalColors={globalColors.primary} />
                    )}
                    {showIcons && <View style={{ width: "5px" }}></View>}

                    <Text style={styles.textItem}>
                      {safeGet(ref, "formData.arbeidssted")}
                    </Text>
                  </View>
                  <View style={styles.groupItems}>
                    {showIcons && <Phone globalColors={globalColors.primary} />}
                    {showIcons && <View style={{ width: "5px" }}></View>}
                    <Text style={styles.textItem}>
                      {safeGet(ref, "formData.telefon")}
                    </Text>
                  </View>
                  <View style={styles.groupItems}>
                    {showIcons && <Email globalColors={globalColors.primary} />}
                    {showIcons && <View style={{ width: "5px" }}></View>}
                    <Text style={styles.textItem}>
                      {safeGet(ref, "formData.epost")}
                    </Text>
                  </View>
                </View>
              ))
            )}
          </View>

          {safeGet(cvData, "Portefølje") &&
            Object.keys(safeGet(cvData, "Portefølje")).length > 0 && (
              <Text
                style={{
                  ...styles.sectionTitle,
                  color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {formatSectionTitle("Portefølje")}
              </Text>
            )}
          {/* <Divider2 /> */}
          <View style={styles.groupItemsSoc}>
            {safeGet(cvData, "Portefølje.instagram") && (
              <Link
                style={styles.link}
                src={safeGet(cvData, "Portefølje.instagram")}
              >
                Instagram
              </Link>
            )}
            {safeGet(cvData, "Portefølje.twitter") && (
              <Link
                style={styles.link}
                src={safeGet(cvData, "Portefølje.twitter")}
              >
                Twitter
              </Link>
            )}
            {safeGet(cvData, "Portefølje.github") && (
              <Link
                style={styles.link}
                src={safeGet(cvData, "Portefølje.github")}
              >
                Github
              </Link>
            )}
            {safeGet(cvData, "Portefølje.linkedin") && (
              <Link
                style={styles.link}
                src={safeGet(cvData, "Portefølje.linkedin")}
              >
                Linkedin
              </Link>
            )}{" "}
            {safeGet(cvData, "Portefølje.tiktok") && (
              <Link
                style={styles.link}
                src={safeGet(cvData, "Portefølje.tiktok")}
              >
                Tiktok
              </Link>
            )}
            {safeGet(cvData, "Portefølje.nettside") && (
              <Link
                style={styles.link}
                src={safeGet(cvData, "Portefølje.nettside")}
              >
                Nettside
              </Link>
            )}
          </View>
        </View>

        {/* Main content */}
        <View style={styles.mainContent}>
          {/* <Canvas style={styles.blob2}></Canvas> */}
          {/* <Canvas style={styles.blob3}></Canvas> */}
          <View style={styles.firstNameSection}>
            {" "}
            <Text
              style={{
                ...styles.firstName,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {uppercaseHeadlines
                ? `${safeGet(
                    cvData,
                    "Personalia.firstName",
                    ""
                  ).toUpperCase()} ${safeGet(
                    cvData,
                    "Personalia.lastName",
                    ""
                  ).toUpperCase()}`
                : `${safeGet(cvData, "Personalia.firstName", "")} ${safeGet(
                    cvData,
                    "Personalia.lastName",
                    ""
                  )}`}
            </Text>
            <Text style={{ ...styles.bold, ...styles.text }}>
              {safeGet(cvData, "Personalia.jobtitle")}
            </Text>
          </View>

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvDesign, "primaryColor", "#000000"),
            }}
          >
            {formatSectionTitle("Arbeidserfaring")}
          </Text>
          <Divider />
          {safeGet(cvData, "Arbeidserfaring", []).map((job, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={styles.text}>
                {safeGet(job, "formData.fra dato")} -{" "}
                {safeGet(job, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.bold}>
                {safeGet(job, "formData.stillingstittel")} |{" "}
                {safeGet(job, "formData.arbeidssted")}
              </Text>
              <Text style={styles.light}>
                {safeGet(job, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvDesign, "primaryColor", "#000000"),
            }}
          >
            {formatSectionTitle("Utdanning")}
          </Text>
          <Divider />
          {safeGet(cvData, "Utdanning", []).map((education, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={{ ...styles.text }}>
                {safeGet(education, "formData.fra dato")} -{" "}
                {safeGet(education, "formData.til dato", "Nåværende")}{" "}
              </Text>
              <Text style={{ ...styles.bold }}>
                {safeGet(education, "formData.utdanningsnavn")} -{" "}
                <Text>{safeGet(education, "formData.utdanningssted")}</Text>
              </Text>
              <Text style={styles.light}>
                {safeGet(education, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <View wrap={false}>
            {safeGet(cvData, "Militærtjeneste", []).length > 0 && (
              <Text
                style={{
                  ...styles.sectionTitleMain,
                  color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {formatSectionTitle("Militærtjeneste")}
              </Text>
            )}
            <Divider />
            {safeGet(cvData, "Militærtjeneste", []).map((military, index) => (
              <View key={index} style={styles.columnGroup}>
                <Text style={{ ...styles.text }}>
                  {safeGet(military, "formData.fra dato")} -{" "}
                  {safeGet(military, "formData.til dato", "Nåværende")}{" "}
                </Text>
                <Text style={{ ...styles.bold }}>
                  {safeGet(military, "formData.militærtjeneste")} -{" "}
                  <Text>{safeGet(military, "formData.sted")}</Text>{" "}
                </Text>
                <Text style={styles.light}>
                  {safeGet(military, "formData.oppsummering")}
                </Text>
              </View>
            ))}
          </View>

          {safeGet(cvData, "Kurs", []).length > 0 && (
            <Text
              style={{
                ...styles.sectionTitleMain,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {formatSectionTitle("Kurs")}
            </Text>
          )}
          <Divider />
          {safeGet(cvData, "Kurs", []).map((kurs, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={{ ...styles.text }}>
                {safeGet(kurs, "formData.fra dato")} -{" "}
                {safeGet(kurs, "formData.til dato", "Nåværende")}{" "}
              </Text>
              <Text style={{ ...styles.bold }}>
                {safeGet(kurs, "formData.kurs")} -{" "}
                <Text>{safeGet(kurs, "formData.sted")}</Text>{" "}
              </Text>
              <Text style={styles.light}>
                {safeGet(kurs, "formData.oppsummering")}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default Asu;
