import Input from "../components/Input";
import { useState, useEffect } from "react";
import TrashIcon from "../components/Trash";
import Stars from "./Stars";
import saveData from "../components/saveData";
import updateCV from "./updateCV";
import axios from "axios";
import { useAuth } from "../components/AuthContext";
import { v4 as uuidv4 } from 'uuid';
import { useLocalStorage } from '../hooks/useLocalStorage';
export default function Skills({ name, description, formData, placeholder }) {
  const [showMore, setShowMore] = useState(false);
  const [serverLastUpdated, setServerLastUpdated] = useState(null);
  const { isAuthenticated } = useAuth();
  const [activateToggle, setActivateToggle] = useState(false);
  const [storedData, setStoredData] = useLocalStorage(name.toLowerCase(), {});
  const [containers, setContainers] = useState(() => {
    try {
      const savedData = localStorage.getItem(name.toLowerCase());

      if (savedData) {
        const parsedData = JSON.parse(savedData);
        return parsedData[name]?.map(container => ({
          ...container,
          id: container.id || uuidv4()
        })) || [{ id: uuidv4(), formData }];
      }
    } catch (error) {
      console.error('Error parsing localStorage data:', error);
    }
    return [{ id: uuidv4(), formData }];
  });
  const [rating, setRating] = useState(0);

  const showMoreFunc = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    // fetchLatestCVData();
  }, []);
  useEffect(() => {

    if (isAuthenticated) {

      if(!localStorage.getItem("arbeidserfaring")) {
      // if(!localStorage.getItem(name.toLowerCase())) {
        
        fetchData();
      }

        // Set up interval to check for updates
        const interval = setInterval(() => {
            const localData = JSON.parse(localStorage.getItem(name.toLowerCase()));
            const localLastUpdated = localData?.lastUpdated;
            
            if (localLastUpdated && (!serverLastUpdated || new Date(localLastUpdated) > new Date(serverLastUpdated))) {
                syncWithServer(localData);
            }
        }, 10000); // Check every 10 seconds

        return () => clearInterval(interval);
    }
}, [isAuthenticated, serverLastUpdated]);

const syncWithServer = async (data) => {
  
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/cv/${name.toLowerCase() === 'språk' ? 'sprak' : name.toLowerCase()}`,
            { ...data, cvId: localStorage.getItem('cvId') },
            { withCredentials: true }
        );
        if(response.status === 200) {
            setServerLastUpdated(response.data.lastUpdated);
        }
    } catch (error) {
        console.error('Error syncing with server:', error);
    }
};

const fetchData = async () => {
    let token = window.location.search.split('=')[0];
    let idcv = window.location.search.split('=')[1];
    let cvId = localStorage.getItem('cvId');

    //if localstorage and browser has id, and they are the same, fetch latest data from server
    if((cvId && idcv && (cvId === idcv))|| idcv && token === "?idcv" ) {

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieve${name}`, {
            withCredentials: true,
            params: {cvId: idcv}
        }).then((response) => {

            const localData = JSON.parse(localStorage.getItem(name.toLowerCase()));
            if (!localData) {
              
                // localStorage.setItem(name.toLowerCase(), JSON.stringify(response.data));
                setStoredData(response.data);
            }

            setServerLastUpdated(response.data.lastUpdated);
        });
    } else if (!idcv && cvId) {

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieve${name}`, {
            withCredentials: true,
            params: {cvId}
        }).then((response) => {
            const localData = JSON.parse(localStorage.getItem(name.toLowerCase()));
            if (!localData) {
                // localStorage.setItem(name.toLowerCase(), JSON.stringify(response.data));
                setStoredData(response.data);
            }
            setServerLastUpdated(response.data.lastUpdated);
        });
    } else if ((idcv && token === "?idcv") && cvId !== idcv) {

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieve${name}`, {
            withCredentials: true,
            params: {cvId: idcv}
        }).then((response) => {

                  // localStorage.setItem(name.toLowerCase(), JSON.stringify(response.data));
                  setStoredData(response.data);

            setServerLastUpdated(response.data.lastUpdated);
        });
    }
}

  const addMore = () => {
    // Create a new container with empty formData fields and UUID
    const newContainer = {
      id: uuidv4(),
      formData: {
        [`${name.toLowerCase()}`]: "",
        "grad": 0
      }
    };
    
    const updatedContainers = [...containers, newContainer];
    setContainers(updatedContainers);

    // Update localStorage with the new container
    const localStorageKey = `${name.toLowerCase()}`;
    const dataToStore = {
      [name]: updatedContainers,
      lastUpdated: new Date().toISOString(),
    };
    setStoredData(dataToStore);
    // localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));
  };

  const handleRating = (key, rating, index) => {
    const updatedContainers = containers.map((container, i) =>
      i === index
        ? {
            ...container,
            formData: { ...container.formData, [key]: rating },
          }
        : container
    );

    // Update localStorage
    const localStorageKey = `${name.toLowerCase()}`;
    const dataToStore = {
      [`${name.toLowerCase()}`]: updatedContainers,
      lastUpdated: new Date().toISOString(),
    };
    setStoredData(dataToStore);
    // localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));

    setContainers(updatedContainers);
  };

  const handleInputChange = (key, value, index) => {

    const updatedContainers = containers.map((container, i) =>
      i === index
        ? {
            ...container,
            formData: { ...container.formData, [key]: value },
          }
        : container
    );

    // Update localStorage
    const localStorageKey = `${name.toLowerCase()}`;
    const dataToStore = {
      [name]: updatedContainers,
      lastUpdated: new Date().toISOString(),
    };
    setStoredData(dataToStore);
    // localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));

    setContainers(updatedContainers);
  };

  return (
    <div className="personaliaBox">
      <div className="personaliaBoxPad">
        <h2>{name}</h2>
        <p>{description}</p>
        <div className="sectionBox">
          {containers.map((container, index) => (
            <div className="starsBox" key={index + 1}>
              {Object.entries(container.formData).map(([key, value]) => {
                if (key !== "grad") {
                  return (
                    <div key={index}>
                      <Input
                        placeholder={placeholder}
                        name={formData.navn}
                        type={"text"}
                        value={value || ''}
                        onChange={(value) => handleInputChange(key, value, index)}
                      ></Input>
                    </div>
                  );
                }
                return null;
              })}
              {Object.entries(container.formData).map(([key, value]) => {
                if (key === "grad") {
                  return (
                    <div className="starsBox" key={index}>
                      <Stars
                        key={index}
                        starValue={value}
                        onRatingSelected={(rating) =>
                          handleRating("grad", rating, index)
                        }
                      ></Stars>
                    </div>
                  );
                }
                return null;
              })}
              <TrashIcon
                containers={containers}
                setContainers={setContainers}
                name={name}
                index={index}
              />
            </div>
          ))}
        </div>

        <div className="moreButton" onClick={showMoreFunc}>
          <p onClick={addMore}>Legg til</p>
        </div>
      </div>
    </div>
  );
}
