import { Svg, Path } from "@react-pdf/renderer";

const Location = ({ globalColors }) => {
  return (
    <Svg width="10" height="10" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M16.6663 31.6669V23.3335H23.333V31.6669C23.333 32.5835 24.083 33.3335 24.9997 33.3335H29.9997C30.9163 33.3335 31.6663 32.5835 31.6663 31.6669V20.0002H34.4997C35.2663 20.0002 35.633 19.0502 35.0497 18.5502L21.1163 6.0002C20.483 5.43353 19.5163 5.43353 18.883 6.0002L4.94968 18.5502C4.38301 19.0502 4.73301 20.0002 5.49968 20.0002H8.33301V31.6669C8.33301 32.5835 9.08301 33.3335 9.99967 33.3335H14.9997C15.9163 33.3335 16.6663 32.5835 16.6663 31.6669Z" fill={globalColors}/>
    </Svg>
  );
};

export default Location;