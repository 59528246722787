import { useEffect, useState } from "react"
import axios from "axios"
import "../css/ResetPassword.css"

export default function ResetPassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const jwtToken = window.location.href.split("/")[window.location.href.split("/").length - 1];
    
    useEffect(() => {

    }, []);

    const handleSubmit = (e) => {

        e.preventDefault();

        axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, {
            password: password,
            confirmPassword: confirmPassword,
            token: jwtToken
        })
        .then(res => {

        })
        .catch(err => {

            setError("Noe gikk galt. Prøv igjen senere.");
        })
    }

    return(
        <div className="resetPassword">
            <form onSubmit={handleSubmit}>
                <h1>Tilbakestill passord</h1>
                
                {error && (
                    <div className="error">
                        {error}
                    </div>
                )}
                
                <div className="input-container">
                    <input 
                        type="password" 
                        placeholder="Nytt passord" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                    />
                    <input 
                        type="password" 
                        placeholder="Bekreft passord" 
                        value={confirmPassword} 
                        onChange={(e) => setConfirmPassword(e.target.value)} 
                    />
                </div>
                
                <div className="button-container">
                    <button type="submit">Tilbakestill passord</button>
                </div>
            </form>
        </div>
    )
}