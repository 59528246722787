// Create styles
// Register custom fonts if needed
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Canvas,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
// import Star from '../img/StarFilled.svg';
// import Star from './Star.js';
import Star from "../../img/Star.js";
// import Star from '../../public/Star.js';
import axios from "axios";
import { useEffect, useState } from "react";
import registerFonts from "./Fonts";
import renderStars from "./RenderStars";
import profilePicPlaceholder from "../../img/profilePicPlaceholder.png";
import StarIcon from "./StarIcon.js";
import safeGet from "./SafeGet.js";
import selectedCVMalDeets from "./SelectedMalDeets.js";


const MyDocument = ({ cvData, selectedCVMal, cvDesign, cvFonts, cvSize }) => {
  registerFonts();

  const globalColors = {
    primary: cvDesign.farger.primaryColor || "#27384D",
    secondary: cvDesign.farger.secondaryColor || "#ffffff",
    accent: cvDesign.farger.accentColor || "#27384D",
    text: "#333333",
    font: cvFonts || "Poppins",
    textSizeBody: cvSize || 8,
  };

  // Create styles for the PDF
  const styles = StyleSheet.create({
    document: {
      width: "60%",
      height: "100%",
      minHeight: "800px",
      backgroundColor: globalColors.secondary,
    },
    page: {
      display: "flex",
      flexDirection: "row",
      fontSize: globalColors.textSizeBody,
      height: "100%",
    },
    sidebar: {
      width: "40%",
      padding: 10,
      backgroundColor: globalColors.secondary,
      color: globalColors.secondary,
      display: "flex",
      flexDirection: "column",
    },
    profilePicture: {
      display: "flex",
      width: "80px",
      height: "80px",
      objectFit: "cover",
      borderRadius: "50%",
      borderColor: globalColors.secondary,
      borderWidth: 2,
      marginBottom: 10,
      backgroundColor: globalColors.secondary,
      boxShadow: `inset 2px -2px 0px ${globalColors.primary}`,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },
    stars: {
      display: "flex",
      width: "10px",
      height: "10px",
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },
    mainContent: {
      width: "70%",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginTop: "30px",
    },
    sectionTitle: {
      fontSize: globalColors.textSizeBody + 1,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 10,
      marginTop: 10,
      backgroundColor: globalColors.primary,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 10,
    },
    sectionTitleMain: {
      fontSize: globalColors.textSizeBody + 4,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 5,
      marginTop: 20,
    },
    starContainer: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 5,
    },
    star: {
      width: 10,
      height: 10,
      marginRight: 3,
    },
    textItem: {
      marginBottom: 3,
      fontSize: globalColors.textSizeBody - 2,
    },
    link: {
      color: globalColors.secondary,
      textDecoration: "underline",
      fontSize: globalColors.textSizeBody - 2,
    },
    groupItems: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 5,
    },
    groupItemsSoc: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 5,
    },
    columnGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginBottom: "10px",
    },
    divider: {
      // width: '90%',
      height: 1.7,
      backgroundColor: globalColors.primary,
      marginBottom: "10px",
    },
    divider2: {
      // width: '110%',
      marginLeft: "0px",
      height: 1.7,
      backgroundColor: globalColors.secondary,
      marginBottom: "10px",
    },
    bold: {
      fontFamily: globalColors.font,
      fontWeight: "bold",
    },
    text: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
    },
    light: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
      fontWeight: "normal",
      fontFamily: globalColors.font,
    },
  });

  const Divider = () => <View style={styles.divider} />;
  const Divider2 = () => <View style={styles.divider2} />;

  const [cvMal, setCVMal] = useState(null);

  useEffect(() => {
    selectedCVMalDeets(selectedCVMal, setCVMal);
  }, [selectedCVMal]);


  if (!cvData) return null;




  return (
    <Document
      size="A4"
      orientation="portrait"
      className="document"
      style={styles.document}
    >
      <Page style={styles.page}>
        {/* Sidebar for personal details */}
        <View style={{ ...styles.sidebar }}>
          <Image
            style={styles.profilePicture}
            src="https://plus.unsplash.com/premium_photo-1690294614341-cf346ba0a637?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          />
          <Canvas style={styles.blob}></Canvas>
          <Text style={styles.sectionTitle}>Kontaktinformasjon</Text>
          <Divider2 />

          {safeGet(cvData, "Personalia.date") && (
            <Text style={styles.textItem}>
              Født: {safeGet(cvData, "Personalia.date")}
            </Text>
          )}
          {safeGet(cvData, "Personalia.email") && (
            <Text style={styles.textItem}>
              Email: {safeGet(cvData, "Personalia.email")}
            </Text>
          )}
          {safeGet(cvData, "Personalia.phonenumber") && (
            <Text style={styles.textItem}>
              Telefon: {safeGet(cvData, "Personalia.phonenumber")}
            </Text>
          )}
          {safeGet(cvData, "Personalia.address") && (
            <Text style={styles.textItem}>
              Adresse: {safeGet(cvData, "Personalia.address")}
            </Text>
          )}

          <Text style={styles.sectionTitle}>Ferdigheter</Text>
          <Divider2 />
          {safeGet(cvData, "Ferdigheter", []).map((skill, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(skill, "formData.navn")}
              </Text>
              {renderStars(safeGet(skill, "formData.grad"), styles)}
            </View>
          ))}

          <Text style={styles.sectionTitle}>Interesser</Text>
          <Divider2 />
          {safeGet(cvData, "Interesser", []).map((interest, index) => (
            <View key={index} style={styles.groupItems}>
      <Text style={styles.textItem}>
                {safeGet(interest, "formData.interesser")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.navn")}
              </Text>
              {renderStars(safeGet(interest, "formData.grad"), styles)}
            </View>
          ))}

          <Text style={styles.sectionTitle}>Språk</Text>
          <Divider2 />
          {safeGet(cvData, "Språk", []).map((language, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.språk")}
              </Text>
              {renderStars(safeGet(language, "formData.grad"), styles)}
              <Text style={styles.textItem}>
                {safeGet(language, "formData.navn")}
              </Text>
              {renderStars(safeGet(language, "formData.grad"), styles)}

           
          
            </View>
          ))}

          <Text style={styles.sectionTitle}>Portefølje</Text>
          <Divider2 />
          <View style={styles.groupItemsSoc}>
            {safeGet(cvData, "Portefølje.instagram") && (
              <Text style={styles.link}>
                <Link style={styles.link} src="www.instagram.com">
                  Instagram
                </Link>
              </Text>
            )}
            {safeGet(cvData, "Portefølje.twitter") && (
              <Text style={styles.link}>
                <Link style={styles.link} src="www.twitter.com">
                  Twitter
                </Link>
              </Text>
            )}
            {safeGet(cvData, "Portefølje.github") && (
              <Text style={styles.link}>
                <Link style={styles.link} src="www.github.com">
                  Github
                </Link>
              </Text>
            )}
            {safeGet(cvData, "Portefølje.linkedin") && (
              <Text style={styles.link}>
                <Link style={styles.link} src="www.linkedin.com">
                  Linkedin
                </Link>
              </Text>
            )}
            {safeGet(cvData, "Portefølje.nettside") && (
              <Text style={styles.link}>
                <Link style={styles.link} src="www.exuma.no">
                  Nettside
                </Link>
              </Text>
            )}
          </View>
        </View>

        {/* Main content */}
        <View style={styles.mainContent}>
          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvData, "farger.primaryColor", "#000000"),
            }}
          >
            {`${safeGet(cvData, "Personalia.firstName", "")} ${safeGet(
              cvData,
              "Personalia.lastName",
              ""
            )}`}
          </Text>
          <Text style={{ ...styles.bold, ...styles.text }}>
            {safeGet(cvData, "Personalia.jobtitle")}
          </Text>
          <Text style={styles.light}>
            {safeGet(cvData, "kort oppsummering")}
          </Text>

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvData, "farger.secondaryColor", "#000000"),
            }}
          >
            Arbeidserfaring
          </Text>
          <Divider />
          {safeGet(cvData, "Arbeidserfaring", []).map((job, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={styles.text}>
                {safeGet(job, "formData.fra dato")} -{" "}
                {safeGet(job, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.bold}>
                {safeGet(job, "formData.stillingstittel")} |{" "}
                {safeGet(job, "formData.arbeidssted")}
              </Text>
              <Text style={styles.light}>
                {safeGet(job, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvData, "farger.primaryColor", "#000000"),
            }}
          >
            Utdanning
          </Text>
          <Divider />
          {safeGet(cvData, "Utdanning", []).map((education, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(education, "formData.utdanningsnavn")} -{" "}
                <Text>{safeGet(education, "formData.utdanningssted")}</Text>
              </Text>
              <Text style={styles.light}>
                {safeGet(education, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvData, "farger.primaryColor", "#000000"),
            }}
          >
            Referanser
          </Text>
          <Divider />
          {safeGet(cvData, "Referanser", []).map((ref, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text
                style={{
                  ...styles.bold,
                  ...styles.text,
                  color: safeGet(cvData, "farger.primaryColor", "#000000"),
                }}
              >
                {safeGet(ref, "formData.navn")} -{" "}
                {safeGet(ref, "formData.stillingstittel")}
              </Text>
              <Text style={styles.text}>
                {safeGet(ref, "formData.arbeidssted")}
              </Text>
              <Text style={styles.text}>
                Telefon: {safeGet(ref, "formData.telefon")}
              </Text>
              <Text style={styles.text}>
                Epost: {safeGet(ref, "formData.epost")}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
