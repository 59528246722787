import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/CvPreviewThumbnail.css';

// Template color palettes for quick previews
const templateStyles = {
  'Toyko': {
    primary: '#3498db',
    secondary: '#ffffff',
    accent: '#f1c40f',
    layout: 'split'
  },
  'Mainla': {
    primary: '#27ae60',
    secondary: '#f5f5f5',
    accent: '#2c3e50',
    layout: 'top-header'
  },
  'Cali': {
    primary: '#e74c3c',
    secondary: '#ffffff',
    accent: '#34495e',
    layout: 'side-header'
  },
  'Stokkholm': {
    primary: '#9b59b6',
    secondary: '#ffffff',
    accent: '#f39c12',
    layout: 'minimal'
  },
  'Asu': {
    primary: '#1abc9c',
    secondary: '#f9f9f9',
    accent: '#7f8c8d',
    layout: 'classic'
  },
  'NTNU': {
    primary: '#2c3e50',
    secondary: '#ecf0f1',
    accent: '#e74c3c',
    layout: 'academic'
  },
  'Filipina': {
    primary: '#f39c12',
    secondary: '#ffffff',
    accent: '#16a085',
    layout: 'modern'
  }
};

// Simple date formatter
const formatDate = (dateString) => {
  if (!dateString) return 'Ukjent dato';
  
  try {
    const date = new Date(dateString);
    
    // Check if date is valid
    if (isNaN(date.getTime())) {
      return 'Ukjent dato';
    }
    
    // Format as DD.MM.YYYY
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    
    return `${day}.${month}.${year}`;
  } catch (e) {
    console.error('Error formatting date:', e);
    return 'Ukjent dato';
  }
};

const CvPreviewThumbnail = ({ cv, onClick, onEdit, onDelete, onCopy, onDownload, isLoading }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  
  // Get template style or use default
  const style = templateStyles[cv.cvName || cv.cvTemplate] || templateStyles['Mainla'];
  
  useEffect(() => {
    // Fetch minimal data for the preview
    const fetchCvData = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/cv/preview`,
          { idcv: cv.idcv },
          { withCredentials: true }
        );
        
        if (response.status === 200) {
          setUserData(response.data);
        }
      } catch (error) {
        console.error('Error fetching CV preview data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    // If we have a CV ID, fetch the data
    if (cv.idcv) {
      fetchCvData();
    }
  }, [cv.idcv]);
  
  // Fallback for when there's no data yet
  const fallbackData = {
    firstName: 'Fornavn',
    lastName: 'Etternavn',
    jobtitle: 'Stillingstittel'
  };
  
  // Use the actual data or fallback
  const personalia = userData?.Personalia || fallbackData;
  
  // Determine if we have a preview image from the server
  const hasPreviewImage = Boolean(cv.previewImage);

  // Extract template name (with fallback)
  const templateName = cv.cvName || cv.cvTemplate || 'Mal';
  
  // Format date for display
  const formattedDate = formatDate(cv.last_cv_update);
    
  // Handle clicks on action buttons without triggering parent onClick
  const handleActionClick = (e, action) => {
    e.stopPropagation();
    if (action) action();
  };

  return (
    <div className="cv-item">
      <div className="image-container" onClick={onClick}>
        {loading && !hasPreviewImage ? (
          <div className="preview-loading">
            <div className="loading-spinner"></div>
          </div>
        ) : hasPreviewImage ? (
          // Use the actual preview image from the server
          <img 
            src={cv.previewImage} 
            alt={`CV Preview - ${templateName}`} 
            className="cv-preview-image"
          />
        ) : (
          // Fallback to our simplified preview if no image is available
          <div className="cv-preview">
            {renderPreviewByLayout(style.layout, personalia, style)}
          </div>
        )}
        
        <div className="actions-container">
          <button onClick={(e) => handleActionClick(e, onEdit)}>
            <span className="button-icon edit-icon"></span>
          </button>
          
          <button 
            onClick={(e) => handleActionClick(e, onDownload)}
            className={isLoading ? 'downloading' : ''}
            disabled={isLoading}
          >
            <span className="button-icon download-icon"></span>
          </button>
        </div>
      </div>
      
      <div className="cv-name">{templateName}</div>
      <div className="cv-date">{formattedDate}</div>
      
      <div className="cv-actions">
        <button onClick={onCopy} disabled={isLoading}>
          <span className="button-icon copy-icon"></span>
          Kopier
        </button>
        
        <button onClick={onDelete} disabled={isLoading}>
          <span className="button-icon delete-icon"></span>
          Slett
        </button>
      </div>
    </div>
  );
};

// Helper function to render the preview based on layout type
const renderPreviewByLayout = (layout, personalia, style) => {
  switch(layout) {
    case 'split':
      return (
        <div className="split-layout">
          <div className="preview-sidebar" style={{ backgroundColor: style.primary }}>
            <div className="preview-photo"></div>
          </div>
          <div className="preview-main">
            <div className="preview-name">
              {personalia.firstName} {personalia.lastName}
            </div>
            <div className="preview-title">{personalia.jobtitle}</div>
            <div className="preview-lines">
              <div className="preview-line"></div>
              <div className="preview-line"></div>
              <div className="preview-line"></div>
            </div>
          </div>
        </div>
      );
    
    case 'top-header':
      return (
        <div className="top-header-layout">
          <div className="preview-header" style={{ backgroundColor: style.primary }}>
            <div className="preview-name">
              {personalia.firstName} {personalia.lastName}
            </div>
            <div className="preview-title">{personalia.jobtitle}</div>
          </div>
          <div className="preview-main">
            <div className="preview-lines">
              <div className="preview-line"></div>
              <div className="preview-line"></div>
              <div className="preview-line"></div>
              <div className="preview-line"></div>
            </div>
          </div>
        </div>
      );
    
    case 'side-header':
      return (
        <div className="side-header-layout">
          <div className="preview-header" style={{ backgroundColor: style.primary }}>
            <div className="preview-name">
              {personalia.firstName} {personalia.lastName}
            </div>
          </div>
          <div className="preview-main">
            <div className="preview-title">{personalia.jobtitle}</div>
            <div className="preview-lines">
              <div className="preview-line"></div>
              <div className="preview-line"></div>
            </div>
          </div>
        </div>
      );
      
    default:
      return (
        <div className="default-layout">
          <div className="preview-header" style={{ backgroundColor: style.primary }}>
            <div className="preview-name">
              {personalia.firstName} {personalia.lastName}
            </div>
          </div>
          <div className="preview-main">
            <div className="preview-title">{personalia.jobtitle}</div>
            <div className="preview-lines">
              <div className="preview-line"></div>
              <div className="preview-line"></div>
            </div>
          </div>
        </div>
      );
  }
};

export default CvPreviewThumbnail; 