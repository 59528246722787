import React, { useEffect, useState } from "react";
import Sortable from "sortablejs";
// Import your components here
import PersonaliaBox from "./PersonaliaBox";
import ShortDesc from "./ShortDesc";
import EducationBox from "./EducationBox";
import Skills from "./Skills";
import ReferenceBox from "./Reference";
import AddMoreSections from "./AddMoreSections";
import PortfolioBox from "./PortfolioBox";
import axios from "axios";
import ComponentWrapper from './ComponentWrapper';
//draggablecontainers2 is being used 
const DraggableContainers = ({}) => {
  const [formDataEducation, setFormDataEducation] = useState({
    utdanningsnavn: "",
    utdanningssted: "",
    "fra dato": "",
    "til dato": "",
    oppsummering: "",
  });
  const [formDataWorkExp, setFormDataWorkExp] = useState({
    stillingstittel: "",
    arbeidssted: "",
    "fra dato": "",
    "til dato": "",
    oppsummering: "",
  });
  const [formDataReference, setFormDataReference] = useState({
    navn: "",
    stillingstittel: "",
    arbeidssted: "",
    telefon: "",
    epost: "",
  });
  const [formDataSkills, setFormDataSkills] = useState({
    ferdigheter: "",
    grad: "",
  });
  const [formDataLanguage, setFormDataLanguage] = useState({
    språk: "",
    grad: "",
  });
  const [formDataKurs, setFormDataKurs] = useState({
    kurs: "",
    sted: "",
    "fra dato": "",
    "til dato": "",
    oppsummering: "",
  });
  const [formDataInteresser, setFormDataInteresser] = useState({
    interesser: "",
    grad: "",
  });
  const [formDataFrivillighet, setFormDataFrivillighet] = useState({
    frivillighet: "",
    sted: "",
    "fra dato": "",
    "til dato": "",
    oppsummering: "",
  });
  const [formDataMilitærtjeneste, setFormDataMilitærtjeneste] = useState({
    militærtjeneste: "",
    sted: "",
    "fra dato": "",
    "til dato": "",
    oppsummering: "",
  });



  const [isLoading, setIsLoading] = useState(false);
  const [additionalComponents, setAdditionalComponents] = useState(() => {
    try {
      // Check localStorage for each possible additional component
      const components = [];
      
      // Check for språk
      if (localStorage.getItem('språk')) {
        components.push(
          <Skills
            key={`LanguageSkills-stored`}
            name="Språk"
            description="Legg til dine språk her"
            placeholder="språk"
            formData={formDataLanguage}
            setFormdata={setFormDataLanguage}
          />
        );
      }

      // Check for kurs
      if (localStorage.getItem('kurs')) {
        components.push(
          <EducationBox
            key={`kurs-stored`}
            name="Kurs"
            description="Legg til dine kurs her"
            placeholder="kurs"
            formData={formDataKurs}
            setFormdata={setFormDataKurs}
          />
        );
      }

      // Check for interesser
      if (localStorage.getItem('interesser')) {
        components.push(
          <Skills
            key={`interesser-stored`}
            name="Interesser"
            description="Legg til dine interesser her"
            placeholder="interesser"
            formData={formDataInteresser}
            setFormdata={setFormDataInteresser}
          />
        );
      }

      // Check for frivillighet
      if (localStorage.getItem('frivillighet')) {
        components.push(
          <EducationBox
            key={`frivillighet-stored`}
            name="Frivillighet"
            description="Legg til dine frivillighet her"
            placeholder="frivillighet"
            formData={formDataFrivillighet}
            setFormdata={setFormDataFrivillighet}
          />
        );
      }

      // Check for militærtjeneste
      if (localStorage.getItem('militærtjeneste')) {
        components.push(
          <EducationBox
            key={`militærtjeneste-stored`}
            name="Militærtjeneste"
            description="Legg til din militærtjeneste her"
            formData={formDataMilitærtjeneste}
            setFormdata={setFormDataMilitærtjeneste}
          />
        );
      }

      // Check for portefølje
      if (localStorage.getItem('portefølje')) {
        components.push(
          <PortfolioBox
            key={`portefølje-stored`}
            name="Portefølje"
            description="Legg til din portefølje her"
          />
        );
      }

      return components;
    } catch (error) {
      console.error('Error loading stored components:', error);
      return [];
    }
  });

  // Add a state to track which components are displayed
  const [displayedComponents, setDisplayedComponents] = useState(() => {
    try {
      return {
        'språk': !!localStorage.getItem('språk'),
        'kurs': !!localStorage.getItem('kurs'),
        'interesser': !!localStorage.getItem('interesser'),
        'frivillighet': !!localStorage.getItem('frivillighet'),
        'militærtjeneste': !!localStorage.getItem('militærtjeneste'),
        'portefølje': !!localStorage.getItem('portefølje')
      };
    } catch (error) {
      console.error('Error checking displayed components:', error);
      return {};
    }
  });

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleComponentDelete = (type) => {
    // Remove from localStorage
    localStorage.removeItem(type.toLowerCase());
    
    // Update displayed components state
    setDisplayedComponents(prev => ({
      ...prev,
      [type.toLowerCase()]: false
    }));

    // Remove from additional components
    setAdditionalComponents(prev => 
      prev.filter(comp => comp.props.name !== type)
    );
  };

  const renderComponents = () => [
    <PersonaliaBox key="PersonaliaBox" />,
    <ShortDesc key="ShortDesc" />,
    <EducationBox
      key="WorkExperience"
      name="Arbeidserfaring"
      description="Registrer relevant arbeidserfaring til din CV"
      formData={formDataWorkExp}
      setFormdata={setFormDataWorkExp}
    />,
    <EducationBox
      key="Education"
      name="Utdanning"
      description="Registrer relevant utdanning til din CV"
      formData={formDataEducation}
      setFormdata={setFormDataEducation}
    />,
    <Skills
      key="Skills"
      name="Ferdigheter"
      description="Legg til dine ferdigheter her"
      placeholder="ferdighet"
      formData={formDataSkills}
      setFormdata={setFormDataSkills}
    />,
    <ReferenceBox
      key="ReferenceBox"
      name="Referanser"
      description="Huk av dersom du kun vil oppgi referanser ved forespørsel"
      formData={formDataReference}
      setFormData={setFormDataReference}
    />,
    ...additionalComponents.map(component => (
      <ComponentWrapper
        key={component.key}
        component={component}
        onDelete={() => handleComponentDelete(component.props.name)}
      />
    ))
  ];

  const addBox = (type) => {
    let newComponent;
    switch (type) {
      case "språk":
        newComponent = (
          <Skills
            key={`LanguageSkills-${Date.now()}`}
            name="Språk"
            description="Legg til dine språk her"
            placeholder="språk"
            formData={formDataLanguage}
            setFormdata={setFormDataLanguage}
          />
        );
        break;
      case "kurs":
        newComponent = (
          <EducationBox
            key={`kurs-${Date.now()}`}
            name="Kurs"
            description="Legg til dine kurs her"
            placeholder="kurs"
            formData={formDataKurs}
            setFormdata={setFormDataKurs}
          />
        );
        break;
      case "interesser":
        newComponent = (
          <Skills
            key={`interesser-${Date.now()}`}
            name="Interesser"
            description="Legg til dine interesser her"
            placeholder="interesser"
            formData={formDataInteresser}
            setFormdata={setFormDataInteresser}
          />
        );
        break;
      case "frivillighet":
        newComponent = (
          <EducationBox
            key={`frivillighet-${Date.now()}`}
            name="Frivillighet"
            description="Legg til dine frivillighet her"
            placeholder="frivillighet"
            formData={formDataFrivillighet}
            setFormdata={setFormDataFrivillighet}
          />
        );
        break;
      case "militærtjeneste":
        newComponent = (
          <EducationBox
            key={`militærtjeneste-${Date.now()}`}
            name="Militærtjeneste"
            description="Legg til din militærtjeneste her"
            formData={formDataMilitærtjeneste}
            setFormdata={setFormDataMilitærtjeneste}
          />
        );
        break;
      case "portefølje":
        newComponent = (
          <PortfolioBox
            key={`portefølje-${Date.now()}`}
            name="Portefølje"
            description="Legg til din portefølje her"
          />
        );
        break;
    }

    if (newComponent) {
      setAdditionalComponents((prevComponents) => [
        ...prevComponents,
        newComponent,
      ]);
      // Update displayed components state
      setDisplayedComponents(prev => ({
        ...prev,
        [type]: true
      }));
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="builder">
      {renderComponents()}
      <AddMoreSections
        className="addMoreSections"
        key="AddMoreSections"
        addBox={addBox}
        displayedComponents={displayedComponents}  // Pass to AddMoreSections
      />
    </div>
  );
};

export default DraggableContainers;
