import { Svg, Path } from "@react-pdf/renderer";

const Email = ({ globalColors }) => {
  return (
    <Svg width="10" height="10" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M33.333 6.66699H6.66634C4.83301 6.66699 3.34967 8.16699 3.34967 10.0003L3.33301 30.0003C3.33301 31.8337 4.83301 33.3337 6.66634 33.3337H33.333C35.1663 33.3337 36.6663 31.8337 36.6663 30.0003V10.0003C36.6663 8.16699 35.1663 6.66699 33.333 6.66699ZM32.6663 13.7503L20.883 21.117C20.3497 21.4503 19.6497 21.4503 19.1163 21.117L7.33301 13.7503C6.91634 13.4837 6.66634 13.0337 6.66634 12.5503C6.66634 11.4337 7.88301 10.767 8.83301 11.3503L19.9997 18.3337L31.1663 11.3503C32.1163 10.767 33.333 11.4337 33.333 12.5503C33.333 13.0337 33.083 13.4837 32.6663 13.7503Z" fill={globalColors}/>
    </Svg>
  );
};

export default Email;