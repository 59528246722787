import "../css/CreateCV.css";
import Logo from "../components/Logo";
import { useState, useRef, useEffect, useCallback } from "react";
import DraggableContainers from "../components/DraggableContainers";
import DraggableContainers2 from "../components/DraggableContainers2";
import ShowDesign from "../components/ShowDesign";
import PopupModal from "../components/PopupModal";
import PopupPayModal from "../components/PopupPayModal";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../img/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../img/arrowRight.svg";
import { useLocalStorage } from '../hooks/useLocalStorage';

import { useLocation } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import Mainla from "./templates/Mainla";
import Toyko from "./templates/Toyko";
// import Cali from "./templates/Cali";
import axios from "axios";
import DownloadablePDF from "../components/DownloadPDF";
import { useAuth } from "../components/AuthContext";
import Stokkholm from "./templates/Stokkholm";
import Asu from "./templates/Asu";
import NTNU from "./templates/NTNU";
import Cali from "./templates/Cali";
import Filipina from "./templates/Filipina";
import PdfViewer2 from "./templates/pdfViewer2";

const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export default function CVCreater() {
  const location = useLocation();
  const { selectedTemplate, setSelectedTemplate, isTransitioning } = useAuth();
  const { isAuthenticated, userInfo, paid, setPaid } = useAuth();
  const [showDesign, setShowDesign] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [useInnhold, setUseInnhold] = useState(true);
  const [popupModal, setPopupModal] = useState(false);
  const [popupPayModal, setPopupPayModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [confirmationToken, setConfirmationToken] = useState(null);
  const [renderedTemplate, setRenderedTemplate] = useState(null);
  const [cvData, setCvData] = useState(null);
  const [designSettings, setDesignSettings] = useLocalStorage('designSettings', {
    showIcons: true,
    uppercaseHeadlines: false
  });
  
  // let profileDummy = "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTqf778yMA8oQSiZel-icDObXWaX1PswJD2NKjv53EzDRsn8g8VclDwco1GXggUmJkSHHkXtUGDT_fTl-FZUfx6fQ"
  // let profileDummy = ""
  let profileDummy = "https://lagecv-cv.s3.eu-north-1.amazonaws.com/DummyPictures/selger42.png"

  // let profileDummy = "https://lagecv-cv.s3.eu-north-1.amazonaws.com/DummyPictures/l%C3%A6rer40.jpg"
  // // let profileDummy = "https://plus.unsplash.com/premium_photo-1690294614341-cf346ba0a637?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  // Get design settings from localStorage
  const getDesignSettings = () => {
    // Helper function to safely parse JSON with a default value
    const safeJSONParse = (item, defaultValue) => {
      try {
        return item ? JSON.parse(item) : defaultValue;
      } catch (e) {
        return defaultValue;
      }
    };

    const designSettingsData = safeJSONParse(localStorage.getItem('designSettings'), {
      showIcons: true,
      uppercaseHeadlines: false
    });

    // Get the profile picture directly from localStorage, it's a URL string
    // For personalia JSON object, the profilePicture could be inside the object
    const personaliaData = safeJSONParse(localStorage.getItem('personalia'), {});
    
    // First check if there's a dedicated 'profilePicture' item in localStorage
    let profilePictureFromStorage = localStorage.getItem('profilePicture');
    
    // If not, try to get it from the personalia object
    if (!profilePictureFromStorage && personaliaData.profilePicture) {
      profilePictureFromStorage = personaliaData.profilePicture;
    }

    return {
      design: safeJSONParse(localStorage.getItem('colorFormData'), {}),
      fonts: safeJSONParse(localStorage.getItem('fontFamily'), { fontFamily: "Inter" }),
      size: safeJSONParse(localStorage.getItem('fontSettings'), { fontSize: 10 }),
      profilePicture: profilePictureFromStorage || profileDummy,
      showIcons: designSettingsData.showIcons,
      uppercaseHeadlines: designSettingsData.uppercaseHeadlines
    };
  };

  const getCvData = async () =>  {
    return {
      Personalia: JSON.parse(localStorage.getItem('personalia') || '{}'),
      Arbeidserfaring: JSON.parse(localStorage.getItem('arbeidserfaring'))?.Arbeidserfaring || [],
      Utdanning: JSON.parse(localStorage.getItem('utdanning'))?.Utdanning || [],
      Ferdigheter: JSON.parse(localStorage.getItem('ferdigheter'))?.Ferdigheter || [],
      Språk: JSON.parse(localStorage.getItem('språk'))?.Språk || [],
      Interesser: JSON.parse(localStorage.getItem('interesser'))?.Interesser || [],
      Referanser: JSON.parse(localStorage.getItem('referanser'))?.Referanser || [],
      Militærtjeneste: JSON.parse(localStorage.getItem('militærtjeneste'))?.Militærtjeneste || [],
      Kurs: JSON.parse(localStorage.getItem('kurs'))?.Kurs || [],
      Frivillighet: JSON.parse(localStorage.getItem('frivillighet'))?.Frivillighet || [],
      Portefølje: JSON.parse(localStorage.getItem('portefølje') || '{}'),
      'kort oppsummering': JSON.parse(localStorage.getItem('kort oppsummering'))?.['kort oppsummering'] || ''
    };
  };

  useEffect(() => {
    let token = window.location.search.split("=")[1];
    setConfirmationToken(token);

    if(token) {
      fetchConfirmationToken(token);
    }
  }, [confirmationToken]);

  const fetchConfirmationToken = async (token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/fetch-confirmation`, 
        {token: token}, 
        {withCredentials: true}
      );
      if(response.status === 200) {
        setPaid(true);
      } 
    } catch (err) {

    }
  };

  const handleDownload = () => {
    if (!isAuthenticated) {
      setPopupModal(true);
    }
    if (isAuthenticated && !paid) {
      setPopupPayModal(true);
    }
  };

//  let dummydata = {
//   "Personalia": {
//       "firstName": "Knut",
//       "lastName": "Hansen",
//       "phonenumber": "91 23 45 67",
//       "email": "knut.hansen@kokk.no",
//       "date": "1968-04-22",
//       "jobtitle": "Kokk",
//       "profilePicture": "https://lagecv-cv.s3.eu-north-1.amazonaws.com/DummyPictures/chef40.png",
//       "address": "Sentrumsveien 45, 1767 Halden",
//       "zipcode": "1767",
//       "city": "Halden",
//       "country": "Norge",
//       "licence": "Fagbrev i kokkefag",
//       "nationality": "Norsk",
//       "lastUpdated": "2025-03-18T09:41:49.929Z"
//   },
//   "Arbeidserfaring": [
//       {
//           "id": "1",
//           "formData": {
//               "stillingstittel": "Kokk",
//               "arbeidssted": "Restaurant Viken, Halden",
//               "fra dato": "2015",
//               "til dato": "Nåværende jobb",
//               "oppsummering": "Laging av mat i høy kvalitet for à la carte og selskapsarrangementer\nLedelse av kjøkkenpersonell og koordinering av kjøkkenarbeid\nPlanlegging av menyer og innkjøp av råvarer"
//           }
//       },
//       {
//           "id": "2",
//           "formData": {
//               "stillingstittel": "Sous-chef",
//               "arbeidssted": "Hotel Central, Halden",
//               "fra dato": "2005",
//               "til dato": "2015",
//               "oppsummering": "Støtte til kjøkkensjefen med daglig drift\nVaretaking og kvalitetssikring av råvarer\nUtvikling og testing av nye retter"
//           }
//       },
//       {
//           "id": "3",
//           "formData": {
//               "stillingstittel": "Kokk",
//               "arbeidssted": "Sjøhuset Restaurant, Halden",
//               "fra dato": "1995",
//               "til dato": "2005",
//               "oppsummering": "Tilberedning og servering av fisk og sjømatretter\nHåndtering av store bestillinger ved spesielle anledninger\nSamarbeid med serveringspersonale for effektiv drift"
//           }
//       }
//   ],
//   "Utdanning": [
//       {
//           "id": "4",
//           "formData": {
//               "utdanningsnavn": "Fagbrev i kokkefag",
//               "utdanningssted": "Fredrikstad videregående skole",
//               "fra dato": "1989",
//               "til dato": "1992",
//               "oppsummering": "Praktisk opplæring i kjøkkenarbeid, matlagingsteknikker og kjøkkenhygiene\nGjennomføring av diverse praksisplasser innen restaurant- og storkjøkken"
//           }
//       }
//   ],
//   "Ferdigheter": [
//       {
//           "id": "5",
//           "formData": {
//               "ferdigheter": "Matlaging",
//               "grad": 5
//           }
//       },
//       {
//           "id": "6",
//           "formData": {
//               "ferdigheter": "Menyplanlegging",
//               "grad": 5
//           }
//       },
//       {
//           "id": "7",
//           "formData": {
//               "ferdigheter": "Kjøkkenledelse",
//               "grad": 4
//           }
//       },
//       {
//           "id": "8",
//           "formData": {
//               "ferdigheter": "Hygiene og sikkerhet",
//               "grad": 5
//           }
//       },
//       {
//           "id": "9",
//           "formData": {
//               "ferdigheter": "Samarbeid og teamarbeid",
//               "grad": 5
//           }
//       }
//   ],
//   "Språk": [
//       {
//           "id": "10",
//           "formData": {
//               "språk": "Norsk",
//               "grad": 5
//           }
//       },
//       {
//           "id": "11",
//           "formData": {
//               "språk": "Engelsk",
//               "grad": 4
//           }
//       }
//   ],
//   "Kurs": [
//       {
//           "id": "12",
//           "formData": {
//               "kurs": "Hygiene og mattrygghet",
//               "sted": "Helsedirektoratet",
//               "fra dato": "2023",
//               "til dato": "2023",
//               "oppsummering": "Oppdatering av retningslinjer for mattrygghet og hygiene i restaurantkjøkken."
//           }
//       },
//       {
//           "id": "13",
//           "formData": {
//               "kurs": "Ledelse på kjøkken",
//               "sted": "Gastronomisk Akademi, Oslo",
//               "fra dato": "2018",
//               "til dato": "2018",
//               "oppsummering": "Ledelsesstrategier for effektivt kjøkkenarbeid, arbeid med ansatte og konflikthåndtering."
//           }
//       }
//   ],
//   "Interesser": [
//       {
//           "id": "14",
//           "formData": {
//               "interesser": "Gastronomi og matlaging",
//               "grad": ""
//           }
//       },
//       {
//           "id": "15",
//           "formData": {
//               "interesser": "Friluftsliv",
//               "grad": ""
//           }
//       },
//       {
//           "id": "16",
//           "formData": {
//               "interesser": "Mat- og vinparing",
//               "grad": ""
//           }
//       }
//   ],
//   "Referanser": [
//       {
//           "id": "17",
//           "formData": {
//               "navn": "Lise Eriksen",
//               "stillingstittel": "Kjøkkensjef",
//               "arbeidssted": "Restaurant Viken, Halden",
//               "telefon": "98765432",
//               "epost": "lise.eriksen@viken.no"
//           }
//       }
//   ],
//   "kort oppsummering": "Erfaren og kreativ kokk med over 30 års erfaring i restaurant- og hotellbransjen. Dyktig på menyplanlegging, matlaging, og kjøkkenledelse. Fokus på høy kvalitet og hygiene. Lidenskap for matlaging og gastronomi."
// }





  // Update the template rendering to use localStorage
  const renderTemplate = useCallback(async  () => {
    if (!selectedTemplate?.name) return null;

    const designSettings = getDesignSettings();
    console.log(designSettings, "DESIGNSETTINGS");
    const cvDataData = await getCvData();
    setCvData(cvDataData);
    
    let template;
    const commonProps = {
      cvData: cvDataData,
      cvDesign: designSettings.design,
      profilePicture: designSettings.profilePicture || profileDummy,
      selectedCVMal: selectedTemplate,
      cvFonts: designSettings.fonts.fontFamily,
      cvSize: designSettings.size.fontSize,
      showIcons: designSettings.showIcons,
      uppercaseHeadlines: designSettings.uppercaseHeadlines
    };

    switch (selectedTemplate.name) {
        case "Toyko":
            template = (
                <Toyko
                    key={selectedTemplate.lastUpdated}
                    {...commonProps}
                />
            );
            break;
        case "Mainla":
            template = (
                <Mainla
                    {...commonProps}
                />
            );
            break;
        case "Cali":
            template = (
                <Cali
                    {...commonProps}
                />
            );
            break;
        case "Stokkholm":
            template = (
                <Stokkholm
                    {...commonProps}
                />
            );
            break;
        case "Asu":
            template = (
                <Asu
                    {...commonProps}
                />
            );
            break;
        case "NTNU":
            template = (
                <NTNU
                    {...commonProps}
                />
            );
            break;
        case "Filipina":
            template = (
                <Filipina
                    {...commonProps}
                />
            );
            break;
        default:
            template = (
                <Toyko
                    key={selectedTemplate.lastUpdated}
                    {...commonProps}
                />
            );
    }

    // Force immediate update
    setRenderedTemplate(null);
    requestAnimationFrame(() => {
        setRenderedTemplate(template);
    });

    return template;
  }, [selectedTemplate]);

  // Add useEffect to handle template rendering when selectedTemplate changes
  useEffect(() => {
    if (selectedTemplate?.name) {
        renderTemplate();
    }
  }, [selectedTemplate, renderTemplate]);

  // Also modify the storage change effect to include selectedTemplate dependency
  useEffect(() => {
    const handleStorageChange = () => {
      if (selectedTemplate && selectedTemplate.name) {
        renderTemplate();
      }
    };

    window.addEventListener('localStorageChange', handleStorageChange);
    return () => window.removeEventListener('localStorageChange', handleStorageChange);
  }, [selectedTemplate]);

  // Initialize design settings on component mount
  useEffect(() => {
    try {
      // Check if design settings exist in localStorage
      const existingSettings = localStorage.getItem('designSettings');
      
      if (!existingSettings) {
        // If no settings exist, initialize with defaults
        const defaultSettings = {
          showIcons: true,
          uppercaseHeadlines: false
        };
        setDesignSettings(defaultSettings);
        localStorage.setItem('designSettings', JSON.stringify(defaultSettings));
        console.log('Initialized default design settings:', defaultSettings);
      } else {
        // If settings exist, parse them and update state
        const parsedSettings = JSON.parse(existingSettings);
        setDesignSettings(parsedSettings);
        console.log('Loaded existing design settings:', parsedSettings);
      }
    } catch (error) {
      console.error('Error initializing design settings:', error);
      // Reset to defaults on error
      const defaultSettings = {
        showIcons: true,
        uppercaseHeadlines: false
      };
      setDesignSettings(defaultSettings);
      localStorage.setItem('designSettings', JSON.stringify(defaultSettings));
    }
  }, []);

  // Add this effect to handle design settings changes
  useEffect(() => {
    const handleDesignSettingsChange = () => {
      try {
        const settingsStr = localStorage.getItem('designSettings');
        if (!settingsStr) return;
        
        const settings = JSON.parse(settingsStr);
        console.log('Design settings changed:', settings);
        setDesignSettings(settings);
        renderTemplate();
      } catch (error) {
        console.error('Error handling design settings change:', error);
      }
    };

    window.addEventListener('designSettingsChange', handleDesignSettingsChange);
    return () => window.removeEventListener('designSettingsChange', handleDesignSettingsChange);
  }, [renderTemplate]);

  return (
    <div className={`gridContainer ${isTransitioning ? "fade-in" : ""}`}>
      <div className={`updating ${isUpdating ? 'visible' : ''}`}>
        Oppdaterer...
      </div>
      <div className="aside">
        <div className="logo">
          <a href="/">
            {" "}
            <Logo />
          </a>
        </div>
        <div
          className="innhold"
          onClick={() => {
            setUseInnhold(true);
            setShowDesign(false);
            if (
              !document
                .querySelector(".builder")
                .classList.contains("fullscreen")
            ) {
              if (window.innerWidth > 660) {
                document.querySelector(".builder").classList.toggle("hide");
              }
            }
            // document.querySelector(".builder").style.display = "grid";
            // document.querySelector(".preview").style.display = "none";
          }}
        >
          Innhold
        </div>
        <div
          className="design"
          onClick={() => {
            setShowDesign(true);
            setUseInnhold(false);
          }}
        >
          Design
        </div>
        {isAuthenticated ? (
          <div className="profile">
            <Link to="/profile">Min Profil</Link>
          </div>
        ) : (
          <div className="loginMenu">
            <a href="/login">Logg inn</a>
            {/* <Link to="/login">Logg inn</Link> */}
          </div>
        )}{" "}

        <div className="previewMobile"         onClick={() => {
          const previewContainer = document.querySelector(".cvTemplatePreview");
          if (previewContainer) {
            setShowPreview(true);
            previewContainer.classList.toggle("fullscreen");
          }
          if(showPreview) {
            setShowPreview(false);
          } else {
            setShowPreview(true);
          }
         
        }}> 
          {showPreview ? <div className="previewMenyButton">Lukk</div> : <div className="previewMenyButton">Forhåndsvis</div>}
        </div>
        {/* <div
          className="previewMobile"
          onClick={() => {
            const previewContainer = document.querySelector(".preview");
            if (previewContainer) {
              previewContainer.classList.toggle("fullscreen");
            }
            if (
              !document
                .querySelector(".builder")
                .classList.contains("fullscreen")
            ) {
              document.querySelector(".builder").classList.toggle("hide");
            }
            // document.querySelector(".builder").classList.toggle("hide");
            // document.querySelector(".builder").style.display = "none";
            // document.querySelector(".preview").style.display = "grid";
          }}
        >
          {" "}
          Forhåndsvis{" "}
        </div> */}
        <button className="cta2" name="LAST NED NÅ" onClick={handleDownload}>
          Last ned nå
        </button>
      </div>
      <div className="builder">
        <div className="builderWrapper">
          {useInnhold ? (
            // <DraggableContainers></DraggableContainers>
            <DraggableContainers2></DraggableContainers2>
          ) : (
            <div></div>
          )}
          {showDesign ? (
      
            <ShowDesign
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              selectedCVMal={selectedTemplate}
              cvFonts={getDesignSettings().fonts.fontFamily}
              cvSize={getDesignSettings().size.fontSize}
            ></ShowDesign>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="cvTemplatePreview">
        <div className="previewContainer">
        <div className="previewBox">
            <h3 className="waterMark">LAGECV.NO</h3>
            <h3 className='waterMark'>FORHÅNDSVISNING</h3>
            {/* <img width="100px" src="logo/lagecv_main_logo.png" alt="lagecv_main_logo" /> */}

            {/* {renderContent()} */}
        </div>
          {renderedTemplate && (
            <PdfViewer2 
              template={renderedTemplate}
              profilePicture={getDesignSettings().profilePicture || profileDummy}
              cvDesign={getDesignSettings().design}
              cvFonts={getDesignSettings().fonts.fontFamily}
              cvSize={getDesignSettings().size.fontSize} 
              selectedTemplate={selectedTemplate.name}
              showIcons={getDesignSettings().showIcons}
              uppercaseHeadlines={getDesignSettings().uppercaseHeadlines}
            />
          )}
        </div>

        {isAuthenticated ? (
          paid ? (
            <DownloadablePDF
              cvData={cvData}
              profilePicture={getDesignSettings().profilePicture}
              className="downloadablePDF"
              cvDesign={getDesignSettings().design}
              selectedTemplate={selectedTemplate.name}
              cvFonts={getDesignSettings().fonts.fontFamily}
              cvSize={getDesignSettings().size.fontSize}
              showIcons={getDesignSettings().showIcons}
              uppercaseHeadlines={getDesignSettings().uppercaseHeadlines}
            >
              onClick={handleDownload}
            </DownloadablePDF>
          ) : (
            <button className="cta2" onClick={() => setPopupPayModal(true)}>
              Last ned CV
            </button>
          )
        ) : (
          <button className="cta2" onClick={() => setPopupModal(true)}>
            Last ned CV
          </button>
        )}
      </div>

      {popupModal && <PopupModal setPopupModal={setPopupModal} />}
      {popupPayModal && <PopupPayModal setPopupPayModal={setPopupPayModal} />}
    </div>
  );
}
