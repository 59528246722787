import "../css/Salesterms.css";

export default function Salesterms() {
  return (
    <div>
      <div className="sales-terms-container">
      <h1>Salgsbetingelser</h1>
        <p>
          Ved å benytte deg av LAGECV.no sine tjenester og nettside, godtar du
          disse vilkårene for bruk.
        </p>

        <h2>Tjenestebeskrivelse</h2>
        <p>
          LAGECV.no tilbyr digitale verktøy og tjenester for opprettelse av
          CV-er og søknader. Tjenestene inkluderer muligheten til å generere,
          redigere, lagre og profesjonelle CV-er og søknader til
          potensielle arbeidsgivere.
        </p>

        <h2>Betalingsvilkår</h2>
        <p>
          Som bruker av LAGECV.no, aksepterer du følgende betalingsvilkår og
          abonnementsbetingelser.
        </p>

        <h3>Betalingsmetoder</h3>
        <p>
          LAGECV.no aksepterer betaling gjennom Vipps. Du bekrefter at du er
          autorisert til å bruke den valgte betalingsmetoden og vil oppgi
          korrekt og fullstendig informasjon.
        </p>
        <p>
          Du må være over 18 år for å inngå et abonnement. Ved å gjennomføre
          betaling bekrefter du at du oppfyller alderskravet.
        </p>

        <h3>Sen og manglende betaling</h3>
        <p>
          Ved sen betaling eller manglende betaling forbeholder LAGECV.no seg
          retten til å suspendere eller avslutte tilgangen til tjenestene.
        </p>
        <p>
          Ingen utestående beløp vil bli forsøkt innkrevd via inkasso, og det
          vil ikke pålegges purregebyr. Abonnementet vil automatisk avsluttes
          dersom betaling ikke går gjennom etter flere forsøk.
        </p>

        <h3>Betaling for tjenester</h3>
        <p>
          Betaling kreves for tilgang til visse tjenester hos LAGECV.no,
          inkludert avanserte funksjoner for CV-bygging og nedlastning i ulike
          formater. Etter en 7-dagers prøveperiode for 29 kroner så fornyes abonnementet
          automatisk til 249 kroner per måned.
        </p>

        <h2>Forpliktelser som bruker</h2>
        <p>
          Som bruker forplikter du deg til å bruke LAGECV.no sine tjenester på
          en ansvarlig måte i samsvar med gjeldende lover og regler.
        </p>

        <ul>
          <li>
            <strong>Korrekt informasjon:</strong> Du skal oppgi nøyaktig og
            oppdatert informasjon i dine CV-er og følgebrev.
          </li>
          <li>
            <strong>Ulovlig bruk:</strong> Du skal ikke bruke tjenestene til
            ulovlige formål som bedrageri eller identitetstyveri.
          </li>
          <li>
            <strong>Kontodeling:</strong> Kontoen er personlig, og du har ikke
            tillatelse til å dele den med andre eller lage CV-er på vegne av
            andre.
          </li>
          <li>
            <strong>Sikkerhet:</strong> Du er ansvarlig for å holde din
            kontoinformasjon konfidensiell og varsle oss ved uautorisert bruk.
          </li>
        </ul>

        <h2>Abonnementsbetingelser</h2>
        <p>
          Våre tjenester tilbys gjennom en abonnementsmodell, hvor du blir
          fakturert på en gjentagende basis. Abonnementet fornyes automatisk, og
          du kan til enhver tid avslutte abonnementet via din egen profil under "Mine abonnementer"
        </p>

        <h2>Refusjon</h2>
        <p>
          Alle salg er endelige, og refusjoner vil ikke bli gitt etter at
          betalte funksjoner er blitt brukt. Unntak vil vurderes individuelt. Du
          har krav på 14-dagers angrerett i henhold til forbrukerloven, men
          denne frafaller ved nedlastning av innhold.
        </p>

        <h2>Endringer i pris og tjenester</h2>
        <p>
          LAGECV.no forbeholder seg retten til å endre priser,
          abonnementsbetingelser og tjenester. Endringer vil bli kommunisert på
          forhånd.
        </p>

        <h2>Opphavsrett</h2>
        <p>
          All innhold på LAGECV.no, inkludert design, tekst, grafikk og koder,
          er beskyttet under opphavsrettsloven og andre immaterielle
          rettigheter. Innholdet eies eller lisensieres av <a href="https://exuma.no">Exuma AS</a>.
        </p>

        <h2>Brukerinnhold</h2>
        <p>
          All informasjon, data, tekst, bilder eller annet materiale som du
          legger inn eller laster opp via vår plattform, anerkjennes som ditt
          brukerinnhold. Du beholder fullt eierskap og rettigheter til dette
          innholdet.
        </p>

        <h2>Ansvarsfraskrivelse</h2>
        <p>
          LAGECV.no garanterer ikke for nøyaktigheten av informasjonen i CV-er
          og følgebrev generert via våre tjenester, og er ikke ansvarlige for
          direkte eller indirekte tap som følge av bruk av tjenestene.
        </p>

        <h2>Endringer i vilkårene</h2>
        <p>
          LAGECV.no forbeholder seg retten til å endre disse vilkårene.
          Eventuelle vesentlige endringer vil bli varslet via e-post.
        </p>

        <h2>Kontaktinformasjon</h2>
        <p>
          For spørsmål relatert til disse vilkårene eller tjenestene, vennligst
          kontakt oss på <a href="mailto:support@lagecv.no">support@lagecv.no</a>.
        </p>
      </div>
    </div>
  );
}
